import React from 'react';

const AttachIcon = ({ ...props }) => {
  const { fill } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M13.217 3.5a3.284 3.284 0 00-3.285 3.283l.005 9.047a1.893 1.893 0 003.229 1.336c.355-.355.555-.836.555-1.338l-.004-7.484a.5.5 0 00-.5-.498.5.5 0 00-.5.5l.004 7.484a.892.892 0 01-1.784-.002l-.005-9.045a2.285 2.285 0 014.568 0v9.041a3.676 3.676 0 11-7.352 0V8.45a.5.5 0 00-.5-.5.5.5 0 00-.5.5v7.375a4.677 4.677 0 109.352 0v-9.04A3.284 3.284 0 0013.217 3.5z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
};

export default AttachIcon;

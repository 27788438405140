import React from 'react';

export const OutgoingSmallIcon = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#5hnll305pa)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 1.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5V15h.085a1.5 1.5 0 0 1 2.83 0h.67a1.5 1.5 0 0 1 2.83 0h.67a1.5 1.5 0 0 1 2.83 0H13v-2.75h1V16h-2v-.5a.5.5 0 0 0-1 0v.5H8.5v-.5a.5.5 0 0 0-1 0v.5H5v-.5a.5.5 0 0 0-1 0v.5H2V1.5A1.5 1.5 0 0 1 3.5 0h9A1.5 1.5 0 0 1 14 1.5V5h-1V1.5zM5.5 2a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm1 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm6.646 2.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 9H11.5a.5.5 0 0 1 0-1h2.793l-1.147-1.146a.5.5 0 0 1 0-.708zM8.5 6.5a.5.5 0 0 0-1 0V7a1.5 1.5 0 1 0 0 3h1a.5.5 0 0 1 0 1H6.085A1.5 1.5 0 0 0 7.5 12v.5a.5.5 0 0 0 1 0V12a1.5 1.5 0 0 0 0-3h-1a.5.5 0 0 1 0-1h2.415A1.5 1.5 0 0 0 8.5 7v-.5z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="5hnll305pa">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

/* eslint-disable no-unused-vars */
// This is an abstraction implementation for logger system in the future,
// For now we need to make sure that we log and console error using this utility function

const logger = {
  log: (message, originalError) => {
    console.log(message);
  },
  error: (message, originalError) => {
    console.error(message, originalError);
  },
  warn: (message, originalError) => {
    console.warn(message, originalError);
  },
};

export default logger;

import React from 'react';
import PropTypes from 'prop-types';

const ChevronFilledIcon = ({ direction, style = {}, ...props }) => {
  let transform = null;
  if (direction === 'up') {
    transform = 'rotate(180deg)';
  } else if (direction === 'left') {
    transform = 'rotate(90deg)';
  } else if (direction === 'right') {
    transform = 'rotate(-90deg)';
  }

  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ transform, ...style }}
    >
      <path
        d="M4.484 7.016L8.01 10.56l3.506-3.545H4.484z"
        color="#000"
        clipRule="evenodd"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

ChevronFilledIcon.propTypes = {
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  style: PropTypes.object,
};

ChevronFilledIcon.defaultProps = {
  direction: 'down',
  style: {},
};

export default ChevronFilledIcon;

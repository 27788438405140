import React from 'react';

const MilestoneIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75436 2.06954C6.5998 1.9782 6.40813 1.97675 6.25219 2.06573C6.09626 2.15471 6 2.32046 6 2.5V3.5V9V9.87622V14.5C6 14.7761 6.22386 15 6.5 15C6.77614 15 7 14.7761 7 14.5V9.28532L12.2544 6.18046L12.9828 5.75L12.2544 5.31954L6.75436 2.06954ZM7 8.12378L11.0172 5.75L7 3.37622V3.5V8.12378Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MilestoneIcon;

import React from 'react';

const TagIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path
        d="M3.0839844 2.5839844a.50005.50005 0 00-.5.5V8.5a.50005.50005 0 00.1464844.3535156L7.3828125 13.5c.1470339.147151.3216715.264036.5136719.34375.1922992.079686.3975472.121094.6054687.121094.2079269 0 .4151227-.041408.6074219-.121094.1920004-.079714.3666364-.196583.5136719-.34375l3.8828121-3.8828125a.50005.50005 0 00.001953 0c.294851-.2965593.458985-.699145.458985-1.1171875s-.16415-.8206296-.458985-1.1171875a.50005.50005 0 00-.001953 0L8.8535156 2.7304688A.50005.50005 0 008.5 2.5839844H3.0839844zm.5 1h4.7089844l4.5058592 4.5058594c.107936.1092651.167969.2564261.167969.4101562 0 .1543575-.059219.3027287-.167969.4121094L8.9160156 12.794922c-.0535458.053594-.1194252.095545-.1894531.125-.0707408.029314-.1477963.044922-.2246094.044922-.0767985 0-.1519154-.015608-.2226562-.044922v.001953c-.0710196-.029486-.1352472-.072704-.1894531-.126953a.50005.50005 0 000-.001953L3.5839844 8.2929688V3.5839844zM6 5c-.5463624 0-1 .4536376-1 1s.4536376 1 1 1 1-.4536376 1-1-.4536376-1-1-1z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TagIcon;

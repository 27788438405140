import _debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';
import { setFormChange } from './index';

const UnrelatedFields = ['next'];

class FormSerializer {
  constructor(selectors) {
    let modifiedSelectors = selectors;
    if (typeof selectors === 'string') modifiedSelectors = [selectors];
    this.selectors = [...modifiedSelectors];
    this.serializedForm = {};
    this.serialize();
    this.registerEvents();
  }

  registerEvents() {
    const debouncedChange = _debounce(this.processFormChange.bind(this), 450);
    $(this.selectors.join(',')).on('input change', ':input', debouncedChange);
  }

  processFormChange({ target }) {
    const form = $(target).closest('form');
    const formSelector = this.selectors.find(selector =>
      $(selector)[0].isEqualNode(form[0]),
    );

    return (
      formSelector &&
      setFormChange(
        !_isEqual(
          _sortBy(this.serializedForm[formSelector], ['name']),
          _sortBy(FormSerializer.getSerializedForm(form), ['name']),
        ),
      )
    );
  }

  static serializeDisabledFields(selector) {
    const serializedArray = [];
    $(selector)
      .find(':input:disabled')
      .each((index, elem) => {
        serializedArray.push({ name: elem.name, value: $(elem).val() });
      });
    return serializedArray;
  }

  static serializeCheckBoxes(selector) {
    return $(selector)
      .find('input[type=checkbox]')
      .filter(a => a && a.name)
      .map(elem => ({ name: elem.name, value: $(elem).is(':checked') }));
  }

  static getSerializedForm(selector) {
    return $(selector)
      .serializeArray()
      .concat(FormSerializer.serializeDisabledFields(selector))
      .concat(FormSerializer.serializeCheckBoxes(selector))
      .filter(
        field => field && field.name && !UnrelatedFields.includes(field.name),
      );
  }

  serialize() {
    this.selectors.forEach(selector => {
      this.serializedForm[selector] =
        FormSerializer.getSerializedForm(selector);
    });
  }

  getAsKeyValue(formId) {
    if (!this.serializedForm[formId]) return {};
    return this.serializedForm[formId].reduce((out, field) => {
      return { ...out, [field.name]: field.value };
    }, {});
  }
}

export default FormSerializer;

import {
  getFormChange,
  getReactChange,
  setFormChange,
  setReactChange,
} from './page-update';
import { confirmUnsavedChanges } from '../../utils';

const formHasChanges = () => {
  return getFormChange() || getReactChange();
};

const continueProgress = target => {
  setReactChange(false);
  setFormChange(false);
  target.click();
};

const showAlert = target => {
  confirmUnsavedChanges(isConfirm => isConfirm && continueProgress(target));
};

const callback = e => {
  if (formHasChanges()) {
    e.preventDefault();
    e.stopImmediatePropagation();
    showAlert(e.target);
  }
};

const registerEvent = () => {
  $(
    '#side-menu, #navbarTop, .page-heading, form .tabs-container .ibox-content',
  ).on(
    'click',
    `a[href]:not([href^="#"], [href^="javascript:void(0)"], 
    [target^="_blank"], .cancel)`,
    callback,
  );
  $('#tags-list').on('click', '.level-2 a', callback);
  $('#global-add a').on('click', callback);
  $('#navbarTop #messages').on('click', 'ul li .media-body', callback);
  $('#navbarTop #notifications').on(
    'click',
    'ul.dropdown-alerts li a',
    callback,
  );
};

export default function () {
  registerEvent();
}

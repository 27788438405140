import React from 'react';
import logo from 'images/logo/PF-Logo-2x.png';

type Props = {
  children?: React.ReactNode;
};

const Header = ({ children }: Props) => (
  <>
    <div
      style={{
        lineHeight: 1.1,
        fontSize: 170,
        marginTop: 20,
        textAlign: 'center',
      }}
    >
      <img src={logo} alt="" />
    </div>
    <div className="text-center">{children}</div>
  </>
);

export default Header;

import React from 'react';

const CloudIcon = ({ ...props }) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.518 4.833a2.773 2.773 0 0 0-2.752 3.115.5.5 0 0 1-.402.552 1.683 1.683 0 0 0 .318 3.333h8.728a1.682 1.682 0 1 0 0-3.363h-.037a.5.5 0 0 1-.49-.6 1.318 1.318 0 0 0-2.108-1.3.5.5 0 0 1-.76-.173 2.773 2.773 0 0 0-2.497-1.564Zm4.012 8H3.682a2.682 2.682 0 0 1-.936-5.195v-.032A3.773 3.773 0 0 1 9.641 5.49a2.318 2.318 0 0 1 3.265 2.026 2.683 2.683 0 0 1-.498 5.317H10.53Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloudIcon;

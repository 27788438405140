import React from 'react';

export const StandaloneTransactionIcon = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#6hwinq1bka)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 8a7 7 0 1 1 14 0A7 7 0 0 1 1 8zm7-8a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm1.157 9.241c.233.226.35.503.35.833 0 .39-.135.698-.403.924-.269.225-.638.338-1.107.338-.48 0-.85-.134-1.111-.403-.262-.268-.392-.652-.392-1.15H5.5c0 .71.188 1.272.564 1.687.38.412.902.648 1.568.71v1.025h.795V12.18c.655-.068 1.164-.286 1.525-.655.365-.369.548-.856.548-1.461 0-.38-.073-.71-.22-.994a2.004 2.004 0 0 0-.677-.74c-.304-.216-.73-.41-1.278-.586-.544-.18-.936-.376-1.176-.591-.237-.219-.355-.51-.355-.876 0-.394.113-.7.339-.918.225-.222.551-.333.977-.333.408 0 .732.148.972.446.24.297.36.698.36 1.203h.988c0-.734-.168-1.313-.505-1.735-.333-.423-.8-.675-1.401-.757V3h-.8v1.176c-.599.072-1.07.296-1.413.672-.34.376-.51.857-.51 1.444 0 .577.173 1.053.52 1.43.352.372.908.674 1.67.907.549.183.937.387 1.166.612z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="6hwinq1bka">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

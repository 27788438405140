import React from 'react';

const InfoCircleIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Info Circle Icon"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0 1A5 5 0 1 0 8 3a5 5 0 0 0 0 10zm0-5.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V8a.5.5 0 0 1 .5-.5zm0-1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
        fill="#33788F"
      />
    </svg>
  );
};

export default InfoCircleIcon;

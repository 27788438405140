export const ButtonLoading = {
  html: `${gettext('saving')}...`,
  start(selector) {
    /** @type {HTMLElement} */
    const element = document.querySelector(selector);
    const preHTML = element.innerHTML;
    element.innerHTML = this.html;
    element.setAttribute('preHTML', preHTML);
    element.classList.add('disabled');
    element.disabled = true;
  },
  stop(selector) {
    /** @type {HTMLElement} */
    const element = document.querySelector(selector);
    element.innerHTML = element.getAttribute('preHTML');
    element.classList.remove('disabled');
    element.disabled = false;
  },
};

import React from 'react';

const RootCause = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 1a5 5 0 00-3.871 8.164l-.393.393a1.5 1.5 0 00-1.697.297l-2.553 2.554a1.5 1.5 0 102.121 2.121l2.553-2.554a1.5 1.5 0 00.29-1.717l.386-.387A5 5 0 1010 1zm0 1a4 4 0 110 8 4 4 0 010-8zM7.5 5a.5.5 0 000 1h5a.5.5 0 100-1h-5zm1 2a.5.5 0 000 1h3a.5.5 0 100-1h-3zm-3.4 3.414a.498.498 0 01.353.854L2.9 13.822a.5.5 0 11-.707-.707l2.553-2.554a.498.498 0 01.354-.147z"
        color="currentColor"
        clipRule="evenodd"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default RootCause;

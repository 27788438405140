import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(quarterOfYear);

export const dividableToYears = (startData, endData) => {
  const start = dayjs(startData).startOf('M');
  const end = dayjs(endData).endOf('M').add(1);

  return (
    start.diff(start.startOf('y'), 'y', true) === 0 &&
    end.diff(start, 'y', true) % 1 === 0
  );
};

export const dividableToQuarters = (startData, endData) => {
  const start = dayjs(startData).startOf('M');
  const end = dayjs(endData).endOf('M').add(1);

  return (
    start.diff(start.startOf('Q'), 'Q', true) === 0 &&
    end.diff(start, 'Q', true) % 1 === 0
  );
};

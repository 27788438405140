import React from 'react';
import PropTypes from 'prop-types';
import createStyle from 'react-jss';
import { primary } from '../../colors';
import { fontAwesome } from '../../fonts';

const LoaderWithProgress = ({ progress, classes }) => {
  return (
    <div className={classes.loader}>
      <i className={classes.spinner} />
      <span className={classes.label}>{progress}%</span>
    </div>
  );
};

LoaderWithProgress.propTypes = {
  progress: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  classes: PropTypes.object,
};

LoaderWithProgress.defaultProps = {
  classes: {},
};

const style = createStyle({
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(359deg)',
    },
  },
  loader: {
    position: 'relative',
    display: 'inline-block',
  },
  label: {
    fontSize: 12,
    position: 'absolute',
    width: 60,
    height: 60,
    lineHeight: '60px',
    textAlign: 'center',
  },
  spinner: {
    position: 'absolute',
    color: primary,
    animation: 'spin 2s infinite linear',
    display: 'inline-block',
    fontStyle: 'normal',
    fontSize: 60,
    lineHeight: 'normal',
    fontFamily: fontAwesome,
    textRendering: 'auto',
    WebkitFontSmoothing: 'antialiased',
    '&:before': {
      content: '"\\f110"',
    },
  },
});

export default style(LoaderWithProgress);

import React from 'react';

const ExpandIcon = ({ ...props }) => {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="g38" transform="translate(-0.5,-0.5)">
        <path
          fill="currentColor"
          d="m 10.976562,5.5 a 0.5,0.5 0 0 0 -0.330078,0.1464844 l -1.9999996,2 a 0.5,0.5 0 0 0 0,0.7070312 0.5,0.5 0 0 0 0.7070312,0 l 2.0000004,-2 a 0.5,0.5 0 0 0 0,-0.7070312 A 0.5,0.5 0 0 0 10.976562,5.5 Z"
        />
        <path
          fill="currentColor"
          d="M 7.9765625,8.5 A 0.5,0.5 0 0 0 7.6464844,8.6464844 l -2,1.9999996 a 0.5,0.5 0 0 0 0,0.707032 0.5,0.5 0 0 0 0.7070312,0 l 2,-2.0000004 a 0.5,0.5 0 0 0 0,-0.7070312 A 0.5,0.5 0 0 0 7.9765625,8.5 Z"
        />
        <path
          fill="currentColor"
          d="M 5.5,8 A 0.5,0.5 0 0 0 5,8.5 V 12 H 8.5 A 0.5,0.5 0 0 0 9,11.5 0.5,0.5 0 0 0 8.5,11 H 6 V 8.5 A 0.5,0.5 0 0 0 5.5,8 Z"
        />
        <path
          fill="currentColor"
          d="M 8.5,5 A 0.5,0.5 0 0 0 8,5.5 0.5,0.5 0 0 0 8.5,6 H 11 V 8.5 A 0.5,0.5 0 0 0 11.5,9 0.5,0.5 0 0 0 12,8.5 V 5 Z"
        />
      </g>
    </svg>
  );
};

export default ExpandIcon;

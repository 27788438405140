import React from 'react';

const ExternalIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M18.563 10.516V5.172h-5.344M18.563 5.172l-6.472 6.471M18.563 14.672v2.562a1 1 0 01-1 1H6.5a1 1 0 01-1-1V6.172a1 1 0 011-1h2.563"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ExternalIcon;

import React from 'react';

const IncomingIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g>
        <path
          fill="currentColor"
          d="M23.725,1.052l-2-1c-0.142-0.069-0.307-0.069-0.447,0L19.501,0.94l-1.776-0.889
		c-0.141-0.069-0.306-0.069-0.447,0L15.502,0.94l-1.775-0.889c-0.142-0.069-0.307-0.069-0.447,0L11.503,0.94L9.727,0.052
		c-0.141-0.069-0.306-0.069-0.447,0l-2,1C7.111,1.136,7.004,1.311,7.004,1.499v5.499h0.998v-5.19l1.5-0.75l1.775,0.889
		c0.142,0.069,0.307,0.069,0.447,0l1.776-0.889l1.776,0.889c0.141,0.069,0.306,0.069,0.447,0L17.5,1.058l1.775,0.889
		c0.142,0.069,0.307,0.069,0.447,0l1.776-0.889l1.5,0.75V18.69l-1.276-0.639c-0.141-0.069-0.306-0.069-0.447,0L19.5,18.94
		l-1.775-0.889c-0.072-0.035-0.147-0.053-0.225-0.053s-0.154,0.018-0.225,0.053L15.5,18.94l-1.775-0.889
		c-0.142-0.069-0.307-0.069-0.447,0l-1.776,0.889l-1.776-0.889c-0.141-0.069-0.306-0.069-0.447,0L8.002,18.69v-2.692H7.004v3.501
		c0,0.173,0.09,0.334,0.237,0.425s0.331,0.1,0.485,0.022l1.776-0.889l1.776,0.889c0.141,0.069,0.306,0.069,0.447,0l1.775-0.889
		l1.775,0.889c0.142,0.069,0.307,0.069,0.447,0l1.776-0.889l1.776,0.889c0.141,0.069,0.306,0.069,0.447,0l1.775-0.889l1.775,0.889
		c0.072,0.036,0.148,0.053,0.225,0.053c0.092,0,0.184-0.025,0.264-0.075C23.91,19.833,24,19.672,24,19.499v-18
		C24,1.311,23.893,1.137,23.725,1.052z"
        />
        <path
          fill="currentColor"
          d="M17.5,12.999H14v-1h3.5c0.275,0,0.5-0.224,0.5-0.5s-0.225-0.5-0.5-0.5H16v-0.5c0-0.276-0.225-0.5-0.5-0.5
		s-0.5,0.224-0.5,0.5v0.5h-1.5c-0.275,0-0.5,0.224-0.5,0.5v2c0,0.276,0.225,0.5,0.5,0.5H17v1h-3.5c-0.275,0-0.5,0.224-0.5,0.5
		s0.225,0.5,0.5,0.5H15v0.5c0,0.276,0.225,0.5,0.5,0.5s0.5-0.224,0.5-0.5v-0.5h1.5c0.275,0,0.5-0.224,0.5-0.5v-2
		C18,13.223,17.775,12.999,17.5,12.999"
        />
        <path
          fill="currentColor"
          d="M18.5,4.999h-6c-0.275,0-0.5-0.224-0.5-0.5s0.225-0.5,0.5-0.5h6c0.275,0,0.5,0.224,0.5,0.5
		S18.775,4.999,18.5,4.999"
        />
        <path
          fill="currentColor"
          d="M20.5,6.999h-10c-0.275,0-0.5-0.224-0.5-0.5s0.225-0.5,0.5-0.5h10c0.275,0,0.5,0.224,0.5,0.5
		S20.775,6.999,20.5,6.999"
        />
        <path
          fill="currentColor"
          d="M20.5,8.999h-10c-0.275,0-0.5-0.224-0.5-0.5s0.225-0.5,0.5-0.5h10c0.275,0,0.5,0.224,0.5,0.5
		S20.775,8.999,20.5,8.999"
        />
        <path
          fill="currentColor"
          d="M5.354,8.146c-0.195-0.195-0.513-0.195-0.708,0c-0.194,0.194-0.194,0.512,0,0.706l1.352,1.353V8.789
		L5.354,8.146z"
        />
        <path
          fill="currentColor"
          d="M4.646,14.144c-0.194,0.195-0.194,0.513,0,0.707C4.745,14.949,4.873,14.998,5,14.998
		c0.129,0,0.257-0.05,0.354-0.147l0.644-0.644v-1.414L4.646,14.144z"
        />
        <path
          fill="currentColor"
          d="M0.5,10.999c-0.275,0-0.5,0.223-0.5,0.5c0,0.275,0.225,0.5,0.5,0.5h5.498v-1H0.5z"
        />
        <path
          fill="currentColor"
          d="M8.354,11.144v0.002L5.998,8.789v1.415l0.794,0.795H5.998v1h0.794l-0.794,0.794v1.414l2.356-2.356
		C8.55,11.656,8.55,11.339,8.354,11.144z"
        />
        <path
          fill="currentColor"
          d="M6.999,18.712C6.997,18.869,7.221,19,7.497,19.001c0.275,0.002,0.5-0.125,0.502-0.282l0.019-2.721h-1
		L6.999,18.712z"
        />
        <path
          fill="currentColor"
          d="M7.524,14.998c-0.276-0.002-0.501,0.125-0.502,0.282l-0.005,0.718h1l0.005-0.711
		C8.023,15.13,7.8,15,7.524,14.998z"
        />
        <path
          fill="currentColor"
          d="M8.01,4.287C8.011,4.13,7.791,4,7.518,3.998C7.245,3.996,7.023,4.123,7.022,4.281L7.004,6.998h0.987
		L8.01,4.287z"
        />
        <path
          fill="currentColor"
          d="M7.489,8.002c0.274,0.002,0.496-0.125,0.497-0.283l0.005-0.721H7.004L6.999,7.712
		C6.997,7.87,7.218,8,7.489,8.002z"
        />
      </g>
    </svg>
  );
};

export default IncomingIcon;

import moment from 'moment';
import _ from 'lodash';

const DANGER_CLASS = 'danger-tint-1-bk';
const SUCCESS_CLASS = 'success-tint-1-bk';
const WARNING_CLASS = 'warning-tint-1-bk';

const buttonLoaderStack = {};
const DATE_FORMATS = ['YYYY-MM-DD', 'DD MMM YYYY', 'MMMM DD, YYYY'];

Number.prototype.toFixedNumber = function (toFixTo = 2) {
  return Math.round(this * 100) / 100;
};

export function getLocalStorage(key) {
  let obj = null;
  try {
    obj = JSON.parse(localStorage.getItem(key));
  } catch (e) {}
  return obj;
}

export function setLocalStorage(key, value) {
  let success = false;
  try {
    localStorage.setItem(key, JSON.stringify(value));
    success = true;
  } catch (e) {}
  return success;
}

export const clearLocalStorage = () => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in localStorage) {
    if (key.indexOf('_filters') || key.indexOf('_columns')) {
      localStorage.removeItem(key);
    }
  }
};

export function heightEqualizer($children) {
  const heights = [];
  $children.each(function () {
    heights.push($(this).height());
  });
  const newHeight = Math.max(...heights);
  $children.height(newHeight);
}

export function loader(show, $target, $loader) {
  if (show) {
    $loader.css({
      top: $target.position().top,
      left: $target.position().left,
      width: $target.width(),
      height: $target.height(),
    });
    $loader.show();
  } else {
    $loader.hide();
  }
}

export function parseParams(str) {
  return str.split('&').reduce(function (params, param) {
    const paramSplit = param.split('=').map(function (value) {
      return decodeURIComponent(value.replace('+', ' '));
    });
    params[paramSplit[0]] = paramSplit[1];
    return params;
  }, {});
}

export function setSessionValue(key, entity, value) {
  const sessionId = `${entity.toLowerCase()}_${key}`;
  localStorage.setItem(sessionId, JSON.stringify(value));
}

export function getSessionValue(key, entity) {
  const sessionId = `${entity.toLowerCase()}_${key}`;
  let value = localStorage.getItem(sessionId);
  if (value) {
    value = JSON.parse(value);
  }
  return value;
}

export function orderColumns(cols) {
  const ordered = [];
  cols.all.forEach(function (col) {
    if (_.includes(cols.selected, col.internal)) {
      ordered.push(col.internal);
    }
  });
  cols.selected = ordered;
  return cols;
}

export function sort($el) {
  let sortAttribute = $el.attr('data-sortAttr');
  let sortOrder = null;
  const sortOrderObject = $el.children('.fa')[0];
  if (sortOrderObject) {
    if ($(sortOrderObject).hasClass('fa fa-sort')) {
      sortOrder = 'asc';
      $(sortOrderObject).attr('class', 'fa fa-caret-up');
    } else if ($(sortOrderObject).hasClass('fa fa-caret-up')) {
      sortOrder = 'desc';
      $(sortOrderObject).attr('class', 'fa fa-caret-down');
    } else if ($(sortOrderObject).hasClass('fa-caret-down')) {
      $(sortOrderObject).attr('class', 'fa fa-sort');
      sortOrder = null;
      sortAttribute = null;
    }
  } else {
    $(sortOrderObject).attr('class', 'fa fa-caret-up');
    sortOrder = 'asc';
  }

  $el
    .parent()
    .children('th')
    .each(function () {
      // remove sorting from other columns
      if (!$(this).is($el)) {
        $(this).children('.fa').attr('class', 'fa fa-sort');
        $(this).find('span').children('.fa').attr('class', 'fa fa-sort');
      }
    });

  return {
    column: sortAttribute,
    order: sortOrder,
  };
}

export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = jQuery.trim(cookies[i]);
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

export function numberInputKeyUp(e) {
  const key = e.charCode || e.keyCode || 0;
  // allow backspace, tab, delete, enter, arrows, numbers and keypad numbers ONLY
  // home, end, period, and numpad decimal
  if (
    [8, 9, 13, 46, 110, 190].indexOf(key) != -1 ||
    (key >= 35 && key <= 40) ||
    (key >= 48 && key <= 57) ||
    (key >= 96 && key <= 105)
  ) {
    return true;
  }
  e.preventDefault();
  e.stopPropagation();
  return false;
}

export function getQueryParam(parameterName) {
  let result = null;
  let tmp = [];
  location.search
    .substr(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=');
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
}

export function populateDropDown(
  $ddl,
  items,
  keyName,
  textName,
  selectedValue,
  empty = '--- Select ---',
) {
  $ddl.empty();

  if (_.size(items) > 0) {
    $ddl.append($('<option></option>').val('0').html(empty));
    $.each(items, function (i, item) {
      $ddl.append(
        $('<option></option>').val(item[keyName]).html(item[textName]),
      );
    });

    if (selectedValue) {
      $ddl.val(selectedValue);
    }
  }
}

export function loaderButton($btn, loading, message = 'Loading...') {
  const id = $btn.prop('id');
  if (loading) {
    const orgText = $btn.html();
    $btn.html(`<i class="fa fa-spinner fa-spin"></i> ${message}`);
    $btn.attr('disabled', true);
    buttonLoaderStack[id] = orgText;
  } else {
    $btn.html(buttonLoaderStack[id]);
    $btn.attr('disabled', false);
    _.remove(buttonLoaderStack, { id });
  }
}

export function toClientDate(dte) {
  return moment(dte, DATE_FORMATS).format('DD MMM YYYY');
}

export function toServerDate(dte) {
  if (dte && dte != '') {
    return moment(dte, DATE_FORMATS).format('YYYY-MM-DD');
  }
  throw new Error('Invalid Date');
}

export function isValidDate(dte) {
  if (!dte || dte.trim === '') return false;
  const momentDate = moment(dte, DATE_FORMATS, true);
  return momentDate.isValid() && momentDate.year() > 0;
}

export function getPercentageDiff(val1, val2) {
  if (val1 == 0 && val2 == 0) return 0;
  if (val2 == 0) {
    if (val1 > 0) return 100;
    if (val1 < 0) return -100;
  }
  return (((val1 - val2) / Math.abs(val2)) * 100).toFixedNumber(2);
}

export function populateView($parent, data) {
  $parent.find('[data-form]').each(function (i) {
    const id = $(this).data('form');
    const format = $(this).data('format');
    if (data[id] !== undefined && data[id] !== null) {
      if (format == 'date') {
        $(this).html(toClientDate(data[id]));
      } else {
        $(this).html(data[id]);
      }
    }
  });
}

export function populateForm($form, data, prefix = null) {
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const inputId = prefix ? prefix + key : key;
      $form.find(`#${inputId}`).val(data[key]);
    }
  }
}

export function gloader(show, $target) {
  const $loader = getLoader();
  if (show) {
    $loader.css({
      top: $target.position().top,
      width: $target.width(),
      height: $target.height(),
    });
    $target.after($loader);
  } else {
    $('.gloader').remove();
  }
}

export function formatNumber(number) {
  accounting.settings.currency.symbol = '';
  if (!isNaN(number)) {
    return accounting.formatMoney(number);
  }
}

function getLoader() {
  const $loader = $("<div class='gloader'></div>");
  const $icon = $("<i class='fa fa-spinner fa-spin fa-5x fa-fw'></i>");
  $loader.append($icon);
  return $loader;
}

export function truncate(str, length, ending) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  }
  return str;
}

export function yesterday() {
  return moment().subtract(1, 'days');
}

export function lastWeek() {
  return {
    start: moment().subtract(1, 'week').startOf('isoWeek'),
    end: moment().subtract(1, 'week').endOf('isoWeek'),
  };
}

export function lastMonth() {
  return {
    start: moment().subtract(1, 'month').startOf('month'),
    end: moment().subtract(1, 'month').endOf('month'),
  };
}

export function last12Month() {
  return {
    start: moment().subtract(1, 'year'),
    end: moment(),
  };
}

export function yearToDate() {
  return {
    start: moment().startOf('year'),
    end: moment(),
  };
}

export function lastQuarter() {
  return {
    start: moment().subtract(1, 'Q').startOf('quarter'),
    end: moment().subtract(1, 'Q').endOf('quarter'),
  };
}

export function getVarianceClass(variance, category = '') {
  var variance = accounting.unformat(variance);
  let varClass = '';
  if (variance == 0) varClass = SUCCESS_CLASS;
  else if (variance > 0)
    varClass =
      category == 'expenses'
        ? variance < 5
          ? WARNING_CLASS
          : DANGER_CLASS
        : SUCCESS_CLASS;
  else if (variance < -5)
    varClass = category == 'expenses' ? SUCCESS_CLASS : DANGER_CLASS;
  else if (variance < 0 && variance >= -5)
    varClass = category == 'expenses' ? SUCCESS_CLASS : WARNING_CLASS;
  return varClass;
}

/**
 * Alternative to $.serializeArray()
 * Source: https://plainjs.com/javascript/ajax/serialize-form-data-into-an-array-46/
 */
export const serializeArray = form => {
  let field;
  let l;
  const s = [];
  if (typeof form === 'object' && form.nodeName === 'FORM') {
    const len = form.elements.length;
    for (let i = 0; i < len; i += 1) {
      field = form.elements[i];
      if (
        field.name &&
        !field.disabled &&
        field.type !== 'file' &&
        field.type !== 'reset' &&
        field.type !== 'submit' &&
        field.type !== 'button'
      ) {
        if (field.type === 'select-multiple') {
          l = form.elements[i].options.length;
          for (let j = 0; j < l; j += 1) {
            if (field.options[j].selected)
              s[s.length] = { name: field.name, value: field.options[j].value };
          }
        } else if (
          (field.type !== 'checkbox' && field.type !== 'radio') ||
          field.checked
        ) {
          s[s.length] = { name: field.name, value: field.value };
        }
      }
    }
  }
  return s;
};

export const getFormData = object => {
  const formData = new FormData();
  let data = object;
  if (object instanceof HTMLFormElement) {
    data = serializeArray(object);
  }
  if (Array.isArray(data)) {
    data.forEach(item => formData.append(item.name, item.value));
  } else {
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));
  }
  return formData;
};

export const checkFrontEndFormErrors = (errorSelector = '.error-in-form') => {
  const $tabs = $('.tab-pane');
  if ($(errorSelector).length !== 0) {
    for (let i = 0, len = $tabs.length; i < len; i++) {
      const errorsInTab = $($tabs[i]).find(errorSelector).length;
      $(`a[href="#${$tabs[i].id}"] .error-box`).remove();
      if (errorsInTab !== 0) {
        $(`a[href="#${$tabs[i].id}"]`).append(
          ` <span class="error-box badge badge-danger p-l-xs m-l-xs">
            <i class="lnricns lnricns-warning"></i> ${errorsInTab}
          </span>`,
        );
      }
    }
  }
};

import React from 'react';
import { UsersApi } from '@bluepoint/common/legacy/api';
import Cookies from 'js-cookie';
import { Button, Form, Input } from 'antd';

type LoginFormValue = {
  email: string;
  password: string;
};

type LoginFormErrors = {
  error?: string;
  email?: string;
  password?: string;
};

const UNKNOWN_ERROR = {
  error: gettext('Something went wrong. Try again later.'),
};

export const FormLogin = () => {
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [errors, setErrors] = React.useState<LoginFormErrors>();

  const onFinish = async ({ email, password }: LoginFormValue) => {
    setSubmitting(true);
    try {
      const { ok, data } = await UsersApi.login(email, password);

      if (ok) {
        window.location.replace(data.redirect_url);
      } else {
        setErrors(data);
      }
    } catch (error) {
      setErrors(UNKNOWN_ERROR);
    } finally {
      setSubmitting(false);
    }
  };

  const params = new URLSearchParams(window.location.search);
  const initEmail = params.get('email') ?? '';

  return (
    <Form
      initialValues={{
        email: initEmail,
        password: '',
      }}
      onFinish={onFinish}
      validateTrigger="onSubmit"
    >
      {errors?.error && (
        <div className="row">
          <div className="col-xs-12 m-t-xs">
            <div className="alert alert-danger">{errors.error}</div>
          </div>
        </div>
      )}
      <Form.Item
        id="email"
        name="email"
        rules={[
          { required: true, message: gettext('Email is required') },
          { type: 'email', message: gettext('Email is invalid') },
        ]}
        help={errors?.email}
        validateStatus={errors?.email ? 'error' : undefined}
      >
        <Input
          disabled={isSubmitting}
          readOnly={initEmail !== ''}
          placeholder={gettext('Email address')}
          autoFocus={initEmail === ''}
        />
      </Form.Item>
      <Form.Item
        id="password"
        name="password"
        rules={[{ required: true, message: gettext('Password is required') }]}
        help={errors?.password}
        validateStatus={errors?.password ? 'error' : undefined}
      >
        <Input.Password
          disabled={isSubmitting}
          placeholder={gettext('Password')}
          autoFocus={initEmail !== ''}
        />
      </Form.Item>
      <Button
        htmlType="submit"
        className="block full-width m-b"
        disabled={isSubmitting}
        type="primary"
      >
        {gettext('Login')}
      </Button>
    </Form>
  );
};

export default FormLogin;

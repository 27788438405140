import Cookies from 'js-cookie';

export const postFormData = (
  url: string,
  data: Record<string, string | string[]>,
  callback: (res: any, success: boolean) => void,
  convertArrays?: boolean,
) => {
  const formData = new FormData();
  const xhr = new XMLHttpRequest();

  Object.keys(data).forEach(key => {
    if (data[key] !== undefined) {
      if (convertArrays && Array.isArray(data[key])) {
        for (let i = 0; i < data[key].length; i += 1) {
          formData.append(key, data[key][i]);
        }
      } else {
        formData.append(key, data[key] as string);
      }
    }
  });

  formData.append('csrfmiddlewaretoken', Cookies.get('csrftoken') ?? '');

  xhr.responseType = 'json';
  xhr.open('POST', url, true);
  xhr.onload = () => {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        callback(xhr.response, true);
      } else {
        callback(xhr.response, false);
      }
    }
  };
  xhr.send(formData);
};

export const confirmUnsavedChanges = (callback: () => void) => {
  app.methods.confirm(
    {
      title: gettext('Unsaved Changes'),
      description: gettext(
        'You have unsaved changes on the page. Are you sure you want to leave?',
      ),
      canceButtonText: `<i class="lnricns lnricns-cross mr5"></i>${gettext(
        'No',
      )}`,
      confirmButtonText: gettext('Yes'),
    },
    callback,
  );
};

export const appendHiddenForm = (
  id: string,
  action: string,
  method: string,
  params: Record<string, any>,
) => {
  const $form = $('<form>')
    .attr({
      id,
      action,
      method,
    })
    .appendTo('body');
  const formParams: Record<string, string> = {
    ...params,
    csrfmiddlewaretoken: Cookies.get('csrftoken') ?? '',
  };
  Object.keys(formParams).forEach(key => {
    $('<input>')
      .attr({
        type: 'hidden',
        name: key,
        value: formParams[key],
      })
      .appendTo($form);
  });
  return $form;
};

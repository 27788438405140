// TODO-UI: investigate and switch to the new styleguide
export const primary = '#3498db';
export const success = '#62cb31';
export const warning = '#ffb606';
export const danger = '#e74c3c';
export const lightBlue = 'aliceblue';
export const borderGray = '#DDD';
export const borderGrayLight = '#e7eaec';
export const disabledGray = '#a7b1c2';
export const menuTextGray = '#a7b1c2';
export const darkGray = '#676a6c';
export const lightGray = '#fafafa';
export const mainTextGray = '#676a6c';
export const toggleDisabled = '#d2d2d2';
export const pageBG = '#f1f3f6';

export const white = '#fff';
export const black = '#333';
export const bluegray = '#8095a8';

export const primary100 = '#3498db';
export const primary200 = '#286090';
export const primary300 = '#204d74';

const green100 = '#5cb85c';
const green200 = '#4cae4c';
const green300 = '#449d44';
const green400 = '#398439';
export const green500 = '#dff0d8';
export const green600 = '#d6e9c6';
export const green700 = '#3c763d';

export const blue100 = '#d9edf7';
export const blue200 = '#bce8f1';
export const blue300 = '#31708f';

export const warning100 = '#fcf8e3';
export const warning200 = '#faebcc';
export const warning300 = '#8a6d3b';

export const red100 = '#f2dede';
export const red200 = '#ebccd1';
export const red300 = '#a94442';

export const default100 = '#e6e6e6';
export const default200 = '#ccc';
const default300 = '#adadad';
export const default400 = '#f2f2f2';
export const default500 = '#5e5e5e';
export const default600 = '#f7f7f7';

const danger100 = '#d9534f';
const danger200 = '#d43f3a';
export const danger300 = '#c9302c';
const danger400 = '#ac2925';

export const gray100 = '#f5f5f5';
export const gray200 = '#e5e5e5';
export const gray300 = '#ddd';
export const gray400 = '#999c9e';
export const gray500 = '#262626';
export const gray600 = '#f2f2f2';
export const gray700 = '#5e5e5e';
export const gray800 = '#828282';

export const primaryButton = {
  normal: {
    bg: primary100,
    border: primary100,
    fg: white,
  },
  hover: {
    bg: primary200,
    border: primary300,
  },
  green: {
    bg: green100,
    border: green200,
    fg: white,
  },
  greenHover: {
    bg: green300,
    border: green400,
  },
};

export const defaultButton = {
  normal: {
    bg: white,
    border: default200,
    fg: black,
  },
  hover: {
    bg: default100,
    border: default300,
  },
};

export const dangerButton = {
  normal: {
    bg: danger100,
    border: danger200,
    fg: white,
  },
  hover: {
    bg: danger300,
    border: danger400,
  },
};

export const successButton = {
  normal: {
    bg: green100,
    border: green200,
    fg: white,
  },
  hover: {
    bg: green300,
    border: green400,
  },
};

import React from 'react';
import type { IconComponentProps } from 'ui/icons/IconsTypes';

export const KanbanIcon = (props: IconComponentProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 7C1.22386 7 1 6.77614 1 6.5V2.5C1 2.22386 1.22386 2 1.5 2H4.5C4.77614 2 5 2.22386 5 2.5V6.5C5 6.77614 4.77614 7 4.5 7H1.5ZM2 6H4V3H2V6ZM6.5 14.0001C6.22386 14.0001 6 13.7762 6 13.5001V2.50007C6 2.22393 6.22386 2.00007 6.5 2.00007H9.5C9.77614 2.00007 10 2.22393 10 2.50007V13.5001C10 13.7762 9.77614 14.0001 9.5 14.0001H6.5ZM7 13.0001H9V3.00007H7V13.0001ZM11 9.50007C11 9.77621 11.2239 10.0001 11.5 10.0001H14.5C14.7761 10.0001 15 9.77621 15 9.50007V2.50007C15 2.22393 14.7761 2.00007 14.5 2.00007H11.5C11.2239 2.00007 11 2.22393 11 2.50007V9.50007ZM14 9.00007H12V3.00007H14V9.00007Z"
        fill="currentColor"
      />
    </svg>
  );
};

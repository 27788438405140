import React from 'react';

const DownloadIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line
        x1="12"
        y1="5.5"
        x2="12"
        y2="15.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 12L12.0355 15.5355L15.5711 12"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M5.5 15V18H18.5V15"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadIcon;

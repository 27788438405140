import React from 'react';

const CloneIcon = props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 2A1.5 1.5 0 0 0 5 3.5v6A1.5 1.5 0 0 0 6.5 11h6A1.5 1.5 0 0 0 14 9.5v-6A1.5 1.5 0 0 0 12.5 2h-6zM6 3.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5v-6zm-4 4a.5.5 0 0 1 .5-.5h2V6h-2A1.5 1.5 0 0 0 1 7.5v6A1.5 1.5 0 0 0 2.5 15h6a1.5 1.5 0 0 0 1.5-1.5v-2H9v2a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5v-6zm5-1a.5.5 0 0 1 .5-.5H9V4.5a.5.5 0 0 1 1 0V6h1.5a.5.5 0 0 1 0 1H10v1.5a.5.5 0 0 1-1 0V7H7.5a.5.5 0 0 1-.5-.5z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloneIcon;

import React from 'react';

const WindIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.60873 4.79412C5.49889 4.79412 4.54761 3.9221 4.54761 2.733C4.54761 1.54389 5.49889 0.671875 6.60873 0.671875C7.63929 0.671875 8.43203 1.38534 8.66985 2.33663"
        stroke="currentColor"
        strokeWidth="1.10294"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M3.12062 2.97069C2.96207 2.65359 2.5657 2.41577 2.16933 2.41577C1.53514 2.41577 0.980225 2.97069 0.980225 3.60488C0.980225 4.23907 1.53514 4.79399 2.16933 4.79399H11.6822"
        stroke="currentColor"
        strokeWidth="1.10294"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M4.30983 8.28209C4.15128 8.59919 3.75491 8.75773 3.35854 8.75773C2.72435 8.75773 2.16943 8.20282 2.16943 7.56863C2.16943 6.93443 2.72435 6.37952 3.35854 6.37952H9.93828"
        stroke="currentColor"
        strokeWidth="1.10294"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M7.48071 7.96497H12.3164"
        stroke="currentColor"
        strokeWidth="1.10294"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default WindIcon;

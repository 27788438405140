import React from 'react';

export const ZoomOutAltIcon = props => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 17C14.3137 17 17 14.3137 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11C5 14.3137 7.68629 17 11 17ZM11 18C12.8058 18 14.4519 17.3162 15.6935 16.1935L19.9497 20.4497L20.6569 19.7426L16.3858 15.4716C17.3938 14.2589 18 12.7002 18 11C18 7.13401 14.866 4 11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18ZM8 11.5H14V10.5H8V11.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

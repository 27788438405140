import React from 'react';

const GeothermalIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.541504 7.57453C1.81678 6.29925 2.15685 7.57453 3.17707 7.40449"
        stroke="currentColor"
        strokeWidth="0.735294"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.04328 7.57453C7.76801 6.29925 7.42793 7.57453 6.40771 7.40449"
        stroke="currentColor"
        strokeWidth="0.735294"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.3916 8.76477C1.98673 8.76477 1.98673 9.18986 2.49684 9.18986C3.09197 9.18986 3.09197 8.76477 3.60208 8.76477C4.19721 8.76477 4.19721 9.18986 4.70732 9.18986C5.30245 9.18986 5.30245 8.76477 5.81256 8.76477C6.40769 8.76477 6.40769 9.18986 6.9178 9.18986C7.51292 9.18986 7.51292 8.76477 8.02303 8.76477"
        stroke="currentColor"
        strokeWidth="0.735294"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.21747 6.21425V1.19816C5.21747 0.943108 5.04744 0.773071 4.79238 0.773071C4.45231 0.773071 4.28227 0.943108 4.28227 1.19816V6.21425C4.02721 6.38428 3.85718 6.72436 3.85718 6.97941C3.85718 7.48952 4.28227 7.8296 4.70736 7.8296C5.21747 7.8296 5.55755 7.40451 5.55755 6.97941C5.64256 6.72436 5.47253 6.38428 5.21747 6.21425Z"
        stroke="currentColor"
        strokeWidth="0.735294"
        strokeLinejoin="round"
      />
      <path
        d="M6.40779 2.64343H5.21753"
        stroke="currentColor"
        strokeWidth="0.735294"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.40779 3.83374H5.21753"
        stroke="currentColor"
        strokeWidth="0.735294"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.40779 4.93896H5.21753"
        stroke="currentColor"
        strokeWidth="0.735294"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GeothermalIcon;

import { identityDecoder } from './legacy-http';
import { request, urlFactory } from './utils';

export const url = urlFactory('api/companies');

const companies = {
  getProjectCompanies: () =>
    request(
      {
        url: url('/project-companies'),
        method: 'GET',
      },
      identityDecoder,
    ),
};

export default companies;

import React from 'react';
import styled from '@emotion/styled';
import { Spin } from 'antd';

const StyledSpin = styled(Spin)`
  width: 100%;
  padding: 32px 0;
`;

const ComponentLoader = () => {
  return <StyledSpin role="progressbar" />;
};

export default ComponentLoader;

import eachSeries from 'async/eachSeries';

export default class ChainSelect {
  constructor(chain) {
    this.chain = chain;
    this.noneIndicator = '---------';

    this.bindSelects();
    this.addLoaderToSelects();
    this.populateSelects();
    this.enableNextSelect(-1);
    this.enablePopulatedSelects();
  }

  addLoaderToSelects() {
    for (let part of this.chain) {
      let $select = part.$select;
      let $picker = $select.next();
      let $loader = $('<i class="loader fa fa-refresh fa-spin fa-fw"></i>');

      $picker.css('position', 'relative').append($loader);
      $loader
        .css({
          position: 'absolute',
          top: 36,
          left: 8,
          'z-index': 999,
          color: '#999',
        })
        .hide();
    }
  }

  bindSelects() {
    let index = 0;

    for (let part of this.chain) {
      let chainIndex = index;
      let $select = part.$select;

      $select
        .selectpicker({
          liveSearch: true,
          container: 'body',
        })
        .on('change', () => {
          this.onSelectChange(chainIndex);
        });

      if (!$select.val()) {
        this.disableSelect($select);
      }

      index++;
    }
  }

  populateSelects() {
    let i = 0;

    eachSeries(
      this.chain,
      (part, seriesCb) => {
        let $select = part.$select;
        let val = $select.val();
        let nextPart = this.chain[i + 1];
        let $nextSelect = null;
        let nextVal = null;

        if (!nextPart) {
          return seriesCb();
        } else {
          $nextSelect = nextPart.$select;
          nextVal = $nextSelect.val();
        }

        if (!val) {
          seriesCb();
        } else {
          part.onChange(val, (err, options) => {
            if (err) {
              return seriesCb(err);
            }

            for (let option of options) {
              if (+option.value !== +nextVal) {
                $nextSelect.append(
                  `<option value="${option.value}">${option.text}</option>`,
                );
              }
            }

            $nextSelect.selectpicker('refresh');
            i++;
            seriesCb();
          });
        }
      },
      err => {
        if (err) {
          // oops
        }
      },
    );
  }

  onSelectChange(chainIndex) {
    let part = this.chain[chainIndex];
    let nextPart = this.chain[chainIndex + 1];
    let val = part.$select.val();
    let triggerNoneEvent = part.$select.data('trigger-none-event');

    this.disableSelectsAfterIndex(chainIndex);
    if (!triggerNoneEvent)
      if (!val || val === this.noneIndicator) {
        return;
      }

    if (nextPart) {
      nextPart.$select.next().children('.loader').fadeIn();
    }

    part.onChange.call(this, val, (err, options) => {
      if (val) {
        if (chainIndex !== this.chain.length - 1) {
          this.enableNextSelect(chainIndex, options);
        }
      }

      if (nextPart) {
        nextPart.$select.next().children('.loader').fadeOut();
      }
    });
  }

  disableSelect($select) {
    $select.attr('disabled', 'disabled');
    $select.val(null).selectpicker('refresh');
  }

  enableSelect($select) {
    $select.removeAttr('disabled').selectpicker('refresh');
  }

  disableSelectsAfterIndex(chainIndex) {
    for (let i = chainIndex + 1; i < this.chain.length; i++) {
      this.disableSelect(this.chain[i].$select);
    }
  }

  enableNextSelect(chainIndex, options) {
    let part = this.chain[chainIndex + 1];
    let $select = part.$select;

    if (options) {
      options = options.sort((obj1, obj2) => {
        return obj1.text.charCodeAt(0) - obj2.text.charCodeAt(0);
      });

      $select.html('');
      $select.append(`<option value="">${this.noneIndicator}</option>`);

      for (let option of options) {
        $select.append(
          `<option value="${option.value}">${option.text}</option>`,
        );
      }
    }

    this.enableSelect($select);
  }

  enablePopulatedSelects() {
    for (let i = 0; i < this.chain.length; i++) {
      let $select = this.chain[i].$select;
      let nextPart = this.chain[i + 1];

      if ($select.val()) {
        this.enableSelect($select);

        if (nextPart) {
          this.enableSelect(nextPart.$select);
        }
      }
    }
  }
}

import React from 'react';

const BiogasIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.40582 5.58983C8.40582 5.24976 8.40582 4.90968 8.3208 4.73965C8.15076 3.1243 7.21556 2.27411 6.62043 0.913818C7.98073 4.73965 6.53541 6.69507 4.92007 7.12016C6.11032 5.58983 5.94029 3.97448 3.89984 3.1243C4.57999 4.22953 4.06988 5.50481 3.13468 5.33477C2.19948 5.16474 1.94442 3.29433 2.96464 0.913818C2.19948 2.44415 1.17926 3.29433 1.09424 5.58983C1.09424 5.67485 1.09424 5.67485 1.09424 5.75987C1.09424 7.80031 2.70959 9.41566 4.75003 9.41566C6.79047 9.41566 8.40582 7.80031 8.40582 5.75987C8.40582 5.67485 8.40582 5.67485 8.40582 5.58983Z"
        stroke="currentColor"
        strokeWidth="0.735294"
        strokeLinejoin="round"
      />
      <path
        d="M2.45459 4.39957C2.70964 2.86924 3.64485 1.67898 5.17518 0.913818C4.92012 2.27411 7.72573 3.29433 6.79053 5.50481"
        stroke="currentColor"
        strokeWidth="0.735294"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BiogasIcon;

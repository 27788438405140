import React, { useEffect } from 'react';
import { clearLocalStorage } from '@bluepoint/common/legacy/utils';
import { Helmet } from 'react-helmet';
import Body from './Body';
import Header from './Header';
import Footer from './Footer';
import FormLogin from './FormLogin';

function AppLogin() {
  useEffect(() => {
    clearLocalStorage();
  }, []);

  return (
    <>
      <Helmet>
        <title>BluePoint - {gettext('Login')}</title>
      </Helmet>
      <Body>
        <Header>
          <h3>{gettext('Welcome to BluePoint')}</h3>
          <p>{gettext('Enter the Login credentials and click on Login')}</p>
        </Header>
        <FormLogin />
        <Footer showLinks />
      </Body>
    </>
  );
}

export default AppLogin;

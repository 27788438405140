import type { QueryClientConfig } from '@tanstack/react-query';
import {
  QueryClient,
  QueryClientProvider as TanstackQueryClientProvider,
} from '@tanstack/react-query';
import _merge from 'lodash/merge';
import React, { useMemo } from 'react';

let queryClient: QueryClient;

const commonQueryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
    },
  },
};

export function getQueryClient(overrides?: Partial<QueryClientConfig>) {
  if (!queryClient) {
    queryClient = new QueryClient(
      _merge({}, commonQueryClientConfig, overrides),
    );
  }

  return queryClient;
}

export const QueryClientProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const client = useMemo(() => getQueryClient(), []);
  return (
    <TanstackQueryClientProvider client={client}>
      {children}
    </TanstackQueryClientProvider>
  );
};

import React from 'react';
import PropTypes from 'prop-types';

const ChevronIcon = ({ direction, style = {}, ...props }) => {
  let transform = null;
  if (direction === 'up') {
    transform = 'rotate(180deg)';
  } else if (direction === 'left') {
    transform = 'rotate(90deg)';
  } else if (direction === 'right') {
    transform = 'rotate(-90deg)';
  }

  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ transform, ...style }}
    >
      <path
        d="M3.75736 7.24264L8 11.4853L12.2196 7.21957"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ChevronIcon.propTypes = {
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  style: PropTypes.object,
};

ChevronIcon.defaultProps = {
  direction: 'down',
  style: {},
};

export default ChevronIcon;

import React from 'react';

const ArchiveIcon = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 3A1.5 1.5 0 0 0 2 4.5v3A1.5 1.5 0 0 0 3.5 9H4v9.5A2.5 2.5 0 0 0 6.5 21h11a2.5 2.5 0 0 0 2.5-2.5V9h.5A1.5 1.5 0 0 0 22 7.5v-3A1.5 1.5 0 0 0 20.5 3h-17zM19 9H5v9.5A1.5 1.5 0 0 0 6.5 20h11a1.5 1.5 0 0 0 1.5-1.5V9zm1-1h.5a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-17a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5H20zM8 12.501a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z"
        fill="#494948"
      />
    </svg>
  );
};

export default ArchiveIcon;

import React from 'react';

const MapMarkerCluster = ({ text, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle
        cx="25px"
        cy="25px"
        r="20"
        fill="#A1A3A6"
        stroke="white"
        strokeWidth="2"
      />
      <text
        x="24"
        y="32"
        fontSize="12pt"
        fontFamily="arial"
        fontWeight="bold"
        textAnchor="middle"
        fill="white"
      >
        {text}
      </text>
    </svg>
  );
};

export default MapMarkerCluster;

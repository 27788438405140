import React from 'react';

export const RecurrentIcon = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.079 4.103a3.928 3.928 0 0 1 3.645 1.054l.011.011 1.738 1.634H10.52a.5.5 0 0 0 0 1h2.952a.5.5 0 0 0 .5-.5V4.35a.5.5 0 0 0-1 0v1.547l-1.546-1.452A4.927 4.927 0 0 0 3.3 6.29a.5.5 0 0 0 .943.334 3.928 3.928 0 0 1 2.836-2.52zM2.507 8.488a.5.5 0 0 0-.5.5v2.952a.5.5 0 0 0 1 0v-1.77l1.856 1.744a4.928 4.928 0 0 0 8.125-1.845.5.5 0 1 0-.942-.334 3.927 3.927 0 0 1-6.481 1.467l-.012-.011-1.811-1.703h1.716a.5.5 0 0 0 0-1H2.507z"
        fill="currentColor"
      />
    </svg>
  );
};

import React from 'react';

const PlusCircleIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g transform="translate(3 3)">
          <circle stroke="currentColor" cx="9" cy="9" r="9" />
          <path stroke="currentColor" strokeLinecap="square" d="M6 9h6M9 6v6" />
        </g>
      </g>
    </svg>
  );
};

export default PlusCircleIcon;

import React from 'react';
import { IconComponentProps } from 'ui/icons/IconsTypes';

export const AddIcon = (props: IconComponentProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.5C12 4.22386 11.7761 4 11.5 4C11.2239 4 11 4.22386 11 4.5V12H3.5C3.22386 12 3 12.2239 3 12.5C3 12.7761 3.22386 13 3.5 13H11V20.5C11 20.7761 11.2239 21 11.5 21C11.7761 21 12 20.7761 12 20.5V13H19.5C19.7761 13 20 12.7761 20 12.5C20 12.2239 19.7761 12 19.5 12H12V4.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { Modal } from 'antd';
import notify from 'ui/notify/notify';
import { DELETE_ITEM_POP_OPTIONS } from '@bluepoint/asset-management/confirm-popups.config';
import { http } from '../api/http';
import { templateFormat } from './strings';
import { getUrlParameter } from './uri';

export const deleteRecord = (e, extraConfirmOptions) => {
  e.preventDefault();
  console.log('deleteRecord');
  const element = e.currentTarget;
  const { id, url: deleteUrl, successUrl, name: itemName } = element.dataset;
  Modal.confirm({
    ...DELETE_ITEM_POP_OPTIONS,
    ...extraConfirmOptions,
    onOk: close => {
      http
        .delete(deleteUrl, {
          data: JSON.stringify([id]),
        })
        .then(() => {
          notify.success(
            templateFormat(gettext('%s has been deleted.'), [itemName]),
            gettext('Deleted!'),
          );
          window.location.href = successUrl;
        })
        .catch(() => {
          notify.error(
            templateFormat(
              gettext('Deleting the %s failed. Try again later.'),
              [itemName],
            ),
          );
          window.location.reload();
        })
        .finally(() => {
          close();
        });
    },
  });
};

export const prepareDelete = url => {
  const next = getUrlParameter('next');
  const deleteElement = document.querySelector('.deleteElement');
  if (deleteElement) deleteElement.dataset.successUrl = next || url;
};

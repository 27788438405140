import React from 'react';
import type { IconComponentProps } from 'ui/icons/IconsTypes';

export const AppSwitcherIcon = (props: IconComponentProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 8.5H8.5V5H5V8.5ZM10.25 19H13.75V15.5H10.25V19ZM5 19H8.5V15.5H5V19ZM5 13.75H8.5V10.25H5V13.75ZM10.25 13.75H13.75V10.25H10.25V13.75ZM15.5 5V8.5H19V5H15.5ZM10.25 8.5H13.75V5H10.25V8.5ZM15.5 13.75H19V10.25H15.5V13.75ZM15.5 19H19V15.5H15.5V19Z"
        fill="currentColor"
      />
    </svg>
  );
};

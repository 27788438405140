import React from 'react';

const DecommissionedIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="#8F251D"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 6.5V7h1V2h-1v4.5zM4 8a4 4 0 1 0 6.228-3.323l.512-.86a5 5 0 1 1-5.553.048l.514.861A3.995 3.995 0 0 0 4 8z"
      />
    </svg>
  );
};

export default DecommissionedIcon;

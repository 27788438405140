import React from 'react';

const ItalicIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M7.334 2.834v1h1.264l-2.233 8.332H5v1h4v-1H7.402l2.233-8.332h1.699v-1h-4z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ItalicIcon;

import React from 'react';

const ScheduleIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.833 7.58203V11.082H15.333"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 16.915V13.415H9.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.46417 10.4986C7.76001 9.66258 8.26283 8.9151 8.92569 8.32595C9.58855 7.73679 10.3899 7.32515 11.2548 7.12944C12.1198 6.93372 13.0203 6.96032 13.8722 7.20674C14.7241 7.45316 15.4997 7.91137 16.1267 8.53862L18.8333 11.082M6 13.4153L8.70667 15.9586C9.3336 16.5859 10.1092 17.0441 10.9611 17.2905C11.8131 17.5369 12.7135 17.5635 13.5785 17.3678C14.4435 17.1721 15.2448 16.7604 15.9076 16.1713C16.5705 15.5821 17.0733 14.8347 17.3692 13.9986"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ScheduleIcon;

import React from 'react';

const CloseIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path
        d="M3.684 3.207a.5.5 0 00-.33.147.5.5 0 000 .707l3.888 3.888-3.888 3.89a.5.5 0 000 .708.5.5 0 00.707 0l3.888-3.889 3.889 3.889a.5.5 0 00.707 0 .5.5 0 000-.71L8.656 7.95l3.89-3.888a.5.5 0 000-.707.5.5 0 00-.378-.147.5.5 0 00-.33.147L7.949 7.242 4.061 3.354a.5.5 0 00-.377-.147z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloseIcon;

import React from 'react';

const SolarIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.20598 9.78587C8.57654 9.78587 9.6876 8.67481 9.6876 7.30425C9.6876 5.93369 8.57654 4.82263 7.20598 4.82263C5.83542 4.82263 4.72437 5.93369 4.72437 7.30425C4.72437 8.67481 5.83542 9.78587 7.20598 9.78587Z"
        stroke="currentColor"
        strokeWidth="1.10294"
        strokeMiterlimit="10"
      />
      <path
        d="M7.20605 3.16826V0.686646"
        stroke="currentColor"
        strokeWidth="1.10294"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M3.56626 5.23629L1.41553 3.99548"
        stroke="currentColor"
        strokeWidth="1.10294"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M3.56626 9.37231L1.41553 10.6131"
        stroke="currentColor"
        strokeWidth="1.10294"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M7.20605 11.4403V13.9219"
        stroke="currentColor"
        strokeWidth="1.10294"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.7629 9.37231L12.9137 10.6131"
        stroke="currentColor"
        strokeWidth="1.10294"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.7629 5.23629L12.9137 3.99548"
        stroke="currentColor"
        strokeWidth="1.10294"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SolarIcon;

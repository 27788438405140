import React from 'react';

const CalendarIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5998 5.59961H6.3998C5.51615 5.59961 4.7998 6.31595 4.7998 7.19961V18.3996C4.7998 19.2833 5.51615 19.9996 6.3998 19.9996H17.5998C18.4835 19.9996 19.1998 19.2833 19.1998 18.3996V7.19961C19.1998 6.31595 18.4835 5.59961 17.5998 5.59961Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1997 4V7.2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.7998 4V7.2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.7998 10.4004H19.1998"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarIcon;

import qs from 'qs';
import * as DOMPurify from 'dompurify';

export const templateFormat = (fmt, obj, named) => {
  if (named || !Array.isArray(obj)) {
    return fmt.replace(/%\(\w+\)s/g, match => {
      return String(obj[match.slice(2, -2)]);
    });
  }
  return fmt.replace(/%s/g, () => {
    return String(obj.shift());
  });
};

/**
 * Select the text of provided node
 * @param {HTMLElement} node
 */
export const selectText = node => {
  if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(node);
    range.select();
  } else if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

export const capitalize = string => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};

export const htmlEntities = str => {
  return String(str)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;');
};

export const stripHtml = html => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

export function sanitizeAndStripHtml(html) {
  return DOMPurify.sanitize(html, { ALLOWED_TAGS: [] });
}

export const isEmail = email => {
  const re =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return re.test(email);
};

export const toSnakeCase = string => {
  return string
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('_');
};

export const paramsSerializer = params => {
  return qs.stringify(params, { arrayFormat: 'repeat' });
};

export const getDOMObjectFromString = (htmlString, objectId) => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlString, 'text/html');
  return htmlDoc.getElementById(objectId);
};

export function ordinalSuffixOf(i) {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
}

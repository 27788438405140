import React from 'react';

const MapMarkerOtherIcon = ({ markerFill = '#6B747D', ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 34 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M8 5C12.9706 0.0294371 21.0294 0.0294371 26 5V5C30.9706 9.97056 30.9706 18.0294 26 23L17 32L8.00001 23C3.02945 18.0294 3.02944 9.97056 8 5V5Z"
          fill={markerFill}
        />
        <path
          d="M25.2929 22.2929L17 30.5858L8.70711 22.2929C4.12708 17.7128 4.12708 10.2871 8.70711 5.7071C13.2871 1.12707 20.7129 1.12707 25.2929 5.7071C29.8729 10.2871 29.8729 17.7128 25.2929 22.2929Z"
          stroke="white"
          strokeWidth="2"
        />
      </g>
      <circle
        cx="15.232"
        cy="12.9339"
        r="0.507186"
        transform="rotate(30 15.232 12.9339)"
        fill="white"
      />
      <ellipse
        cx="16.9889"
        cy="13.9483"
        rx="0.507186"
        ry="0.507186"
        transform="rotate(30 16.9889 13.9483)"
        fill="white"
      />
      <circle
        cx="18.7457"
        cy="14.9627"
        r="0.507186"
        transform="rotate(30 18.7457 14.9627)"
        fill="white"
      />
      <path
        d="M16.1914 19.2855L17.9666 16.2109"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M15.9404 11.6865L17.7156 8.61187"
        stroke="white"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0.271973"
          y="1.27209"
          width="33.4558"
          height="38.7279"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.419608 0 0 0 0 0.454902 0 0 0 0 0.490196 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default MapMarkerOtherIcon;

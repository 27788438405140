import React from 'react';

const RepeatableIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.1313 6L17.4545 8.3232L15.1313 10.6464"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 11.808V10.6464C7 9.36337 8.04013 8.32324 9.3232 8.32324H17.4544"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.3232 18.7776L7 16.4544L9.3232 14.1312"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4544 12.9696V14.1312C17.4544 15.4143 16.4143 16.4544 15.1312 16.4544H7"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RepeatableIcon;

import logger from '../utils/logger';
import { http } from './http';
import type { LookupItem } from './hooks/useLookup';
import { BIOMES, TIMEZONES } from './lookups.consts';

export * from './hooks/useLookup';

async function countries(): Promise<LookupItem[]> {
  try {
    const { data } = await http.get<{ countries: LookupItem[] }>(
      '/lookup/countries',
    );
    return data.countries;
  } catch (e) {
    logger.error('Error fetching countries', e);
    throw e;
  }
}

async function contacts(): Promise<LookupItem[]> {
  try {
    const { data } = await http.get<{ responsibles: LookupItem[] }>(
      '/lookup/responsibles',
    );
    return data.responsibles;
  } catch (e) {
    logger.error('Error fetching contacts', e);
    throw e;
  }
}

async function timezones(): Promise<LookupItem[]> {
  return Promise.resolve(TIMEZONES.map(tz => ({ id: tz, name: tz })));
}

async function biomes(): Promise<LookupItem[]> {
  return Promise.resolve(BIOMES);
}

export const lookups = {
  countries,
  contacts,
  timezones,
  biomes,
};

import { getCurrentClientId } from '~/stores/userInfoStore';

export const setClientTimeStampedLocalSettings = (key: string, data: any) => {
  const clientId = getCurrentClientId();
  if (!clientId) {
    throw new Error('Client ID not found');
  }
  console.log('setClientTimeStampedLocalSettings', clientId, key, data);
  const clientKey = `client_id_${clientId}_${key}`;
  const timeStamp = Math.floor(Date.now() / 1000);
  localStorage.setItem(clientKey, JSON.stringify({ timeStamp, data }));
};

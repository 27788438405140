import React from 'react';

const EventIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="8" cy="8" r="6.5" stroke="currentColor" />
      <line
        x1="8"
        y1="5.16699"
        x2="8"
        y2="8.16699"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse cx="7.99999" cy="10.7" rx="0.7" ry="0.7" fill="currentColor" />
    </svg>
  );
};

export default EventIcon;

import React, { useEffect, useState } from 'react';
import { ComponentLoader } from 'ui/loaders';
import logo from 'images/logo/PF-Logo-2x.png';
import axios from 'axios';
import { getParamsObject } from '@bluepoint/common/utils';
import { Alert, Button } from 'antd';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import { auth } from '~/stores/AuthStore';
import Body from './Body';

const SSO_LOGIN_ENDPOINT = '/api/v2/users/auth0-login';

const LoginWithSsoHandler = () => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<string[] | null>(null);
  const [logoutUrl, setLogoutUrl] = useState<string | null>(null);
  const [next, setNext] = useState<string | null>(null);

  useEffect(() => {
    setErrors(null);
    setLogoutUrl('/user/login');
    setLoading(true);
    const params = getParamsObject(window.location.search);
    const { code } = params;
    setNext(params.next);
    axios
      .post(SSO_LOGIN_ENDPOINT, { code, next: params.next })
      .then(res => {
        if (res.data.api_token !== '') auth.setAccessToken(res.data.api_token);
        window.location.replace(res.data.redirect_url);
      })
      .catch(err => {
        setErrors(
          err.response?.data?.errors ?? [gettext('Error while logging in.')],
        );
        setLogoutUrl(err.response?.data?.logout_url ?? '/user/logout');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>BluePoint - {gettext('Login')}</title>
      </Helmet>
      <Body>
        <div
          style={{
            lineHeight: 1.1,
            fontSize: 170,
            marginTop: 20,
            marginBottom: 10,
            textAlign: 'center',
          }}
        >
          <img src={logo} alt="" />
        </div>
        <div className="text-center">
          {loading ? (
            <>
              <h3>{gettext('Logging in with SSO...')}</h3>
              <ComponentLoader />
            </>
          ) : (
            <div style={{ padding: '32px 0' }}>
              {errors ? (
                <>
                  <Alert
                    message={errors.map(err => (
                      <div key={err}>{err}</div>
                    ))}
                    type="error"
                  />
                  <p style={{ marginTop: 32, textAlign: 'center' }}>
                    <Button
                      href={`${logoutUrl}${
                        next ? `?next=${encodeURIComponent(next)}` : ''
                      }`}
                    >
                      {gettext('Back to Login')}
                    </Button>
                  </p>
                </>
              ) : (
                'Redirecting'
              )}
            </div>
          )}
        </div>
        <p className="m-t">
          <small>
            {gettext('BluePoint renewable asset management software')} <br />
            <a href="https://powerfactos.com/">Power Factors LLC</a> &copy;{' '}
            {new Date().getFullYear()}
          </small>
        </p>
      </Body>
    </>
  );
};

export default LoginWithSsoHandler;

import { http } from '@bluepoint/common/api';
import logger from '@bluepoint/common/utils/logger';

const USER_API_URL = '/api/v2/users/';
const CURRENT_USER_URL = '/api/v2/users/request-user/';
const CURRENT_CLIENT_URL = '/api/v2/users/request-client/';
const AVAILABLE_CLIENTS = '/api/v2/users/available-clients';

const currentUser = async () => {
  try {
    const { data } = await http.get(CURRENT_USER_URL);
    return { ...data, temperatureUnit: data.temperature_unit };
  } catch (e) {
    console.error('[UsersApi] CurrentUser', e);
    throw e;
  }
};

const currentClient = async () => {
  try {
    const { data } = await http.get(CURRENT_CLIENT_URL);
    return data;
  } catch (e) {
    console.error('[UsersApi] currentClient', e);
    throw e;
  }
};

const availableClients = async () => {
  try {
    const { data } = await http.get(AVAILABLE_CLIENTS);
    return data;
  } catch (e) {
    console.error('[UsersApi] availableClients', e);
    throw e;
  }
};

const getInfo = async userIds => {
  try {
    const { data } = await http.get(`${USER_API_URL}info`, {
      params: {
        user_ids: userIds.join(','),
      },
    });
    return data;
  } catch (e) {
    logger.error(`[UsersApi] Get User Info`, e);
    throw e;
  }
};

const lookup = async (extraParams = {}) => {
  try {
    const { data } = await http.get('/lookup/responsibles', {
      params: { active: true, ...extraParams },
    });
    return data?.responsibles ?? [];
  } catch (e) {
    logger.error(`[UsersApi] Get Users Lookup`, e);
    throw e;
  }
};

/**
 * Get user portfolios permissions
 * @returns {Promise<UserPortfoliosResponse>}
 */
const userPortfolios = async () => {
  try {
    const { data } = await http.get(`${USER_API_URL}portfolios`);
    return data;
  } catch (e) {
    logger.error(`[UsersApi] Get User Portfolios`, e);
    throw e;
  }
};

const UsersApi = {
  getInfo,
  lookup,
  currentUser,
  userPortfolios,
  currentClient,
  availableClients,
};

export default UsersApi;

import React from 'react';

const PriorityHighIcon = ({ color = '#C1180B', ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 13L8 5" stroke={color} strokeLinecap="round" />
      <path
        d="M12.0022 8.65451L8 4.65229L3.99778 8.65451"
        stroke={color}
        strokeLinecap="round"
      />
      <path
        d="M12.0022 5.65451L8 1.65229L3.99778 5.65451"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PriorityHighIcon;

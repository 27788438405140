namespace Globals {
  export function getDocument() {
    return document;
  }

  export function getWindow(): Window &
    typeof globalThis & { [key: string]: any } {
    return window;
  }

  export function getLocation() {
    return getWindow().location;
  }

  export function getApp(): Record<string, any> {
    return window.app ?? {};
  }

  export function updateApp(app: any) {
    window.app = { ...getApp(), ...app };
  }

  export function getAppSettings(): Record<string, any> {
    return getApp().settings ?? {};
  }

  export function getAppEnv(): Record<string, any> {
    return getApp().env ?? {};
  }

  export function getApiUrl() {
    return getAppEnv().API_FE_URL;
  }

  export function getApiDocUrl() {
    return getAppEnv().API_DOC_URL;
  }
}

export default Globals;

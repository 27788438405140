import React from 'react';
import type { IconComponent } from 'ui/icons';

const FilterIcon: IconComponent = props => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7.5a.5.5 0 0 1 .5-.5h17a.5.5 0 0 1 0 1h-17a.5.5 0 0 1-.5-.5zM7 12a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 7 12zm3.5 4a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3z"
        fill="#494948"
      />
    </svg>
  );
};

export default FilterIcon;

import React from 'react';

const DotIcon = ({ fill, ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 7 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3.76466" cy="3.48085" r="2.94118" fill={fill} />
    </svg>
  );
};

export default DotIcon;

import React from 'react';

const CompanyIcon = ({ fill, ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 .838l-.637.182-5.55 1.585A2.498 2.498 0 002 5.008V14h-.5a.5.5 0 000 1h13a.5.5 0 000-1H14V5.281a1.5 1.5 0 00-1.137-1.455L10 3.11V.838zM9 2.162V14H3V5.008c0-.67.444-1.258 1.088-1.442L9 2.162zm1 1.979l2.621.654A.502.502 0 0113 5.28V14h-3V4.14zM4 5.5v1h1v-1H4zm3 0v1h1v-1H7zm4 0v1h1v-1h-1zm-7 3v1h1v-1H4zm3 0v1h1v-1H7zm4 0v1h1v-1h-1zm-7 3v1h1v-1H4zm3 0v1h1v-1H7zm4 0v1h1v-1h-1z"
        clipRule="evenodd"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CompanyIcon;

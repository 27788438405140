import React from 'react';

const PrinterIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v5h1.5A2.5 2.5 0 0 1 21 11v5a2.5 2.5 0 0 1-2.5 2.5H17v3a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-3H5.5A2.5 2.5 0 0 1 3 16v-5a2.5 2.5 0 0 1 2.5-2.5H7v-5ZM8 18v3h8v-6H8v3Zm9-.5v-3a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v3H5.5A1.5 1.5 0 0 1 4 16v-5a1.5 1.5 0 0 1 1.5-1.5h13A1.5 1.5 0 0 1 20 11v5a1.5 1.5 0 0 1-1.5 1.5H17ZM16 4v4.5H8V4h8Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PrinterIcon;

import React from 'react';

const AtSignIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M8.746 2.865c-1.068 0-2.014.234-2.84.701a4.735 4.735 0 00-1.908 1.965c-.446.847-.67 1.814-.67 2.899 0 1.493.418 2.656 1.252 3.49.839.83 2.015 1.246 3.53 1.246 1.017 0 1.925-.175 2.726-.525v-.832c-.943.358-1.85.537-2.727.537-1.264 0-2.232-.341-2.904-1.026-.672-.684-1.008-1.663-1.008-2.935 0-.939.182-1.765.545-2.479a3.814 3.814 0 011.576-1.65c.689-.388 1.498-.582 2.428-.582.738 0 1.392.154 1.96.463.57.304 1.01.739 1.32 1.306.308.568.462 1.23.462 1.985 0 .717-.117 1.301-.355 1.752-.238.446-.548.67-.928.67-.555 0-.832-.371-.832-1.114v-.138l.156-2.942a5.858 5.858 0 00-.968-.213 6.516 6.516 0 00-.965-.08c-.801 0-1.448.267-1.94.801-.492.53-.738 1.22-.738 2.07 0 .735.175 1.317.525 1.746.355.426.845.64 1.471.64.426 0 .793-.103 1.102-.307a1.98 1.98 0 00.72-.852h.05c.045.338.19.615.437.832.246.217.55.326.908.326a1.77 1.77 0 001.15-.406c.334-.271.594-.654.778-1.147a4.623 4.623 0 00.275-1.627c0-.905-.192-1.704-.576-2.396a3.91 3.91 0 00-1.621-1.607c-.697-.38-1.494-.57-2.39-.57zm-.15 3.268c.354 0 .682.041.982.125L9.492 7.89C9.417 9.196 8.947 9.85 8.084 9.85c-.813 0-1.22-.53-1.22-1.59 0-.668.152-1.19.456-1.565.309-.375.733-.562 1.276-.562z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AtSignIcon;

import React from 'react';

const CancelIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12" cy="12" r="6.5" stroke="currentColor" />
      <line
        x1="7.25014"
        y1="7.81593"
        x2="16.6544"
        y2="16.5838"
        stroke="currentColor"
      />
    </svg>
  );
};

export default CancelIcon;

import React from 'react';

const BellIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1c-.765 0-1.384.62-1.384 1.385 0 .348-.152.738-.464.894a4.154 4.154 0 00-2.306 3.72v2.77a.462.462 0 01-.461.462 1.385 1.385 0 100 2.769h9.23a1.385 1.385 0 000-2.77.461.461 0 01-.461-.46V7c0-1.63-.94-3.041-2.306-3.721-.312-.155-.463-.546-.463-.894C9.385 1.62 8.765 1 8 1z"
        />
      </mask>
      <path
        d="M12.615 9.23a1 1 0 100 2v-2zm-5-6.845C7.616 2.172 7.789 2 8 2V0a2.385 2.385 0 00-2.384 2.385h2zm-1.908-.002A5.154 5.154 0 002.847 7h2c0-1.236.71-2.308 1.75-2.826l-.89-1.79zM2.847 7v2.77h2V7h-2zm.538 2.23A2.385 2.385 0 001 11.616h2c0-.212.172-.384.385-.384v-2zM1 11.616A2.385 2.385 0 003.385 14v-2A.385.385 0 013 11.615H1zM3.385 14h9.23v-2h-9.23v2zm9.23 0A2.385 2.385 0 0015 11.615h-2a.385.385 0 01-.385.385v2zM15 11.615a2.385 2.385 0 00-2.385-2.384v2c.213 0 .385.172.385.384h2zM13.154 9.77V7h-2v2.77h2zm0-2.77a5.154 5.154 0 00-2.86-4.615l-.891 1.79A3.154 3.154 0 0111.153 7h2zM8 2c.213 0 .385.172.385.385h2A2.385 2.385 0 008 0v2zm2.294.384a.31.31 0 01.09.066c.016.017.02.027.018.023a.26.26 0 01-.017-.088h-2c0 .543.227 1.396 1.018 1.79l.89-1.791zm2.321 6.847c.298 0 .539.24.539.538h-2c0 .807.654 1.462 1.461 1.462v-2zm-9.769.538c0-.297.241-.538.539-.538v2c.807 0 1.461-.655 1.461-1.462h-2zm2.77-7.384a.26.26 0 01-.017.088c-.002.004.002-.006.017-.023a.308.308 0 01.09-.067l.892 1.791c.79-.393 1.018-1.246 1.018-1.79h-2z"
        fill="currentColor"
        mask="url(#a)"
      />
      <path
        d="M9.847 13.5c0 .884-.827 1.6-1.847 1.6s-1.846-.716-1.846-1.6"
        stroke="currentColor"
      />
    </svg>
  );
};

export default BellIcon;

import React from 'react';
import type { IconComponent } from 'ui/icons';

export const ClockIcon: IconComponent = props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      {...props}
    >
      <g id="Clock">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM13 6.5C13 6.22386 12.7761 6 12.5 6C12.2239 6 12 6.22386 12 6.5V12H8C7.72386 12 7.5 12.2239 7.5 12.5C7.5 12.7761 7.72386 13 8 13H12.5H13V12.5V6.5Z"
          fill="#494948"
        />
      </g>
    </svg>
  );
};

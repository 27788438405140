import React from 'react';

const ProjectIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.5 3.5C1.5 2.94772 1.94772 2.5 2.5 2.5L5.5 2.5V4.5H2.5C1.94772 4.5 1.5 4.05228 1.5 3.5V3.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 2.5V2C7.22386 2 7 2.22386 7 2.5H7.5ZM7.5 4.5H7C7 4.77614 7.22386 5 7.5 5V4.5ZM7.5 3H13.5V2H7.5V3ZM13.5 4H7.5V5H13.5V4ZM7 2.5V4.5H8V2.5H7ZM14 3.5C14 3.77614 13.7761 4 13.5 4V5C14.3284 5 15 4.32843 15 3.5H14ZM13.5 3C13.7761 3 14 3.22386 14 3.5H15C15 2.67157 14.3284 2 13.5 2V3Z"
        fill="currentColor"
      />
      <path
        d="M1.5 11.5C1.5 10.9477 1.94772 10.5 2.5 10.5L9.5 10.5V12.5H2.5C1.94772 12.5 1.5 12.0523 1.5 11.5V11.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 10.5V10C11.2239 10 11 10.2239 11 10.5H11.5ZM11.5 12.5H11C11 12.7761 11.2239 13 11.5 13V12.5ZM11.5 11H13.5V10H11.5V11ZM13.5 12H11.5V13H13.5V12ZM11 10.5V12.5H12V10.5H11ZM14 11.5C14 11.7761 13.7761 12 13.5 12V13C14.3284 13 15 12.3284 15 11.5H14ZM13.5 11C13.7761 11 14 11.2239 14 11.5H15C15 10.6716 14.3284 10 13.5 10V11Z"
        fill="currentColor"
      />
      <path
        d="M1.5 7.5C1.5 6.94772 1.94772 6.5 2.5 6.5H13.5C14.0523 6.5 14.5 6.94772 14.5 7.5V7.5C14.5 8.05228 14.0523 8.5 13.5 8.5H2.5C1.94772 8.5 1.5 8.05228 1.5 7.5V7.5Z"
        stroke="currentColor"
      />
      <path
        d="M11 6C10.7239 6 10.5 6.22386 10.5 6.5C10.5 6.77614 10.7239 7 11 7V6ZM11 7H13.5V6H11V7ZM13.5 8H11V9H13.5V8ZM14 7.5C14 7.77614 13.7761 8 13.5 8V9C14.3284 9 15 8.32843 15 7.5H14ZM13.5 7C13.7761 7 14 7.22386 14 7.5H15C15 6.67157 14.3284 6 13.5 6V7Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ProjectIcon;

import React from 'react';

const UserIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6663 13.25V12.0833C12.6663 11.4645 12.4205 10.871 11.9829 10.4334C11.5453 9.99583 10.9518 9.75 10.333 9.75H5.66634C5.0475 9.75 4.45401 9.99583 4.01643 10.4334C3.57884 10.871 3.33301 11.4645 3.33301 12.0833V13.25"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00033 7.41667C9.28899 7.41667 10.3337 6.372 10.3337 5.08333C10.3337 3.79467 9.28899 2.75 8.00033 2.75C6.71166 2.75 5.66699 3.79467 5.66699 5.08333C5.66699 6.372 6.71166 7.41667 8.00033 7.41667Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserIcon;

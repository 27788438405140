import React from 'react';

const BoldIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M4.334 3v10h4.332c1.645 0 3-1.355 3-3 0-.954-.464-1.802-1.168-2.354C10.813 7.172 11 6.606 11 6c0-1.645-1.355-3-3-3H4.334zm2 2H8c.564 0 1 .436 1 1 0 .564-.436 1-1 1H6.334V5zm0 4h2.332c.564 0 1 .436 1 1 0 .564-.436 1-1 1H6.334V9z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BoldIcon;

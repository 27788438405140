import React from 'react';

const PlusIcon = ({ ...props }) => {
  return (
    <svg
      role="img"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.535 7.521l-.025 8.026H7.484v1h8.022l-.026 8.021 1 .004.026-8.025h8.025v-1H16.51l.025-8.022-1-.004z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PlusIcon;

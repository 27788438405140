import React from 'react';

const NonOperationalIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4c-1.014 0-1.94.378-2.646 1H4a4.992 4.992 0 0 1 4-2c1.636 0 3.088.786 4 2h-1.354A3.985 3.985 0 0 0 8 4zm-4 7h1.354c.705.622 1.632 1 2.646 1s1.94-.378 2.646-1H12a4.993 4.993 0 0 1-4 2 4.993 4.993 0 0 1-4-2zm0-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm4 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm3 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0z"
        fill="#266B98"
      />
    </svg>
  );
};

export default NonOperationalIcon;

import React from 'react';
import type { IconComponentProps } from 'ui/icons/IconsTypes';

export const TemplateIcon = (props: IconComponentProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6.5C4 5.11929 5.11929 4 6.5 4H17.5C18.8807 4 20 5.11929 20 6.5V8.5V13.0926L21.8844 13.6481C22.0426 13.6947 22.1676 13.8163 22.2186 13.9732C22.2695 14.13 22.2399 14.3019 22.1393 14.4326L20.2505 16.8874L20.3357 19.9836C20.3402 20.1484 20.2632 20.3049 20.1298 20.4018C19.9964 20.4988 19.8237 20.5237 19.6683 20.4684L16.75 19.4307L13.8317 20.4684C13.6763 20.5237 13.5036 20.4988 13.3702 20.4018C13.2368 20.3049 13.1598 20.1484 13.1643 19.9836L13.1914 19H6.5C5.11929 19 4 17.8807 4 16.5V8.5V6.5ZM13.2189 18L13.2495 16.8874L11.3607 14.4326C11.2601 14.3019 11.2305 14.13 11.2814 13.9732C11.3324 13.8163 11.4574 13.6947 11.6156 13.6481L14.5866 12.7723L16.3376 10.2173C16.4308 10.0813 16.5851 10 16.75 10C16.9149 10 17.0692 10.0813 17.1624 10.2173L18.9134 12.7723L19 12.7978V9H9L9 18H13.2189ZM19 6.5V8H5V6.5C5 5.67157 5.67157 5 6.5 5H17.5C18.3284 5 19 5.67157 19 6.5ZM5 16.5V9H8L8 18H6.5C5.67157 18 5 17.3284 5 16.5ZM15.3109 13.4843L16.75 11.3845L18.1891 13.4843C18.2541 13.5791 18.3498 13.6487 18.4601 13.6812L20.9019 14.401L19.3496 16.4185C19.2794 16.5096 19.2429 16.6222 19.246 16.7372L19.316 19.2818L16.9175 18.4289C16.8092 18.3904 16.6908 18.3904 16.5825 18.4289L14.184 19.2818L14.254 16.7372C14.2571 16.6222 14.2206 16.5096 14.1504 16.4185L12.5981 14.401L15.0399 13.6812C15.1502 13.6487 15.2459 13.5791 15.3109 13.4843Z"
        fill="currentColor"
      />
    </svg>
  );
};

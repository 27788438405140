import type { Dayjs } from 'dayjs';
import type { PickerProps } from 'antd/lib/date-picker/generatePicker';
import DayjsDatePicker from './DatePicker/DayjsDatePicker';
import DayjsTimePicker from './DatePicker/DayjsTimePicker';
import DayjsCalendar from './DatePicker/DayjsCalendar';
import Select from './Select/AntdSelect';

/** TODO [Antd5]: Delete this after antd v5 upgrade */

// overrides
export const DatePicker = DayjsDatePicker;
export type DatePickerProps = PickerProps<Dayjs>;
export const TimePicker = DayjsTimePicker;
export const Calendar = DayjsCalendar;
export { Select };

// Re-export antd components
export declare const theme: null;
export { default as Affix } from 'antd/lib/affix';
export type { AffixProps } from 'antd/lib/affix';
export { default as Alert } from 'antd/lib/alert';
export type { AlertProps } from 'antd/lib/alert';
export { default as Anchor } from 'antd/lib/anchor';
export type { AnchorLinkProps, AnchorProps } from 'antd/lib/anchor';
export { default as AutoComplete } from 'antd/lib/auto-complete';
export type { AutoCompleteProps } from 'antd/lib/auto-complete';
export { default as Avatar } from 'antd/lib/avatar';
export type { AvatarProps } from 'antd/lib/avatar';
export { default as BackTop } from 'antd/lib/back-top';
export type { BackTopProps } from 'antd/lib/back-top';
export { default as Badge } from 'antd/lib/badge';
export type { BadgeProps } from 'antd/lib/badge';
export { default as Breadcrumb } from 'antd/lib/breadcrumb';
export type { BreadcrumbItemProps, BreadcrumbProps } from 'antd/lib/breadcrumb';
export { default as Button } from 'antd/lib/button';
export type { ButtonProps } from 'antd/lib/button';
export type { CalendarProps } from 'antd/lib/calendar';
export { default as Card } from 'antd/lib/card';
export type { CardProps } from 'antd/lib/card';
export { default as Carousel } from 'antd/lib/carousel';
export type { CarouselProps } from 'antd/lib/carousel';
export { default as Cascader } from 'antd/lib/cascader';
export type { CascaderProps } from 'antd/lib/cascader';
export { default as Checkbox } from 'antd/lib/checkbox';
export type { CheckboxOptionType, CheckboxProps } from 'antd/lib/checkbox';
export { default as Col } from 'antd/lib/col';
export type { ColProps } from 'antd/lib/col';
export { default as Collapse } from 'antd/lib/collapse';
export type { CollapsePanelProps, CollapseProps } from 'antd/lib/collapse';
export { default as Comment } from 'antd/lib/comment';
export type { CommentProps } from 'antd/lib/comment';
export { default as ConfigProvider } from 'antd/lib/config-provider';
export { default as Descriptions } from 'antd/lib/descriptions';
export type { DescriptionsProps } from 'antd/lib/descriptions';
export { default as Divider } from 'antd/lib/divider';
export type { DividerProps } from 'antd/lib/divider';
export { default as Drawer } from 'antd/lib/drawer';
export type { DrawerProps } from 'antd/lib/drawer';
export { default as Dropdown } from 'antd/lib/dropdown';
export type {
  DropdownProps,
  DropdownProps as DropDownProps,
} from 'antd/lib/dropdown';
export { default as Empty } from 'antd/lib/empty';
export type { EmptyProps } from 'antd/lib/empty';
export { default as Form } from 'antd/lib/form';
export type {
  FormInstance,
  FormItemProps,
  FormListFieldData,
  FormListOperation,
  FormProps,
  Rule as FormRule,
} from 'antd/lib/form';
export { default as Grid } from 'antd/lib/grid';
export { default as Image } from 'antd/lib/image';
export type { ImageProps } from 'antd/lib/image';
export { default as Input } from 'antd/lib/input';
export type { InputProps, InputRef } from 'antd/lib/input';
export { default as InputNumber } from 'antd/lib/input-number';
export type { InputNumberProps } from 'antd/lib/input-number';
export { default as Layout } from 'antd/lib/layout';
export type { LayoutProps, SiderProps } from 'antd/lib/layout';
export { default as List } from 'antd/lib/list';
export type { ListProps } from 'antd/lib/list';
export { default as Mentions } from 'antd/lib/mentions';
export type { MentionProps } from 'antd/lib/mentions';
export { default as Menu } from 'antd/lib/menu';
export type {
  MenuItemProps,
  MenuProps,
  MenuTheme,
  SubMenuProps,
} from 'antd/lib/menu';
export { default as message } from 'antd/lib/message';
export type { ArgsProps as MessageArgsProps } from 'antd/lib/message';
export { default as Modal } from 'antd/lib/modal';
export type { ModalFuncProps, ModalProps } from 'antd/lib/modal';
export { default as notification } from 'antd/lib/notification';
export { default as PageHeader } from 'antd/lib/page-header';
export type { PageHeaderProps } from 'antd/lib/page-header';
export { default as Pagination } from 'antd/lib/pagination';
export type { PaginationProps } from 'antd/lib/pagination';
export { default as Popconfirm } from 'antd/lib/popconfirm';
export type { PopconfirmProps } from 'antd/lib/popconfirm';
export { default as Popover } from 'antd/lib/popover';
export type { PopoverProps } from 'antd/lib/popover';
export { default as Progress } from 'antd/lib/progress';
export type { ProgressProps } from 'antd/lib/progress';
export { default as Radio } from 'antd/lib/radio';
export type {
  RadioChangeEvent,
  RadioGroupProps,
  RadioProps,
} from 'antd/lib/radio';
export { default as Rate } from 'antd/lib/rate';
export type { RateProps } from 'antd/lib/rate';
export { default as Result } from 'antd/lib/result';
export type { ResultProps } from 'antd/lib/result';
export { default as Row } from 'antd/lib/row';
export type { RowProps } from 'antd/lib/row';
export { default as Segmented } from 'antd/lib/segmented';
export type { SegmentedProps } from 'antd/lib/segmented';
export type { RefSelectProps, SelectProps } from 'antd/lib/select';
export { default as Skeleton } from 'antd/lib/skeleton';
export type { SkeletonProps } from 'antd/lib/skeleton';
export { default as Slider } from 'antd/lib/slider';
export type { SliderSingleProps } from 'antd/lib/slider';
export { default as Space } from 'antd/lib/space';
export type { SpaceProps } from 'antd/lib/space';
export { default as Spin } from 'antd/lib/spin';
export type { SpinProps } from 'antd/lib/spin';
export { default as Statistic } from 'antd/lib/statistic';
export type { StatisticProps } from 'antd/lib/statistic';
export { default as Steps } from 'antd/lib/steps';
export type { StepProps, StepsProps } from 'antd/lib/steps';
export { default as Switch } from 'antd/lib/switch';
export type { SwitchProps } from 'antd/lib/switch';
export { default as Table } from 'antd/lib/table';
export type {
  ColumnGroupType as TableColumnGroupType,
  ColumnProps as TableColumnProps,
  ColumnsType as TableColumnsType,
  ColumnType as TableColumnType,
  TablePaginationConfig,
  TableProps,
} from 'antd/lib/table';
export { default as Tabs } from 'antd/lib/tabs';
export type { TabPaneProps, TabsProps } from 'antd/lib/tabs';
export { default as Tag } from 'antd/lib/tag';
export type { TagProps, TagType } from 'antd/lib/tag';
export type {
  TimePickerProps,
  TimeRangePickerProps,
} from 'antd/lib/time-picker';
export { default as Timeline } from 'antd/lib/timeline';
export type { TimelineItemProps, TimelineProps } from 'antd/lib/timeline';
export { default as Tooltip } from 'antd/lib/tooltip';
export type { TooltipProps } from 'antd/lib/tooltip';
export { default as Transfer } from 'antd/lib/transfer';
export type { TransferProps } from 'antd/lib/transfer';
export { default as Tree } from 'antd/lib/tree';
export type {
  AntTreeNodeProps as TreeNodeProps,
  DataNode as TreeDataNode,
  TreeProps,
} from 'antd/lib/tree';
export { default as TreeSelect } from 'antd/lib/tree-select';
export type { TreeSelectProps } from 'antd/lib/tree-select';
export { default as Typography } from 'antd/lib/typography';
export type { TypographyProps } from 'antd/lib/typography';
export { default as Upload } from 'antd/lib/upload';
export type { UploadFile, UploadProps } from 'antd/lib/upload';
export { default as version } from 'antd/lib/version';

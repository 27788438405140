import React from 'react';

const TypeIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path
        d="M6 2.5a.5.5 0 00-.5.5.5.5 0 00.5.5h4a.5.5 0 00.5-.5.5.5 0 00-.5-.5H6zM6 12.5a.5.5 0 00-.5.5.5.5 0 00.5.5h4a.5.5 0 00.5-.5.5.5 0 00-.5-.5H6zM3 5.5a.50005.50005 0 00-.5.5v4a.50005.50005 0 00.5.5h10a.50005.50005 0 00.5-.5V6a.50005.50005 0 00-.5-.5H3zm.5 1h9v3h-9v-3z"
        overflow="visible"
        fill="currentColor"
      />
    </svg>
  );
};

export default TypeIcon;

import React from 'react';

const MinusIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M4.5 7.5A.5.5 0 004 8a.5.5 0 00.5.5h7A.5.5 0 0012 8a.5.5 0 00-.5-.5h-7z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MinusIcon;

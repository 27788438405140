import { http } from './http';

const HIGHCHARTS_URL = '/api/v2/highcharts/export/';

/**
 * Request chart image from highcharts server
 *
 * @param {object} args highcharts server args
 * @return {Promise<any>}
 */
export const exportChartAsImage = async args => {
  try {
    const response = await http.post(HIGHCHARTS_URL, args);
    return response.data;
  } catch (e) {
    console.error('HighchartsExportApi', e);
    throw e;
  }
};

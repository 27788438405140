import React from 'react';

const NegativeIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path
        d="M8 2a6 6 0 00-6 6 6 6 0 006 6 6 6 0 006-6 6 6 0 00-6-6zM4.5 7.5h7a.5.5 0 01.5.5.5.5 0 01-.5.5h-7A.5.5 0 014 8a.5.5 0 01.5-.5z"
        fill="currentColor"
      />
    </svg>
  );
};

export default NegativeIcon;

import React from 'react';

const MoreIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="11" cy="16" r="1" stroke="currentColor" />
      <circle cx="16" cy="16" r="1" stroke="currentColor" />
      <circle cx="21" cy="16" r="1" stroke="currentColor" />
    </svg>
  );
};

export default MoreIcon;

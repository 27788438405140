import React from 'react';

const ActivityIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.61185 10.101L6.01085 12.443L3.66889 9.84196"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 12C6 10.3513 6.58192 8.75556 7.64322 7.4939C8.70452 6.23225 10.1771 5.38568 11.8014 5.10338C13.4257 4.82108 15.0976 5.12117 16.5223 5.95076C17.9471 6.78036 19.0333 8.08622 19.5895 9.63822C20.1458 11.1902 20.1364 12.8888 19.563 14.4345C18.9896 15.9803 17.889 17.274 16.4552 18.0878C15.0214 18.9016 13.3463 19.1832 11.7252 18.8829C10.1041 18.5827 8.641 17.7199 7.59372 16.4466"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M13 7.5V12L16.5 13.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ActivityIcon;

import React from 'react';

const ErrorIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 5V9" stroke="currentColor" strokeLinejoin="round" />
      <path d="M8 10V11" stroke="currentColor" strokeLinejoin="round" />
      <path
        d="M5.72183 2.5L10.2782 2.5L13.5 5.72183L13.5 10.2782L10.2782 13.5L5.72183 13.5L2.5 10.2782L2.5 5.72183L5.72183 2.5Z"
        stroke="currentColor"
      />
    </svg>
  );
};

export default ErrorIcon;

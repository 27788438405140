import React from 'react';
import PropTypes from 'prop-types';

const ArrowSmallIcon = ({ direction = 'right', style = {}, ...props }) => {
  let transform = null;
  if (direction === 'down') {
    transform = 'rotate(90deg)';
  } else if (direction === 'up') {
    transform = 'rotate(-90deg)';
  } else if (direction === 'left') {
    transform = 'rotate(180deg)';
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...props}
      style={{ transform, ...style }}
    >
      <path
        fill="currentColor"
        d="M 9.1640625 5.0683594 L 8.4570312 5.7753906 L 10.931641 8.25 L 8.4570312 10.724609 L 9.1640625 11.431641 L 12.345703 8.25 L 9.1640625 5.0683594 z "
        id="path20"
      />
      <path
        fill="currentColor"
        d="M 4.8613281 7.75 L 4.8613281 8.75 L 10.931641 8.75 L 10.931641 7.75 L 4.8613281 7.75 z "
        id="path16"
      />
    </svg>
  );
};

ArrowSmallIcon.propTypes = {
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  style: PropTypes.object,
};

ArrowSmallIcon.defaultProps = {
  direction: 'right',
  style: {},
};

export default ArrowSmallIcon;

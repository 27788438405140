import React from 'react';
import type { IconComponentProps } from 'ui/icons/IconsTypes';

export const SeverityCriticalIcon = (props: IconComponentProps) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Crtical">
        <path
          id="Line 88"
          d="M5 13L5 5"
          stroke="#C1180B"
          strokeLinecap="round"
        />
        <path
          id="Rectangle 70"
          d="M9.00222 8.65451L5 4.65229L0.997775 8.65451"
          stroke="#C1180B"
          strokeLinecap="round"
        />
        <path
          id="Rectangle 71"
          d="M9.00222 5.65451L5 1.65229L0.997775 5.65451"
          stroke="#C1180B"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

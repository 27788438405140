import React from 'react';

const RevertCircleIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.32324 9L7.00004 11.3232L9.32324 13.6464"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4546 14.808V13.6464C17.4546 12.3634 16.4145 11.3232 15.1314 11.3232H7.00019"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12" cy="12" r="9" stroke="currentColor" />
    </svg>
  );
};

export default RevertCircleIcon;

import React from 'react';

const ResetIcon = ({ ...props }) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M7.256 3a.5.5 0 00-.475.416l-.885 5.186a.497.497 0 00.413.576l5.189.857a.5.5 0 10.162-.986l-4.306-.711a6.28 6.28 0 0110.707 4.455c0 3.469-2.811 6.28-6.28 6.28a6.28 6.28 0 01-6.281-6.28.5.5 0 10-1 0 7.28 7.28 0 007.281 7.28 7.278 7.278 0 007.28-7.28A7.28 7.28 0 007.154 7.17l.612-3.586A.499.499 0 007.256 3z"
        color="#000"
        clipRule="evenodd"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default ResetIcon;

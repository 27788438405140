import React from 'react';

const MapMarkerHydro = ({ markerFill = '#38495C', ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M13 9C17.9706 4.02944 26.0294 4.02944 31 9V9C35.9706 13.9706 35.9706 22.0294 31 27L22 36L13 27C8.02945 22.0294 8.02944 13.9706 13 9V9Z"
          fill={markerFill}
        />
        <path
          d="M30.2929 26.2929L22 34.5858L13.7071 26.2929C9.12708 21.7128 9.12708 14.2871 13.7071 9.7071C18.2871 5.12707 25.7129 5.12707 30.2929 9.7071C34.8729 14.2871 34.8729 21.7128 30.2929 26.2929Z"
          stroke="white"
          strokeWidth="2"
        />
      </g>
      <path
        d="M21.8382 13.125C21.3507 15.5625 18.8157 18 19.0107 20.0475C19.0107 21.6075 20.2782 22.875 21.8382 22.875C23.3982 22.875 24.6657 21.6075 24.6657 20.0475C24.8607 18 22.3257 15.5625 21.8382 13.125Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6499 20.2222C20.6499 20.8962 21.2124 21.474 21.8687 21.474C21.9624 21.474 22.0562 21.474 22.1499 21.474"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="44"
          height="44"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.419608 0 0 0 0 0.454902 0 0 0 0 0.490196 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default MapMarkerHydro;

import React from 'react';
import type { IconComponent } from 'ui/icons/IconsTypes';

export const BaselinesIcon: IconComponent = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.83337 5C3.83337 4.72386 4.05723 4.5 4.33337 4.5H11.6667C11.9429 4.5 12.1667 4.72386 12.1667 5C12.1667 5.27614 11.9429 5.5 11.6667 5.5L4.33337 5.5C4.05723 5.5 3.83337 5.27614 3.83337 5ZM5.16671 8.33333C5.16671 8.05719 5.39056 7.83333 5.66671 7.83333H13.6667C13.9428 7.83333 14.1667 8.05719 14.1667 8.33333C14.1667 8.60948 13.9428 8.83333 13.6667 8.83333H5.66671C5.39056 8.83333 5.16671 8.60948 5.16671 8.33333ZM4.33337 11.1667C4.05723 11.1667 3.83337 11.3905 3.83337 11.6667C3.83337 11.9428 4.05723 12.1667 4.33337 12.1667L10.3334 12.1667C10.6095 12.1667 10.8334 11.9428 10.8334 11.6667C10.8334 11.3905 10.6095 11.1667 10.3334 11.1667L4.33337 11.1667Z"
        fill="currentColor"
      />
    </svg>
  );
};

import React from 'react';

export const CalculatorSmallIcon = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2h8a1 1 0 0 1 1 1v9.5a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zM2 3a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9.5a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3zm4 4h1v1H6V7zm1 2H6v1h1V9zm-1 2h1v1H6v-1zM5 7H4v1h1V7zM4 9h1v1H4V9zm1 2H4v1h1v-1zm3-4h1v1H8V7zm3 0h-1v1h2V7h-1zm-1 2h2v1h-2V9zM9 9H8v1h1V9zm-1 2h1v1H8v-1zm3 0h-1v1h2v-1h-1zM5 4h6v1H5V4zM4 3h8v3H4V3z"
        fill="currentColor"
      />
    </svg>
  );
};

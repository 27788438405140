import type { Root } from 'react-dom/client';
import { createRoot } from 'react-dom/client';
import type { ReactElement } from 'react';
import { flushSync } from 'react-dom';

const roots = new Map<Element, Root>();

export function createRootById(id: string, skipCache = false) {
  const el = document.getElementById(id);
  if (!el) {
    throw new Error(`Element with id "${id}" not found`);
  }
  if (!skipCache && roots.has(el)) {
    return roots.get(el)!;
  }
  const root = createRoot(el);
  roots.set(el, root);
  return root;
}

export function renderToString(reactElement: ReactElement) {
  const div = document.createElement('div');
  const root = createRoot(div);
  flushSync(() => {
    root.render(reactElement);
  });
  return div.innerHTML;
}

import React from 'react';

export const RedoIcon = props => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2368 10.3637C11.9643 10.4087 11.707 10.2242 11.662 9.95178C11.6171 9.67932 11.8015 9.42201 12.074 9.37705L16.6799 8.61706C15.4713 7.39591 13.7951 6.64035 11.9422 6.64035C8.26089 6.64035 5.27657 9.62467 5.27657 13.306C5.27657 16.9874 8.26089 19.9717 11.9422 19.9717C15.6236 19.9717 18.6079 16.9874 18.6079 13.306C18.6079 13.0299 18.8318 12.806 19.1079 12.806C19.3841 12.806 19.6079 13.0299 19.6079 13.306C19.6079 17.5396 16.1759 20.9717 11.9422 20.9717C7.70861 20.9717 4.27657 17.5396 4.27657 13.306C4.27657 9.07238 7.70861 5.64035 11.9422 5.64035C13.8195 5.64035 15.5393 6.31549 16.8717 7.43533L16.2123 3.57C16.1658 3.29779 16.3489 3.03948 16.6211 2.99304C16.8933 2.9466 17.1516 3.12963 17.198 3.40184L18.1328 8.88132C18.1552 9.01251 18.1243 9.1472 18.0471 9.25558C17.9699 9.36395 17.8526 9.43707 17.7213 9.45873L12.2368 10.3637Z"
        fill="currentColor"
      />
    </svg>
  );
};

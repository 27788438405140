export const DELETE_ITEM_POP_OPTIONS = {
  title: 'Are you sure you want to proceed?',
  content: 'This element will be permanently deleted.',
  okButtonProps: { danger: true },
  okText: 'Yes, delete it!',
  cancelText: 'No, cancel please!',
};

export const DELETE_CANCEL_POP_OPTIONS = {
  title: 'Canceled',
  text: 'Delete was canceled',
  type: 'error',
  showConfirmButton: false,
  timer: 700,
};

export const DELETE_SUCESS_POP_OPTIONS = itemName => {
  return {
    title: 'Deleted!',
    text: `${itemName} has been deleted.`,
    type: 'success',
    showConfirmButton: false,
    timer: 1500,
  };
};

import React from 'react';

const OutgoingIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M15.995,18.69l-1.276-0.639c-0.141-0.069-0.306-0.069-0.447,0l-1.775,0.889l-1.775-0.889
	c-0.072-0.035-0.147-0.053-0.225-0.053s-0.154,0.018-0.225,0.053L8.496,18.94l-1.775-0.889c-0.142-0.069-0.307-0.069-0.447,0
	L4.497,18.94l-1.776-0.889c-0.141-0.069-0.306-0.069-0.447,0L0.998,18.69V1.808l1.5-0.75l1.775,0.889
	c0.142,0.069,0.307,0.069,0.447,0l1.776-0.889l1.776,0.889c0.141,0.069,0.306,0.069,0.447,0l1.775-0.889l1.775,0.889
	c0.142,0.069,0.307,0.069,0.447,0l1.776-0.889l1.5,0.75v5.19h1.001V1.499c0-0.188-0.107-0.362-0.275-0.447l-2-1
	c-0.142-0.069-0.307-0.069-0.447,0L12.497,0.94l-1.776-0.889c-0.141-0.069-0.306-0.069-0.447,0L8.498,0.94L6.723,0.052
	c-0.142-0.069-0.307-0.069-0.447,0L4.499,0.94L2.723,0.052c-0.141-0.069-0.306-0.069-0.447,0l-2,1C0.107,1.136,0,1.311,0,1.499v18
	c0,0.173,0.09,0.334,0.237,0.425s0.331,0.1,0.485,0.022l1.776-0.889l1.776,0.889c0.141,0.069,0.306,0.069,0.447,0l1.775-0.889
	l1.775,0.889c0.142,0.069,0.307,0.069,0.447,0l1.776-0.889l1.776,0.889c0.141,0.069,0.306,0.069,0.447,0l1.775-0.889l1.775,0.889
	c0.072,0.036,0.148,0.053,0.225,0.053c0.092,0,0.184-0.025,0.264-0.075c0.146-0.091,0.236-0.252,0.236-0.425v-3.501h-1.001V18.69z"
      />
      <path
        fill="currentColor"
        d="M10.496,12.999h-3.5v-1h3.5c0.275,0,0.5-0.224,0.5-0.5s-0.225-0.5-0.5-0.5h-1.5v-0.5
	c0-0.276-0.225-0.5-0.5-0.5s-0.5,0.224-0.5,0.5v0.5h-1.5c-0.275,0-0.5,0.224-0.5,0.5v2c0,0.276,0.225,0.5,0.5,0.5h3.5v1h-3.5
	c-0.275,0-0.5,0.224-0.5,0.5s0.225,0.5,0.5,0.5h1.5v0.5c0,0.276,0.225,0.5,0.5,0.5s0.5-0.224,0.5-0.5v-0.5h1.5
	c0.275,0,0.5-0.224,0.5-0.5v-2C10.996,13.223,10.771,12.999,10.496,12.999"
      />
      <path
        fill="currentColor"
        d="M11.496,4.999h-6c-0.275,0-0.5-0.224-0.5-0.5s0.225-0.5,0.5-0.5h6c0.275,0,0.5,0.224,0.5,0.5
	S11.771,4.999,11.496,4.999"
      />
      <path
        fill="currentColor"
        d="M13.496,5.999h-10c-0.275,0-0.5,0.224-0.5,0.5s0.225,0.5,0.5,0.5h10c0.275,0,0.5-0.224,0.5-0.5
	S13.771,5.999,13.496,5.999z"
      />
      <path
        fill="currentColor"
        d="M13.496,7.999h-10c-0.275,0-0.5,0.224-0.5,0.5s0.225,0.5,0.5,0.5h10c0.271,0,0.492-0.219,0.498-0.49v-0.02
	C13.988,8.218,13.768,7.999,13.496,7.999z"
      />
      <polygon
        fill="currentColor"
        points="13.994,8.489 13.994,8.509 13.996,8.499 "
      />
      <path
        fill="currentColor"
        d="M17.019,4.287C17.02,4.13,16.796,4,16.521,3.998c-0.276-0.002-0.501,0.125-0.502,0.282L16,6.998h1
	L17.019,4.287z"
      />
      <path
        fill="currentColor"
        d="M16.493,8.001c0.275,0.002,0.5-0.125,0.502-0.282L17,6.998h-1l-0.005,0.714
	C15.993,7.869,16.217,8,16.493,8.001z"
      />
      <path
        fill="currentColor"
        d="M15.995,18.712c-0.002,0.157,0.222,0.288,0.498,0.289c0.275,0.002,0.5-0.125,0.502-0.282l0.019-2.721h-1
	L15.995,18.712z"
      />
      <path
        fill="currentColor"
        d="M16.521,14.998c-0.276-0.002-0.501,0.125-0.502,0.282l-0.005,0.718h1l0.005-0.711
	C17.02,15.13,16.796,15,16.521,14.998z"
      />
      <path
        fill="currentColor"
        d="M22.351,11.144v0.002l-3-3c-0.195-0.195-0.513-0.195-0.708,0c-0.194,0.194-0.194,0.512,0,0.706l2.146,2.147
	h-2.794v1h2.794l-2.146,2.145c-0.194,0.195-0.194,0.513,0,0.707c0.099,0.099,0.227,0.147,0.354,0.147
	c0.129,0,0.257-0.05,0.354-0.147l3-3C22.546,11.656,22.546,11.339,22.351,11.144z"
      />
      <path
        fill="currentColor"
        d="M14.496,10.999c-0.275,0-0.5,0.223-0.5,0.5c0,0.275,0.225,0.5,0.5,0.5h3.498v-1H14.496z"
      />
    </svg>
  );
};

export default OutgoingIcon;

import React from 'react';

const HydroIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.37708 1.53284C4.96262 3.60516 2.8074 5.67748 2.97319 7.41823C2.97319 8.74452 4.0508 9.82213 5.37708 9.82213C6.70337 9.82213 7.78098 8.74452 7.78098 7.41823C7.94676 5.67748 5.79155 3.60516 5.37708 1.53284Z"
        stroke="currentColor"
        strokeWidth="0.735294"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.36694 7.56677C4.36694 8.13986 4.84517 8.63108 5.4031 8.63108C5.48281 8.63108 5.56251 8.63108 5.64222 8.63108"
        stroke="currentColor"
        strokeWidth="0.735294"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default HydroIcon;

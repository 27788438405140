import React from 'react';

const EnergyIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M36.6065 19.8742H24.5871V3.76929L12.3936 29.1259H24.5871V45.2308L36.6065 19.8742Z"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EnergyIcon;

import React from 'react';

const PriorityLowIcon = ({ color = '#7DD693', ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line
        x1="8"
        y1="4.5"
        x2="8"
        y2="11.5"
        stroke={color}
        strokeLinecap="round"
      />
      <path
        d="M12.0022 8.00222L8 12.0044L3.99778 8.00222"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PriorityLowIcon;

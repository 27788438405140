import React from 'react';

const TimesIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.70696 7.70711L16.1922 16.1924"
        stroke="currentColor"
        strokeLinecap="square"
      />
      <path
        d="M7.70711 16.1924L16.1924 7.70712"
        stroke="currentColor"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default TimesIcon;

import React from 'react';

type Props = {
  children: React.ReactNode;
};

const Body = ({ children }: Props) => (
  <div className="gray-bg" style={{ height: '100%', width: '100%' }}>
    <div className="middle-box loginscreen animated fadeInDown">{children}</div>
  </div>
);

export default Body;

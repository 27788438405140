import React from 'react';

const ZoomOutIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 8 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 0.949999H7.5" stroke="currentColor" strokeLinecap="square" />
    </svg>
  );
};

export default ZoomOutIcon;

import React from 'react';

const FileAudioIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <defs>
        <clipPath id="a">
          <path d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <clipPath id="b">
          <path
            d="M13.846 12.482V7.923A5.923 5.923 0 002 7.923v4.559c0 .286.232.518.518.518h1.748a.567.567 0 00.567-.567V9.774a.567.567 0 00-.567-.567h-.975a.157.157 0 01-.158-.157V7.866a4.847 4.847 0 019.693 0V9.05c0 .087-.07.157-.157.157h-1.127a.642.642 0 00-.642.643v2.561a.59.59 0 00.589.589h1.839a.518.518 0 00.518-.518z"
            fill="#FFF"
          />
        </clipPath>
        <g clipPath="url(#b)">
          <path
            d="M13.328 13h-1.839a.59.59 0 01-.589-.589V9.85c0-.355.287-.643.642-.643h1.127c.087 0 .157-.07.157-.157V7.866a4.846 4.846 0 00-9.693 0V9.05c0 .087.071.157.158.157h.975c.313 0 .567.254.567.567v2.659a.567.567 0 01-.567.567H2.518A.518.518 0 012 12.482V7.923a5.923 5.923 0 0111.846 0v4.559a.518.518 0 01-.518.518zM11.9 10.21h.77c.06 0 .12-.01.18-.02V12h-.95v-1.79zM3.83 12H3v-1.83c.09.02.19.04.29.04h.54V12z"
            fillRule="evenodd"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};

export default FileAudioIcon;

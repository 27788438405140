import React from 'react';

const MilestoneTaskIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75036 2.36928C5.70592 1.81652 6.81524 1.5 8 1.5C10.8294 1.5 13.2377 3.30803 14.1303 5.83329C14.3696 6.51034 14.5 7.2394 14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8C1.5 5.59471 2.8062 3.49391 4.75036 2.36928ZM8 0.5C6.63475 0.5 5.3533 0.86524 4.24964 1.50367C2.00919 2.7997 0.5 5.22331 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 7.12431 15.3497 6.28263 15.0731 5.50004C14.0438 2.58774 11.2663 0.5 8 0.5ZM6.25542 3.0639C6.41317 2.97543 6.6064 2.97905 6.76073 3.07336L11.2607 5.82336L11.9589 6.25L11.2607 6.67664L7 9.28042V12.5C7 12.7761 6.77614 13 6.5 13C6.22386 13 6 12.7761 6 12.5V9.89153V9V4.5V3.5C6 3.31914 6.09767 3.15237 6.25542 3.0639ZM10.0411 6.25L7 8.10847V4.5V4.39153L10.0411 6.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MilestoneTaskIcon;

import { useEffect, useState } from 'react';
import type { Option } from 'ui/Select/AntdSelect';
import notify from 'ui/notify/notify';
import axios from 'axios';
import logger from '../../utils/logger';

export type LookupItem<T = any, P = number | string> = {
  id: P;
  name: T;
};

type UseLookupParam<
  T extends LookupItem = LookupItem,
  O extends Option = Option,
> = {
  api: () => Promise<T[]>;
  mapper?: (results: T[]) => O[];
  onError?: (error: Error) => void;
  messages?: {
    error?: string;
  };
};
export function useLookup<
  T extends LookupItem = LookupItem,
  O extends Option = Option,
>(params: UseLookupParam<T, O>) {
  const [options, setOptions] = useState<O[]>([]);
  const [loading, setLoading] = useState(true);

  const defaultOnError = (e: Error) => {
    if (!axios.isAxiosError(e)) logger.error('Lookup Error', e);
    notify.error(params.messages?.error ?? gettext('Error loading items'));
  };

  useEffect(() => {
    setLoading(true);
    params
      .api()
      .then(results => {
        if (params.mapper) {
          setOptions(params.mapper(results));
        } else {
          setOptions(
            results.map(item => ({ value: item.id, label: item.name } as O)),
          );
        }
      })
      .catch(e => {
        if (params.onError) params.onError(e);
        else defaultOnError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const prepend = (option: O) => {
    setOptions([option, ...options]);
  };

  return { options, loading, prepend };
}

import React from 'react';

const LinkIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M15.516 4.5a4.022 4.022 0 00-2.829 1.129.5.5 0 00-.005.004L11.47 6.838a.5.5 0 00-.002.707.5.5 0 00.707.002l1.207-1.2a3.02 3.02 0 014.27 4.272l-2.106 2.106a3.02 3.02 0 01-4.555-.327.5.5 0 00-.699-.101.5.5 0 00-.102.701 4.015 4.015 0 004.625 1.357 4.015 4.015 0 001.438-.923l2.113-2.112a.5.5 0 00.006-.008A4.021 4.021 0 0015.516 4.5zm-4.639 4.889a4.02 4.02 0 00-3.13 1.168l-2.114 2.111a.5.5 0 00-.006.006 4.021 4.021 0 002.857 6.814 4.022 4.022 0 002.829-1.129.5.5 0 00.005-.005l1.205-1.206a.5.5 0 000-.707.5.5 0 00-.707 0l-1.199 1.2a3.02 3.02 0 01-4.27-4.272l2.106-2.105a3.02 3.02 0 014.555.326.5.5 0 00.699.1.5.5 0 00.102-.7 4.021 4.021 0 00-1.291-1.12 4.02 4.02 0 00-1.641-.481z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LinkIcon;

import React from 'react';

const FilePdfIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path
        d="M4.5 1C3.677 1 3 1.677 3 2.5v11c0 .823.678 1.5 1.5 1.5h7c.823 0 1.5-.677 1.5-1.5V5.514a1.5 1.5 0 00-.44-1.06L9.548 1.438A1.5 1.5 0 008.487 1H4.5zm0 1h3.986a.5.5 0 01.354.146l3.014 3.014a.5.5 0 01.146.354V13.5c0 .282-.218.5-.5.5h-7a.493.493 0 01-.5-.5v-11c0-.282.218-.5.5-.5zm3.344 2c-.064 0-.255.033-.319.064a.6.6 0 00-.351.381c-.223.51.096 1.4.414 2.1a23.25 23.25 0 01-1.21 3.437c-1.208.573-1.844 1.114-1.907 1.623-.032.191.031.445.35.7a.614.614 0 00.318.095c.286 0 .572-.222.89-.668.255-.35.51-.796.764-1.369.827-.35 1.91-.7 2.8-.89.51.477.955.732 1.337.732.286 0 .508-.128.699-.383.19-.286.222-.508.127-.699-.127-.223-.413-.35-.922-.35-.318 0-.668.064-1.082.127-.573-.636-1.21-1.527-1.623-2.418.445-1.781.222-2.036.127-2.164C8.129 4.191 8.003 4 7.844 4zm-.096.572c.032 0 .35.352.031 1.02-.445-.446-.063-1.02-.031-1.02zm.19 2.705c.35.637.795 1.24 1.208 1.782a17.1 17.1 0 00-2.066.636c.318-.795.635-1.622.857-2.418zm2.927 2.037c.287 0 .351.031.383.063 0 .032-.033.063-.064.127-.096.127-.318.127-.381.127-.16 0-.224-.094-.479-.285.223 0 .414-.032.541-.032zm-4.867 1.463c-.54.955-.796 1.082-.86 1.082-.127-.127-.126-.19-.126-.222.032-.16.318-.478.986-.86z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FilePdfIcon;

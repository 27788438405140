import React from 'react';

const UnderlineIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M4.834 3.334v4.332A3.175 3.175 0 008 10.834a3.175 3.175 0 003.166-3.168V3.334h-1v4.332A2.16 2.16 0 018 9.834a2.16 2.16 0 01-2.166-2.168V3.334h-1zm.5 8.832v1h5.332v-1H5.334z"
        fill="currentColor"
      />
    </svg>
  );
};

export default UnderlineIcon;

import { identityDecoder } from './legacy-http';
import { request, urlFactory } from './utils';

export const url = urlFactory('api/accounting');

const accounting = {
  getYearBalance: ({ year, company }) =>
    request(
      {
        url: url('/year-balance'),
        method: 'GET',
        params: {
          year,
          company: company !== null ? company : undefined,
        },
      },
      identityDecoder,
    ),
};

export default accounting;

import React from 'react';
import { notification } from 'antd';
import {
  CheckIcon as SuccessIcon,
  ErrorIcon,
  InfoCircleIcon,
  WarningIcon,
} from 'ui/icons';

const customIcons = {
  success: <SuccessIcon />,
  error: <ErrorIcon />,
  info: <InfoCircleIcon />,
  warning: <WarningIcon />,
};

const customKeys = Object.keys(customIcons);

const isFirstParamMessage = config =>
  typeof config === 'string' || React.isValidElement(config);

// TODO-UI: needs documenation
const notify = (() => {
  const customConfig = props => {
    let { icon } = props;
    const { type, placement } = props;
    if (customKeys.includes(type)) {
      icon = customIcons[type];
    }
    return {
      ...props,
      icon,
      className: `bp-notification bp-notification-${type}`,
      placement: placement || 'bottomRight',
    };
  };

  return {
    success: (config, title = '') => {
      if (isFirstParamMessage(config))
        notification.success(
          customConfig({
            type: 'success',
            message: title || config,
            description: title ? config : undefined,
          }),
        );
      else notification.success(customConfig({ type: 'success', ...config }));
    },
    error: (config, title = '') => {
      if (isFirstParamMessage(config))
        notification.error(
          customConfig({
            type: 'error',
            message: title || config,
            description: title ? config : undefined,
          }),
        );
      else notification.error(customConfig({ type: 'error', ...config }));
    },
    info: (config, title = '') => {
      if (isFirstParamMessage(config))
        notification.info(
          customConfig({
            type: 'info',
            message: title || config,
            description: title ? config : undefined,
          }),
        );
      else notification.info(customConfig({ type: 'info', ...config }));
    },
    warning: (config, title = '') => {
      if (isFirstParamMessage(config))
        notification.warning(
          customConfig({
            type: 'warning',
            message: title || config,
            description: title ? config : undefined,
          }),
        );
      else notification.warning(customConfig({ type: 'warning', ...config }));
    },
    open: config => {
      notification.open(customConfig(config));
    },
    close: key => {
      notification.close(key);
    },
    destroy: () => {
      notification.destroy();
    },
  };
})();

export default notify;

import React from 'react';

const MilestoneDiamondCheck = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="#494948"
        stroke="#494948"
        d="m3.75 12 8.485-8.485L20.721 12l-8.486 8.485z"
      />
      <path
        d="m17.391 8.25-5.61 5.61L9 11.079"
        stroke="#fff"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default MilestoneDiamondCheck;

import React, { FC } from 'react';
import { Spin } from 'antd';
import { StyledLoaderContainer, StyledLoaderWrapper } from './styles.emotion';
import { FullPageLoaderProps } from './FullPageLoaderTypes';

const FullPageLoader: FC<FullPageLoaderProps> = ({
  wrapperOnly,
  hasOverlay,
  text,
}) => (
  <StyledLoaderWrapper wrapperOnly={wrapperOnly} hasOverlay={hasOverlay}>
    <StyledLoaderContainer>
      <Spin size="large" tip={text} />
    </StyledLoaderContainer>
  </StyledLoaderWrapper>
);

export default FullPageLoader;

import React from 'react';

const MapMarkerSolar = ({ markerFill = '#F5B741', ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M13 9C17.9706 4.02944 26.0294 4.02944 31 9V9C35.9706 13.9706 35.9706 22.0294 31 27L22 36L13 27C8.02945 22.0294 8.02944 13.9706 13 9V9Z"
          fill={markerFill}
        />
        <path
          d="M30.2929 26.2929L22 34.5858L13.7071 26.2929C9.12708 21.7128 9.12708 14.2871 13.7071 9.7071C18.2871 5.12707 25.7129 5.12707 30.2929 9.7071C34.8729 14.2871 34.8729 21.7128 30.2929 26.2929Z"
          stroke="white"
          strokeWidth="2"
        />
      </g>
      <path
        d="M22 20.25C23.2426 20.25 24.25 19.2426 24.25 18C24.25 16.7574 23.2426 15.75 22 15.75C20.7574 15.75 19.75 16.7574 19.75 18C19.75 19.2426 20.7574 20.25 22 20.25Z"
        stroke="white"
        strokeMiterlimit="10"
      />
      <path
        d="M22 14.25V12"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M18.7 16.125L16.75 15"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M18.7 19.875L16.75 21"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M22 21.75V24"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M25.2251 19.875L27.1751 21"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M25.2251 16.125L27.1751 15"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="44"
          height="44"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.419608 0 0 0 0 0.454902 0 0 0 0 0.490196 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default MapMarkerSolar;

import React from 'react';
import { IconComponentProps } from 'ui/icons/IconsTypes';

export const ChangedDataIcon = (props: IconComponentProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75237 8.24769C4.88905 8.38437 5.11066 8.38437 5.24734 8.24769C5.38402 8.111 5.38402 7.8894 5.24734 7.75271L3.34468 5.85005L12 5.85005C12.1933 5.85005 12.35 5.69335 12.35 5.50005C12.35 5.30675 12.1933 5.15005 12 5.15005L3.34468 5.15005L5.24734 3.24739C5.38402 3.11071 5.38402 2.8891 5.24734 2.75241C5.11066 2.61573 4.88905 2.61573 4.75237 2.75241L2.25222 5.25256L2.00473 5.50005L2.25222 5.74754L4.75237 8.24769ZM12.6553 10.8501L10.7527 12.7527C10.616 12.8894 10.616 13.111 10.7527 13.2477C10.8893 13.3844 11.111 13.3844 11.2476 13.2477L13.7478 10.7475L13.9953 10.5001L13.7478 10.2526L11.2476 7.75242C11.111 7.61573 10.8893 7.61573 10.7527 7.75242C10.616 7.8891 10.616 8.11071 10.7527 8.24739L12.6553 10.1501H4C3.8067 10.1501 3.65 10.3068 3.65 10.5001C3.65 10.6934 3.8067 10.8501 4 10.8501H12.6553Z"
        fill="currentColor"
      />
    </svg>
  );
};

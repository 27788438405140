import React from 'react';

const MapMarkerBiogasIcon = ({ markerFill = '#7FC8E0', ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 39 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <mask id="path-1-inside-1" fill="white">
          <path d="M9.09375 5.78124C14.841 0.0340284 24.159 0.0340284 29.9062 5.78124C35.6535 11.5284 35.6535 20.8465 29.9063 26.5937L19.5 37L9.09376 26.5937C3.34655 20.8465 3.34654 11.5284 9.09375 5.78124Z" />
        </mask>
        <path
          d="M9.09375 5.78124C14.841 0.0340284 24.159 0.0340284 29.9062 5.78124C35.6535 11.5284 35.6535 20.8465 29.9063 26.5937L19.5 37L9.09376 26.5937C3.34655 20.8465 3.34654 11.5284 9.09375 5.78124Z"
          fill={markerFill}
        />
        <path
          d="M19.5 37L18.0858 38.4142L19.5 39.8284L20.9142 38.4142L19.5 37ZM9.09376 26.5937L7.67954 28.0079L9.09376 26.5937ZM29.9063 26.5937L31.3205 28.0079L29.9063 26.5937ZM28.492 25.1795L18.0858 35.5858L20.9142 38.4142L31.3205 28.0079L28.492 25.1795ZM20.9142 35.5858L10.508 25.1795L7.67954 28.0079L18.0858 38.4142L20.9142 35.5858ZM10.508 25.1795C5.54181 20.2134 5.54181 12.1616 10.508 7.19545L7.67954 4.36703C1.15128 10.8953 1.15129 21.4797 7.67954 28.0079L10.508 25.1795ZM28.492 7.19545C33.4582 12.1616 33.4582 20.2134 28.492 25.1795L31.3205 28.0079C37.8487 21.4797 37.8487 10.8953 31.3205 4.36703L28.492 7.19545ZM31.3205 4.36703C24.7922 -2.16123 14.2078 -2.16123 7.67954 4.36703L10.508 7.19545C15.4741 2.22929 23.5259 2.22929 28.492 7.19545L31.3205 4.36703Z"
          fill="white"
          mask="url(#path-1-inside-1)"
        />
      </g>
      <path
        d="M24.4718 17.2281C24.4718 16.7656 24.4718 16.3031 24.3562 16.0719C24.125 13.875 22.8531 12.7188 22.0437 10.8688C23.8937 16.0719 21.9281 18.7313 19.7312 19.3094C21.35 17.2281 21.1187 15.0313 18.3437 13.875C19.2687 15.3781 18.575 17.1125 17.3031 16.8813C16.0312 16.65 15.6843 14.1063 17.0718 10.8688C16.0312 12.95 14.6437 14.1063 14.5281 17.2281C14.5281 17.3438 14.5281 17.3438 14.5281 17.4594C14.5281 20.2344 16.725 22.4313 19.5 22.4313C22.275 22.4313 24.4718 20.2344 24.4718 17.4594C24.4718 17.3438 24.4718 17.3438 24.4718 17.2281Z"
        stroke="white"
        strokeLinejoin="round"
      />
      <path
        d="M16.3781 15.6094C16.7249 13.5281 17.9968 11.9094 20.0781 10.8688C19.7312 12.7188 23.5468 14.1063 22.2749 17.1125"
        stroke="white"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0.783325"
          y="1.47083"
          width="37.4333"
          height="43.5291"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default MapMarkerBiogasIcon;

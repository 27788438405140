import React from 'react';

const ZoomInIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 3.95H7.5" stroke="currentColor" strokeLinecap="square" />
      <path
        d="M4.2499 0.700001V7.2"
        stroke="currentColor"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default ZoomInIcon;

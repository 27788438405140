import React from 'react';

const PriorityMediumIcon = ({ color = '#F09D6C', ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line
        x1="0.5"
        y1="-0.5"
        x2="7.5"
        y2="-0.5"
        transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 7.5 12)"
        stroke={color}
        strokeLinecap="round"
      />
      <path
        d="M12.0001 7.99994L8 3.99988L3.99994 7.99994"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PriorityMediumIcon;

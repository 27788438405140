import React from 'react';

const CloudUploadIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M6.518 3.166a3.78 3.78 0 00-3.772 3.773c0 .055.008.107.01.16C1.765 7.496 1 8.359 1 9.485a2.69 2.69 0 002.682 2.682h2.181v-1H3.682A1.674 1.674 0 012 9.484c0-.825.587-1.502 1.363-1.65l.46-.088-.057-.465a2.81 2.81 0 01-.02-.342 2.765 2.765 0 012.772-2.773A2.77 2.77 0 019.014 5.73l.271.561.488-.387a1.31 1.31 0 01.819-.283 1.31 1.31 0 011.316 1.318c0 .092-.008.18-.025.264l-.121.6h.646c.935 0 1.684.747 1.684 1.681 0 .935-.749 1.682-1.684 1.682h-2.181v1h2.181a2.69 2.69 0 002.684-2.682c0-1.282-.982-2.216-2.19-2.476.001-.023.006-.045.006-.069a2.324 2.324 0 00-2.316-2.318c-.367 0-.667.185-.977.338-.672-1.041-1.77-1.793-3.097-1.793zm1.521 4.133L5.65 9.643l.7.714L7.5 9.23V14h1V9.152l1.15 1.13.7-.716L8.04 7.3z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloudUploadIcon;

import React from 'react';

const FileIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path
        d="M4.5 1C3.677 1 3 1.677 3 2.5v11c0 .823.678 1.5 1.5 1.5h7c.823 0 1.5-.677 1.5-1.5V5.514v-.002a.5.5 0 000-.012.5.5 0 00-.012-.107.5.5 0 00-.002-.01c-.03-.35-.176-.68-.425-.93L9.547 1.44a1.496 1.496 0 00-.95-.427A.5.5 0 008.5 1a.5.5 0 00-.008 0H4.5zm0 1H8v3.5a.5.5 0 00.5.5H12v7.5c0 .282-.218.5-.5.5h-7a.493.493 0 01-.5-.5v-11c0-.282.218-.5.5-.5zm4.5.307L11.693 5H9V2.307z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FileIcon;

import React from 'react';
import { IconComponentProps } from 'ui/icons/IconsTypes';

export const InvoiceIcon = (props: IconComponentProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 1.5C3 1.22386 3.22386 1 3.5 1H12.5C12.7761 1 13 1.22386 13 1.5V15H12.9146C12.7087 14.4174 12.1531 14 11.5 14C10.8469 14 10.2913 14.4174 10.0854 15H9.41465C9.20873 14.4174 8.65311 14 8 14C7.34689 14 6.79127 14.4174 6.58535 15H5.91465C5.70873 14.4174 5.15311 14 4.5 14C3.84689 14 3.29127 14.4174 3.08535 15H3V1.5ZM3.5 0C2.67157 0 2 0.671573 2 1.5V15.5V16H2.5H3.5H4V15.5C4 15.2239 4.22386 15 4.5 15C4.77614 15 5 15.2239 5 15.5V16H5.5H7H7.5V15.5C7.5 15.2239 7.72386 15 8 15C8.27614 15 8.5 15.2239 8.5 15.5V16H9H10.5H11V15.5C11 15.2239 11.2239 15 11.5 15C11.7761 15 12 15.2239 12 15.5V16H12.5H13.5H14V15.5V1.5C14 0.671573 13.3284 0 12.5 0H3.5ZM7.5 7C6.67157 7 6 7.67157 6 8.5C6 9.32843 6.67157 10 7.5 10V11H6.5C6.22386 11 6 11.2239 6 11.5C6 11.7761 6.22386 12 6.5 12H7.5V12.5C7.5 12.7761 7.72386 13 8 13C8.27614 13 8.5 12.7761 8.5 12.5V12C9.32843 12 10 11.3284 10 10.5C10 9.67157 9.32843 9 8.5 9V8H9.5C9.77614 8 10 7.77614 10 7.5C10 7.22386 9.77614 7 9.5 7H8.5V6.5C8.5 6.22386 8.27614 6 8 6C7.72386 6 7.5 6.22386 7.5 6.5V7ZM7 8.5C7 8.22386 7.22386 8 7.5 8V9C7.22386 9 7 8.77614 7 8.5ZM9 10.5C9 10.7761 8.77614 11 8.5 11V10C8.77614 10 9 10.2239 9 10.5ZM5.5 2C5.22386 2 5 2.22386 5 2.5C5 2.77614 5.22386 3 5.5 3H10.5C10.7761 3 11 2.77614 11 2.5C11 2.22386 10.7761 2 10.5 2H5.5ZM6.5 4C6.22386 4 6 4.22386 6 4.5C6 4.77614 6.22386 5 6.5 5H9.5C9.77614 5 10 4.77614 10 4.5C10 4.22386 9.77614 4 9.5 4H6.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

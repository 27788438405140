import React from 'react';

const PasteIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 3C10.8469 3 10.2913 3.4174 10.0854 4H9.5C8.84689 4 8.29127 4.4174 8.08535 5H6.5C5.67157 5 5 5.67157 5 6.5V18.5C5 19.3284 5.67157 20 6.5 20H13.0854C13.2913 20.5826 13.8469 21 14.5 21H18.5C19.3284 21 20 20.3284 20 19.5V13.5C20 12.6716 19.3284 12 18.5 12H18V6.5C18 5.67157 17.3284 5 16.5 5H15C14.9717 5 14.944 5.00234 14.917 5.00685C14.7131 4.42068 14.1557 4 13.5 4H12.9146C12.7087 3.4174 12.1531 3 11.5 3ZM15 6V6.5C15 6.77614 14.7761 7 14.5 7H8.5C8.22386 7 8 6.77614 8 6.5V6H6.5C6.22386 6 6 6.22386 6 6.5V18.5C6 18.7761 6.22386 19 6.5 19H13V18.6176V13.5C13 12.6716 13.6716 12 14.5 12H17V6.5C17 6.22386 16.7761 6 16.5 6H15ZM9 5.5V6H14V5.5C14 5.22386 13.7761 5 13.5 5H12.5C12.2239 5 12 4.77614 12 4.5C12 4.22386 11.7761 4 11.5 4C11.2239 4 11 4.22386 11 4.5H10.5V5H9.5C9.22386 5 9 5.22386 9 5.5ZM14 19.5V18.6176V13.5C14 13.2239 14.2239 13 14.5 13H17.5H17.6538H18H18.5C18.7761 13 19 13.2239 19 13.5V19.5C19 19.7761 18.7761 20 18.5 20H14.5C14.2239 20 14 19.7761 14 19.5ZM15.5 15V16H17.5V15H15.5ZM15.5 18V17H17.5V18H15.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PasteIcon;

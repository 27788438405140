import React from 'react';
import type { IconComponent } from 'ui/icons/IconsTypes';

export const CalculatorIcon: IconComponent = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 4H18C18.5523 4 19 4.44772 19 5V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM4 5C4 3.89543 4.89543 3 6 3H18C19.1046 3 20 3.89543 20 5V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V5ZM11 12H12H13V13V14H12H11V13V12ZM12 16H11V17V18H12H13V17V16H12ZM15 12H16H17V13V14H16H15V13V12ZM16 16H15V17V18H16H17V17V16H16ZM7 12H8H9V13V14H8H7V13V12ZM8 16H7V17V18H8H9V17V16H8ZM8 7H16V9H8V7ZM7 6H8H16H17V7V9V10H16H8H7V9V7V6Z"
        fill="currentColor"
      />
    </svg>
  );
};

import axios from 'axios';

export const downloadPngFromBase64 = (fileName, data) => {
  const link = document.createElement('a');
  link.href = `data:image/png;base64,${data}`;
  link.setAttribute('target', '_blank');
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const isAudio = mimetype => {
  const audioMimetypes = [
    'audio/flac', // flac
    'audio/mpegurl', // m3u, m3u8
    'audio/mp4', // m4a, m4b
    'audio/mpeg', // mp3
    'audio/ogg', // ogg, opus
    'audio/x-scpls', // pls
    'audio/wav', // wav
    'audio/aac', // aac
  ];
  return audioMimetypes.includes(mimetype);
};

export const isPdf = mimetype => {
  const pdfMimetypes = [
    'application/pdf', // pdf
  ];
  return pdfMimetypes.includes(mimetype);
};

export const isImage = mimetype => {
  const imageMimetypes = [
    'image/png', // png
    'image/jpeg', // png
    'image/bmp', // bmp
    'image/gif', // gif
    'image/svg+xml', // svg
    'image/webp', // webp
  ];
  return imageMimetypes.includes(mimetype);
};

const forceDownload = (blob, filename) => {
  const a = window.document.createElement('a');
  a.download = filename;
  a.href = blob;
  // For Firefox https://stackoverflow.com/a/32226068
  window.document.body.appendChild(a);
  a.click();
  a.remove();
};

export const downloadResource = async (url, filename) => {
  if (!filename)
    // eslint-disable-next-line no-param-reassign
    filename = url.split('\\').pop().split('/').pop();
  await axios
    .get(url, {
      responseType: 'blob',
    })
    .then(response => {
      const blob = response.data;
      const blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, filename);
    })
    .catch(e => console.error(e));
};

/**
 * Convert file size from bytes to human readable format (B, kB, Mb, ...)
 * @param {number} fileSize
 */
export const toHumanFileSize = fileSize => {
  const i = Math.floor(Math.log(fileSize) / Math.log(1024));
  return `${(fileSize / 1024 ** i).toFixed(2) * 1}${
    ['B', 'kB', 'Mb', 'Gb', 'Tb'][i]
  }`;
};

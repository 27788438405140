import React from 'react';

const CustomizeIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)" fill="currentColor">
        <path d="M2 5.334v1h7.334v-1zM6.666 10v1H14v-1zM11.334 5.334v1H14v-1zM2 10v1h2.666v-1zM9.334 4v3.334h1V4zM4.666 8.666V12h1V8.666z" />
      </g>
      <defs id="defs19">
        <clipPath id="clip0">
          <path id="rect16" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CustomizeIcon;

import React from 'react';

const PortfolioIcon = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 14C7 11.2386 9.23858 9 12 9V9C14.7614 9 17 11.2386 17 14V16H7V14Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="6"
        y="16"
        width="12"
        height="2"
        rx="1"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="19.5"
        y1="12.5"
        x2="21.5"
        y2="12.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="2.5"
        y1="12.5"
        x2="4.5"
        y2="12.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="16.9497"
        y1="7.34319"
        x2="18.3639"
        y2="5.92897"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="11.5"
        y1="5.5"
        x2="11.5"
        y2="3.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="6.34319"
        y1="8.05029"
        x2="4.92897"
        y2="6.63608"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PortfolioIcon;

import React from 'react';
import { IconComponentProps } from 'ui/icons/IconsTypes';

export const HelpIcon = (props: IconComponentProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.02783 9.61523V9.27148C7.02783 8.8776 7.09945 8.54997 7.24268 8.28857C7.3859 8.02718 7.63656 7.75684 7.99463 7.47754C8.42074 7.14095 8.69466 6.87956 8.81641 6.69336C8.94173 6.50716 9.00439 6.28516 9.00439 6.02734C9.00439 5.72656 8.90413 5.49561 8.70361 5.33447C8.50309 5.17334 8.21484 5.09277 7.83887 5.09277C7.4987 5.09277 7.18359 5.14111 6.89355 5.23779C6.60352 5.33447 6.32064 5.45085 6.04492 5.58691L5.59375 4.6416C6.32064 4.23698 7.09945 4.03467 7.93018 4.03467C8.632 4.03467 9.1888 4.20654 9.60059 4.55029C10.0124 4.89404 10.2183 5.36849 10.2183 5.97363C10.2183 6.24219 10.1789 6.4821 10.1001 6.69336C10.0213 6.90104 9.90137 7.09977 9.74023 7.28955C9.58268 7.47933 9.30876 7.7264 8.91846 8.03076C8.58545 8.29215 8.36165 8.50879 8.24707 8.68066C8.13607 8.85254 8.08057 9.0835 8.08057 9.37354V9.61523H7.02783ZM6.80762 11.3286C6.80762 10.7879 7.0708 10.5176 7.59717 10.5176C7.85498 10.5176 8.05192 10.5892 8.18799 10.7324C8.32406 10.8721 8.39209 11.0708 8.39209 11.3286C8.39209 11.5828 8.32227 11.7852 8.18262 11.9355C8.04655 12.0824 7.8514 12.1558 7.59717 12.1558C7.34294 12.1558 7.14779 12.0841 7.01172 11.9409C6.87565 11.7941 6.80762 11.59 6.80762 11.3286Z"
        fill="currentColor"
      />
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="7.5"
        stroke="currentColor"
      />
    </svg>
  );
};

import React from 'react';

const TaskIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 9.78104C12.5 10.8393 12.5 12.24 12.5 12.24C12.5 12.9355 11.9843 13.5 11.34 13.5H4.66667C4.02233 13.5 3.5 12.9355 3.5 12.24L3.50001 3.76C3.50001 3.06453 4.02234 2.5 4.66667 2.5H5.16667"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 2.5H11.5C12.0523 2.5 12.5 3.04269 12.5 3.71212C12.5 3.71212 12.5 4.12626 12.5 4.83333"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="5.5"
        y="1.5"
        width="5"
        height="2"
        rx="1"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 7L10.6333 9L15.1667 5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TaskIcon;

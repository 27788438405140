import React from 'react';

const RegulatoryIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.50002 1.99948L3.00002 1.99948V2.49948L3.00002 5.06445L1.28251 6.78197L0.928955 7.13552L1.28251 7.48907L3.00002 9.20659V11.4995V11.9995H3.50002H5.00003V15.9995V16.738L5.68573 16.4637L8.00003 15.538L10.3143 16.4637L11 16.738V15.9995V11.9995H12.5H13V11.4995L13 9.20659L14.7175 7.48907L15.0711 7.13552L14.7175 6.78197L13.2422 5.30665L12.5351 6.01376L13.6569 7.13552L12.1465 8.64593L12 8.79238L12 8.99948V10.9995H10H9.79292L9.64647 11.1459L8.00002 12.7924L6.35358 11.1459L6.20713 10.9995H6.00002H4.00002L4.00002 8.99948V8.79237L3.85358 8.64593L2.34317 7.13552L3.85358 5.62511L4.00002 5.47867L4.00002 5.27156V2.99948H6.2721H6.47921L6.62565 2.85304L8.00002 1.47867L9.37439 2.85304L9.52084 2.99948L9.72794 2.99948L11.4481 2.99948L12.4481 1.99948H9.93505L8.35358 0.418006L8.00002 0.0644531L7.64647 0.418006L6.06499 1.99948L3.50002 1.99948ZM6.00003 15.261V12.2066L7.64647 13.853L8.00002 14.2066L8.35358 13.853L10 12.2066V15.261L8.18573 14.5353L8.00003 14.461L7.81433 14.5353L6.00003 15.261ZM8.35358 8.85209L13.3536 3.85209L12.6465 3.14498L8.00003 7.79143L6.35358 6.14498L5.64648 6.85209L7.64648 8.85209L8.00003 9.20564L8.35358 8.85209Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RegulatoryIcon;

import styled from '@emotion/styled';
import { grey700 } from 'ui/styleguide/colors';
import { LoaderWrapperProps } from './FullPageLoaderTypes';

export const StyledLoaderWrapper = styled.div<LoaderWrapperProps>`
  position: ${({ wrapperOnly }) => (wrapperOnly ? 'absolute' : 'fixed')};
  top: 0;
  left: ${({ wrapperOnly }) => (wrapperOnly ? '-15px' : '0')};
  width: ${({ wrapperOnly }) => (wrapperOnly ? 'calc(100% + 30px)' : '100%')};
  height: ${({ wrapperOnly }) =>
    wrapperOnly ? 'calc(100vh - 46px)' : '100vh'};
  display: flex;
  z-index: 2020;
  background-color: ${({ hasOverlay }) =>
    hasOverlay ? 'rgba(217, 221, 224, 0.6)' : ''};
`;

export const StyledLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
  > span {
    display: block;
    margin-top: 16px;
    color: ${grey700};
  }
`;

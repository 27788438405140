import React from 'react';
import { IconComponentProps } from 'ui/icons/IconsTypes';

export const FullscreenIcon = (props: IconComponentProps) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9.5C18 9.77614 18.2239 10 18.5 10C18.7761 10 19 9.77614 19 9.5L19 5.5V5H18.5H14.5C14.2239 5 14 5.22386 14 5.5C14 5.77614 14.2239 6 14.5 6L17.4993 6C17.3716 6.00017 17.2439 6.04898 17.1464 6.14645L14.1464 9.14645C13.9512 9.34171 13.9512 9.65829 14.1464 9.85355C14.3417 10.0488 14.6583 10.0488 14.8536 9.85355L17.8536 6.85355C18.0488 6.65829 18.0488 6.34171 17.8536 6.14645C17.7561 6.04898 17.6284 6.00017 17.5007 6H18L18 9.5Z"
        fill="currentColor"
      />
      <path
        d="M6.5 6.5L9.5 9.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 5.5L5.5 5.5L5.5 9.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M6.5 17.5L9.5 14.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 14.5L5.5 18.5L9.5 18.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M17.5 17.5L14.5 14.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 18.5L18.5 18.5L18.5 14.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};

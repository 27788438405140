import React from 'react';

const FolderEmptyIcon = ({ ...props }) => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="path-1-inside-1" fill="white">
        <path d="M7.12173 59.0958V11.2753C7.12173 10.1419 8.0263 9.22433 9.1437 9.22433H40.0053C40.697 9.22433 41.3887 9.46721 41.9474 9.92599L52.6159 18.6697H86.8563C87.9737 18.6697 88.8783 19.5872 88.8783 20.7207V59.1227H7.12173V59.0958ZM18.6797 51.8363H77.2937V29.8274H18.6797V51.8363Z" />
      </mask>
      <path
        d="M7.12173 59.0958V11.2753C7.12173 10.1419 8.0263 9.22433 9.1437 9.22433H40.0053C40.697 9.22433 41.3887 9.46721 41.9474 9.92599L52.6159 18.6697H86.8563C87.9737 18.6697 88.8783 19.5872 88.8783 20.7207V59.1227H7.12173V59.0958ZM18.6797 51.8363H77.2937V29.8274H18.6797V51.8363Z"
        fill="#F7F9FB"
        stroke="#D5DDE6"
        strokeWidth="2"
        mask="url(#path-1-inside-1)"
      />
      <mask id="path-2-inside-2" fill="white">
        <path d="M7.84005 87C6.82907 87 5.97771 86.2444 5.84469 85.2189L0.0182371 39.4224C-0.0615773 38.8287 0.124656 38.262 0.497124 37.8032C0.869591 37.3715 1.42829 37.1016 2.0136 37.1016H93.9864C94.5717 37.1016 95.1038 37.3445 95.5029 37.8032C95.8753 38.235 96.0616 38.8287 95.9818 39.4224L90.1553 85.2189C90.0223 86.2444 89.1709 87 88.16 87H7.84005Z" />
      </mask>
      <path
        d="M7.84005 87C6.82907 87 5.97771 86.2444 5.84469 85.2189L0.0182371 39.4224C-0.0615773 38.8287 0.124656 38.262 0.497124 37.8032C0.869591 37.3715 1.42829 37.1016 2.0136 37.1016H93.9864C94.5717 37.1016 95.1038 37.3445 95.5029 37.8032C95.8753 38.235 96.0616 38.8287 95.9818 39.4224L90.1553 85.2189C90.0223 86.2444 89.1709 87 88.16 87H7.84005Z"
        fill="#F7F9FB"
        stroke="#D5DDE6"
        strokeWidth="2"
        mask="url(#path-2-inside-2)"
      />
    </svg>
  );
};

export default FolderEmptyIcon;

import Cookies from 'js-cookie';
import { keyDecoder } from './legacy-http';
import { request, urlFactory } from './utils';

export const url = urlFactory('api/users');

export const getRedirectTo = () => {
  const url = new URL(window.location.href);
  const redirectTo = url.searchParams.get('next');
  return redirectTo;
};

const loginHeaders = {
  'Login-CSRFToken': Cookies.get('login_csrftoken'),
};

const users = {
  login: (email, password) =>
    request(
      {
        url: url('/login'),
        data: { email, password, redirect_to: getRedirectTo() },
        method: 'POST',
        headers: loginHeaders,
      },
      ({ redirect_url }) => ({
        redirect_url: redirect_url,
      }),
      ({ detail, email, password }) => ({
        email: email && `${email.join(', ')}.`,
        password: password && `${password.join(', ')}.`,
        error: detail,
      }),
    ),
};

export default users;

import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LoginWithSsoHandler from '~/asset-management/auth/AppLogin/LoginWithSSOHandler';
import LogoutWithSsoHandler from '~/asset-management/auth/AppLogin/LogoutWithSSOHandler';
import { createRootById } from '~/common/utils/react';
import AppLogin from './AppLogin';

const router = createBrowserRouter([
  { path: '/user/login', element: <AppLogin /> },
  { path: '/admin/login', element: <AppLogin /> },
  { path: '/user/login-auth0-complete', element: <LoginWithSsoHandler /> },
  { path: '/user/logout-auth0', element: <LogoutWithSsoHandler /> },
]);

const LoginEntry = () => <RouterProvider router={router} />;

const root = createRootById('react-app');
root.render(<LoginEntry />);

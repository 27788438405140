import React from 'react';

const MapMarkerGeothermalIcon = ({ markerFill = '#C1180B', ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M13 9C17.9706 4.02944 26.0294 4.02944 31 9V9C35.9706 13.9706 35.9706 22.0294 31 27L22 36L13 27C8.02945 22.0294 8.02944 13.9706 13 9V9Z"
          fill={markerFill}
        />
        <path
          d="M30.2929 26.2929L22 34.5858L13.7071 26.2929C9.12708 21.7128 9.12708 14.2871 13.7071 9.7071C18.2871 5.12707 25.7129 5.12707 30.2929 9.7071C34.8729 14.2871 34.8729 21.7128 30.2929 26.2929Z"
          stroke="white"
          strokeWidth="2"
        />
      </g>
      <path
        d="M17.0498 21.2C18.5498 19.7 18.9498 21.2 20.1498 21"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.0497 21.2C25.5497 19.7 25.1497 21.2 23.9497 21"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0498 22.6C18.7498 22.6 18.7498 23.1 19.3498 23.1C20.0498 23.1 20.0498 22.6 20.6498 22.6C21.3498 22.6 21.3498 23.1 21.9498 23.1C22.6498 23.1 22.6498 22.6 23.2498 22.6C23.9498 22.6 23.9498 23.1 24.5498 23.1C25.2498 23.1 25.2498 22.6 25.8498 22.6"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5497 19.6V13.7C22.5497 13.4 22.3497 13.2 22.0497 13.2C21.6497 13.2 21.4497 13.4 21.4497 13.7V19.6C21.1497 19.8 20.9497 20.2 20.9497 20.5C20.9497 21.1 21.4497 21.5 21.9497 21.5C22.5497 21.5 22.9497 21 22.9497 20.5C23.0497 20.2 22.8497 19.8 22.5497 19.6Z"
        stroke="white"
        strokeLinejoin="round"
      />
      <path
        d="M23.9498 15.3999H22.5498"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9498 16.7999H22.5498"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9498 18.1H22.5498"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="44"
          height="44"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.419608 0 0 0 0 0.454902 0 0 0 0 0.490196 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default MapMarkerGeothermalIcon;

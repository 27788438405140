import React from 'react';

const EditIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 12.5H13"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8849 3.36292C11.1979 3.04992 11.6541 2.92768 12.0817 3.04225C12.5092 3.15681 12.8432 3.49078 12.9578 3.91834C13.0723 4.34591 12.9501 4.80212 12.6371 5.11512L5.33626 12.4159L3 13L3.58407 10.6637L10.8849 3.36292Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;

import React from 'react';

export const UndoIcon = props => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7632 10.3637C12.0357 10.4087 12.293 10.2242 12.338 9.95178C12.3829 9.67932 12.1985 9.42201 11.926 9.37705L7.32013 8.61706C8.52872 7.39591 10.2049 6.64034 12.0578 6.64034C15.7391 6.64034 18.7234 9.62467 18.7234 13.306C18.7234 16.9874 15.7391 19.9717 12.0578 19.9717C8.37641 19.9717 5.39209 16.9874 5.39209 13.306C5.39209 13.0299 5.16823 12.806 4.89209 12.806C4.61595 12.806 4.39209 13.0299 4.39209 13.306C4.39209 17.5396 7.82413 20.9717 12.0578 20.9717C16.2914 20.9717 19.7234 17.5396 19.7234 13.306C19.7234 9.07238 16.2914 5.64034 12.0578 5.64034C10.1805 5.64034 8.46071 6.31549 7.12831 7.43533L7.78772 3.57C7.83415 3.29779 7.65113 3.03948 7.37892 2.99304C7.10671 2.9466 6.84839 3.12963 6.80196 3.40184L5.86719 8.88132C5.84481 9.01251 5.87565 9.1472 5.95288 9.25558C6.03011 9.36395 6.14736 9.43707 6.27867 9.45873L11.7632 10.3637Z"
        fill="currentColor"
      />
    </svg>
  );
};

import React from 'react';
import type { SelectProps } from 'antd';
import { Spin } from 'antd';
import AntdSelect from 'antd/lib/select';
import styled from '@emotion/styled';
import { blue500 } from 'ui/styleguide/colors';
import { css, cx } from '@emotion/css';

type Value = string | number | null;

interface BaseOptionType {
  disabled?: boolean;

  [name: string]: any;
}

export type Option<T = Value> = BaseOptionType & {
  label: string | React.ReactNode;
  value?: T;
  options?: Option<T>[];
};

export { SelectProps };

type SelectArrowProps = {
  isOpen?: boolean;
  size?: 'small' | 'middle' | 'large';
};

const SelectClassName = css`
  &.ant-select-single .ant-select-selector {
    padding: 0 16px 0 11px;
  }
  .ant-select-arrow {
    top: 48%;
    vertical-align: middle;
    right: 8px;
  }
`;

export const SelectArrow = styled.div<SelectArrowProps>`
  position: relative;
  ${({ size }) =>
    size === 'small'
      ? `
    width: 8px;
    height: 8px;
    margin: 1px;
  `
      : `
    width: 11px;
    height: 11px;
    margin: 2.5px;
  `}
  display: inline-block;
  vertical-align: middle;

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    ${({ size }) =>
      size === 'small'
        ? `
        height: 1px;
        width: 5.2px;
      `
        : `
      height: 1px;
      width: 7px;
    `}
    top: 50%;
    background-color: ${blue500};
    transition: transform 0.2s;
  }

  &::before {
    left: 0;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg)' : 'rotate(45deg)')};
  }

  &::after {
    right: 0;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(-45deg)')};
  }

  .ant-select-open & {
    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }
`;

export const StyledSelectWithButtonWrapper = styled.div`
  display: flex;

  .ant-select .ant-select-selector {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  button.ant-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

function Select<T = Value, O extends BaseOptionType | Option<T> = Option<T>>({
  loading,
  getPopupContainer,
  className,
  showSearch = true,
  ...props
}: SelectProps<T, O>) {
  return (
    <AntdSelect
      className={cx(SelectClassName, className)}
      suffixIcon={
        loading ? <Spin size="small" /> : <SelectArrow size={props.size} />
      }
      showSearch={showSearch}
      {...props}
      loading={loading}
      getPopupContainer={getPopupContainer || (el => el.parentElement)}
    />
  );
}

Select.Option = AntdSelect.Option;
Select.OptGroup = AntdSelect.OptGroup;

export default Select;

import React from 'react';
import { IconComponentProps } from 'ui/icons/IconsTypes';

export const HistoryIcon = (props: IconComponentProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.79828 12.7547C8.91755 12.9591 10.073 12.7621 11.0614 12.1984C12.0497 11.6346 12.8074 10.7403 13.2012 9.67287C13.595 8.60542 13.5996 7.43325 13.2142 6.36274C12.8288 5.29222 12.0781 4.39199 11.0943 3.82052C10.1104 3.24906 8.95649 3.04299 7.83565 3.23861C6.71482 3.43423 5.69892 4.01898 4.96681 4.88993C4.42946 5.52918 4.07028 6.29253 3.9172 7.10331L4.74004 6.36241C4.94526 6.17764 5.26141 6.19421 5.44618 6.39942C5.63096 6.60463 5.61439 6.92078 5.40918 7.10556L3.67517 8.66686C3.46996 8.85164 3.15381 8.83507 2.96903 8.62986L1.40773 6.89585C1.22295 6.69064 1.23952 6.37449 1.44474 6.18971C1.64995 6.00494 1.9661 6.02151 2.15087 6.22672L2.90825 7.06787C3.07567 6.03358 3.51974 5.05732 4.20132 4.24648C5.08491 3.19533 6.31099 2.48959 7.66372 2.2535C9.01646 2.01741 10.4091 2.26611 11.5965 2.95581C12.7839 3.64551 13.69 4.732 14.1551 6.024C14.6203 7.316 14.6147 8.73068 14.1394 10.019C13.6641 11.3073 12.7496 12.3866 11.5568 13.067C10.364 13.7473 8.96946 13.9851 7.61862 13.7384C6.26778 13.4917 5.04728 12.7763 4.17198 11.7183C3.99596 11.5055 4.02576 11.1903 4.23853 11.0143C4.4513 10.8383 4.76647 10.8681 4.94249 11.0809C5.66774 11.9575 6.67902 12.5502 7.79828 12.7547ZM9.16665 4.99998C9.16665 4.72384 8.94279 4.49998 8.66665 4.49998C8.3905 4.49998 8.16665 4.72384 8.16665 4.99998V7.99998V8.32968L8.46969 8.45955L10.803 9.45955C11.0568 9.56833 11.3508 9.45076 11.4596 9.19694C11.5683 8.94313 11.4508 8.64919 11.1969 8.54041L9.16665 7.67028V4.99998Z"
        fill="currentColor"
      />
    </svg>
  );
};

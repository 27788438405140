import dayjs from 'dayjs';
import { keyDecoder, nullDecoder } from './legacy-http';
import { formRequest, urlFactory } from './utils';

export const url = urlFactory('reporting');

export const templates = {
  clone: ({ templateId, title }) =>
    formRequest(
      {
        url: url(`/templates/clone/${templateId}`),
        method: 'POST',
        data: { title },
      },
      keyDecoder('id'),
      res => {
        return res.error;
      },
    ),
};

export const reports = {
  create: ({ plantIds, startDate, endDate, templateId, reportName }) =>
    formRequest(
      {
        url: url(`/reports/new/`),
        method: 'POST',
        data: {
          plant_ids: JSON.stringify(plantIds),
          start_date: dayjs(startDate).format('YYYY-MM-DD'),
          end_date: dayjs(endDate).format('YYYY-MM-DD'),
          template_id: templateId,
          report_name: reportName,
        },
      },
      nullDecoder,
    ),
};

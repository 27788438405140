import React from 'react';

const ContractIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2.5C2 1.67157 2.67157 1 3.5 1H12.5C13.3284 1 14 1.67157 14 2.5V4.00019C14.1242 3.99805 14.2497 4.01304 14.373 4.04608C14.8395 4.17108 15.2039 4.53545 15.3289 5.00195C15.4539 5.46845 15.3205 5.9662 14.979 6.3077L14 7.28674V13.5C14 14.3284 13.3284 15 12.5 15H3.5C2.67157 15 2 14.3284 2 13.5V2.5ZM13 8.28674L9.95973 11.327C9.89565 11.3911 9.81536 11.4365 9.72745 11.4585L8.12127 11.8601C7.95088 11.9027 7.77064 11.8527 7.64645 11.7286C7.52226 11.6044 7.47233 11.4241 7.51493 11.2537L7.91647 9.64755C7.93845 9.55964 7.98391 9.47935 8.04799 9.41527L10.4633 7H5C4.72386 7 4.5 6.77614 4.5 6.5C4.5 6.22386 4.72386 6 5 6H11C11.1287 6 11.2461 6.04864 11.3347 6.12855L13 4.46326V2.5C13 2.22386 12.7761 2 12.5 2H3.5C3.22386 2 3 2.22386 3 2.5V13.5C3 13.7761 3.22386 14 3.5 14H12.5C12.7761 14 13 13.7761 13 13.5V8.28674ZM4.5 4.5C4.5 4.22386 4.72386 4 5 4H11C11.2761 4 11.5 4.22386 11.5 4.5C11.5 4.77614 11.2761 5 11 5H5C4.72386 5 4.5 4.77614 4.5 4.5ZM4.5 11.5C4.5 11.2239 4.72386 11 5 11H7C7.27614 11 7.5 11.2239 7.5 11.5C7.5 11.7761 7.27614 12 7 12H5C4.72386 12 4.5 11.7761 4.5 11.5ZM13.7744 5.10306C13.8633 5.01419 13.9928 4.97948 14.1142 5.01201C14.2356 5.04454 14.3305 5.13937 14.363 5.26077C14.3955 5.38218 14.3608 5.51171 14.2719 5.60059L9.35056 10.522L8.68718 10.6878L8.85303 10.0244L13.7744 5.10306Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ContractIcon;

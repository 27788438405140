import React from 'react';

const DeleteIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 4.64445H4.12222H13.1"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4776 4.64447C12.4776 4.36833 12.2538 4.14447 11.9776 4.14447C11.7015 4.14447 11.4776 4.36833 11.4776 4.64447H12.4776ZM4.62207 4.64447C4.62207 4.36833 4.39821 4.14447 4.12207 4.14447C3.84593 4.14447 3.62207 4.36833 3.62207 4.64447H4.62207ZM5.3054 4.64447C5.3054 4.92061 5.52926 5.14447 5.8054 5.14447C6.08155 5.14447 6.3054 4.92061 6.3054 4.64447H5.3054ZM9.79429 4.64447C9.79429 4.92061 10.0181 5.14447 10.2943 5.14447C10.5704 5.14447 10.7943 4.92061 10.7943 4.64447H9.79429ZM11.4776 4.64447V12.5H12.4776V4.64447H11.4776ZM11.4776 12.5C11.4776 12.8437 11.199 13.1222 10.8554 13.1222V14.1222C11.7513 14.1222 12.4776 13.396 12.4776 12.5H11.4776ZM10.8554 13.1222H5.24429V14.1222H10.8554V13.1222ZM5.24429 13.1222C4.90065 13.1222 4.62207 12.8437 4.62207 12.5H3.62207C3.62207 13.396 4.34836 14.1222 5.24429 14.1222V13.1222ZM4.62207 12.5V4.64447H3.62207V12.5H4.62207ZM6.3054 4.64447V3.52225H5.3054V4.64447H6.3054ZM6.3054 3.52225C6.3054 3.1786 6.58398 2.90002 6.92763 2.90002V1.90002C6.0317 1.90002 5.3054 2.62632 5.3054 3.52225H6.3054ZM6.92763 2.90002H9.17207V1.90002H6.92763V2.90002ZM9.17207 2.90002C9.51571 2.90002 9.79429 3.1786 9.79429 3.52225H10.7943C10.7943 2.62632 10.068 1.90002 9.17207 1.90002V2.90002ZM9.79429 3.52225V4.64447H10.7943V3.52225H9.79429Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DeleteIcon;

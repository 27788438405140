import React from 'react';
import PropTypes from 'prop-types';
import { cx } from '@emotion/css';

const CaretIcon = ({ direction, style = {}, ...props }) => {
  let transform = null;
  if (direction === 'down') {
    transform = 'rotate(90deg)';
  } else if (direction === 'up') {
    transform = 'rotate(-90deg)';
  } else if (direction === 'left') {
    transform = 'rotate(180deg)';
  }

  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cx(props.className, 'caret-icon')}
      style={{ transform, ...style }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.01478 11.5148L10.5607 7.98963L7.01637 4.48363"
        fill="currentColor"
      />
    </svg>
  );
};
CaretIcon.propTypes = {
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  style: PropTypes.object,
  className: PropTypes.string,
};

CaretIcon.defaultProps = {
  direction: 'right',
  style: {},
  className: '',
};

export default CaretIcon;

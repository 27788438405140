import PropTypes from 'prop-types';
import React from 'react';

const ArrowIcon = ({ direction, style = {}, ...props }) => {
  let transform = null;
  if (direction === 'down') {
    transform = 'rotate(180deg)';
  } else if (direction === 'left') {
    transform = 'rotate(-90deg)';
  } else if (direction === 'right') {
    transform = 'rotate(90deg)';
  }

  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ transform, ...style }}
    >
      <path
        d="M12.0503 5.94971L12.0503 17.9497"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M4.97923 11.9498L12.0503 4.87874L19.1214 11.9498"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};

ArrowIcon.propTypes = {
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  style: PropTypes.object,
};

ArrowIcon.defaultProps = {
  direction: 'up',
  style: {},
};

export default ArrowIcon;

import React from 'react';

const ProjectsEmptyIcon = ({ ...props }) => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.5 69.5a4 4 0 0 1 4-4h43v12h-43a4 4 0 0 1-4-4v-4z"
        stroke="#D5DDE6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.5 65.5V65H69v.5h.5zm0 12H69v.5h.5v-.5zm0-11.5h13v-1h-13v1zM86 69.5v4h1v-4h-1zM82.5 77h-13v1h13v-1zM69 65.5v12h1v-12h-1zm17 8a3.5 3.5 0 0 1-3.5 3.5v1a4.5 4.5 0 0 0 4.5-4.5h-1zM82.5 66a3.5 3.5 0 0 1 3.5 3.5h1a4.5 4.5 0 0 0-4.5-4.5v1z"
        fill="#D5DDE6"
      />
      <path
        d="M9.5 45.5a4 4 0 0 1 4-4h69a4 4 0 0 1 4 4v4a4 4 0 0 1-4 4h-69a4 4 0 0 1-4-4v-4z"
        stroke="#D5DDE6"
      />
      <path
        d="M10.5 44.5a2 2 0 0 1 2-2h71a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-71a2 2 0 0 1-2-2v-6zM10.5 68.5a2 2 0 0 1 2-2h43v10h-43a2 2 0 0 1-2-2v-6zM85.5 74.5a2 2 0 0 1-2 2h-13v-10h13a2 2 0 0 1 2 2v6zM10.5 20.5a2 2 0 0 1 2-2h19v10h-19a2 2 0 0 1-2-2v-6zM85.5 26.5a2 2 0 0 1-2 2h-37v-10h37a2 2 0 0 1 2 2v6z"
        fill="#F7F9FB"
      />
      <path
        d="M9.5 21.5a4 4 0 0 1 4-4h19v12h-19a4 4 0 0 1-4-4v-4z"
        stroke="#D5DDE6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.5 17.5V17H45v.5h.5zm0 12H45v.5h.5v-.5zm37-12v.5-.5zm-37 .5h37v-1h-37v1zM86 21.5v4h1v-4h-1zM82.5 29h-37v1h37v-1zM45 17.5v12h1v-12h-1zm41 8a3.5 3.5 0 0 1-3.5 3.5v1a4.5 4.5 0 0 0 4.5-4.5h-1zM82.5 18a3.5 3.5 0 0 1 3.5 3.5h1a4.5 4.5 0 0 0-4.5-4.5v1z"
        fill="#D5DDE6"
      />
    </svg>
  );
};

export default ProjectsEmptyIcon;

import { identityDecoder } from './legacy-http';
import { request, urlFactory } from './utils';

export const url = urlFactory('tasks/api');

const tasks = {
  toggleTaskStatus: taskID =>
    request(
      {
        url: url(`/${taskID}/toggle`),
        method: 'POST',
      },
      identityDecoder,
    ),
};

export default tasks;

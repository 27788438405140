import React from 'react';

const ImportIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4.5C4 3.67157 4.67157 3 5.5 3H11C11.2761 3 11.5 2.77614 11.5 2.5C11.5 2.22386 11.2761 2 11 2H5.5C4.11929 2 3 3.11929 3 4.5V19.5C3 20.8807 4.11929 22 5.5 22H18.5C19.8807 22 21 20.8807 21 19.5V13C21 12.9788 20.9987 12.9579 20.9961 12.9374V12.1419C20.9961 9.06261 18.4998 6.56632 15.4205 6.56632L15 6.56632V3.49988C15 3.30279 14.8842 3.12408 14.7043 3.04354C14.5244 2.963 14.314 2.99564 14.167 3.12691L8.167 8.48381C8.06243 8.57717 8.00186 8.71014 8.00004 8.85031C7.99823 8.99048 8.05533 9.12497 8.15745 9.22101L14.1574 14.8641C14.3027 15.0007 14.5153 15.038 14.6984 14.9588C14.8815 14.8797 15 14.6993 15 14.4999V11.2066H15.4205C16.8858 11.2066 18.1902 11.8948 19.0284 12.9677C19.5958 13.6939 19.6298 13.7532 19.9204 14.2597L19.9359 14.2867C19.956 14.3218 19.9773 14.3589 20 14.3984V19.5C20 20.3284 19.3284 21 18.5 21H5.5C4.67157 21 4 20.3284 4 19.5V4.5ZM14 13.3432L9.24001 8.86638L14 4.61658V7.06631C14 7.34245 14.2239 7.56631 14.5 7.56631L15.4205 7.56632C17.9475 7.56632 19.9961 9.6149 19.9961 12.1419V12.5829C19.9421 12.5132 19.8826 12.4367 19.8164 12.352C18.7968 11.047 17.2067 10.2066 15.4205 10.2066H14.5C14.3674 10.2066 14.2402 10.2592 14.1464 10.353C14.0527 10.4468 14 10.574 14 10.7066V13.3432Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ImportIcon;

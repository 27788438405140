import React from 'react';

const CopyIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 2.5A2.508 2.508 0 002.5 5v4c0 1.375 1.125 2.5 2.5 2.5h.5v.5c0 1.376 1.124 2.5 2.5 2.5h4c1.376 0 2.5-1.124 2.5-2.5V8c0-1.376-1.124-2.5-2.5-2.5h-.5V5c0-1.375-1.125-2.5-2.5-2.5H5zm0 1h4c.834 0 1.5.666 1.5 1.5v.5H8A2.506 2.506 0 005.5 8v2.5H5c-.834 0-1.5-.666-1.5-1.5V5c0-.834.666-1.5 1.5-1.5zm3 3h4c.84 0 1.5.66 1.5 1.5v4c0 .84-.66 1.5-1.5 1.5H8c-.84 0-1.5-.66-1.5-1.5V8c0-.84.66-1.5 1.5-1.5z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CopyIcon;

import React from 'react';

const ClaimIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.75 1.75a1.5 1.5 0 0 0-1.5 1.5v16a1.5 1.5 0 0 0 1.5 1.5H9a.5.5 0 0 0 0-1H4.75a.5.5 0 0 1-.5-.5v-16a.5.5 0 0 1 .5-.5h11.5a.5.5 0 0 1 .5.5v.95a.5.5 0 0 0 1 0v-.95a1.5 1.5 0 0 0-1.5-1.5H4.75zm12.5 4.438-.229.117-6 3.084-.271.14v7.463c0 1.89 1.034 3.466 2.31 4.606 1.276 1.138 2.856 1.897 4.092 2.142l.098.02.098-.02c1.237-.245 2.818-1.006 4.095-2.176 1.282-1.173 2.307-2.805 2.307-4.798V9.529l-.271-.14-6-3.084-.229-.117zm-10.5.062a.5.5 0 0 0 0 1H12a.5.5 0 0 0 0-1H6.75zm10.5 1.063 5.5 2.826v6.627c0 1.627-.835 3.01-1.982 4.06-1.117 1.022-2.483 1.684-3.518 1.912-1.036-.228-2.405-.889-3.523-1.886-1.153-1.029-1.977-2.355-1.977-3.86V10.14l5.5-2.826zm0 3.437a.5.5 0 0 0-.5.5V12c0 .091.024.177.066.25h-.066a1.5 1.5 0 0 0-1.5 1.5V14a1.5 1.5 0 0 0 1.5 1.5h1a.5.5 0 0 1 .5.5v.25a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h1.066a.497.497 0 0 0-.066.25v.75a.5.5 0 0 0 1 0V18a.497.497 0 0 0-.066-.25h.066a1.5 1.5 0 0 0 1.5-1.5V16a1.5 1.5 0 0 0-1.5-1.5h-1a.5.5 0 0 1-.5-.5v-.25a.5.5 0 0 1 .5-.5h2a.5.5 0 0 0 0-1h-1.066a.497.497 0 0 0 .066-.25v-.75a.5.5 0 0 0-.5-.5zm-10.5 1.5a.5.5 0 0 0 0 1H9a.5.5 0 0 0 0-1H6.75zm0 3a.5.5 0 0 0 0 1H9a.5.5 0 0 0 0-1H6.75z"
        clipRule="evenodd"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default ClaimIcon;

import Globals from '~/common/globals';
import { getQueryClient } from '~/common/api/QueryClient';
import { http } from '../api/http';

const FRONTEND_ENV_VARS_ENDPOINT = '/common/api/frontend-env/';

export const loadEnvironmentVars = async (forceData = false) => {
  try {
    if (
      typeof window?.process === 'undefined' ||
      typeof window?.process?.env === 'undefined'
    ) {
      window.process = { ...(window.process || {}), env: {} };
    }
    const { data } = await getQueryClient().fetchQuery(
      ['env-vars'],
      () =>
        http.get(FRONTEND_ENV_VARS_ENDPOINT, {
          params: { forceData },
        }),
      {
        cacheTime: 5 * 60 * 1000, // 5 minutes
        staleTime: 5 * 60 * 1000, // 5 minutes
      },
    );

    Object.keys(data).forEach(key => {
      process.env[key] = data[key];
    });
    Globals.updateApp({ env: process.env });
  } catch (e) {
    console.error('Failed to load environment variables', e);
  }
};

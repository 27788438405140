import React from 'react';

const ComplianceIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.0556 2.5H2.94444C2.1467 2.5 1.5 3.08203 1.5 3.80001V12.9001C1.5 13.618 2.1467 14.2001 2.94444 14.2001H13.0556C13.8533 14.2001 14.5 13.618 14.5 12.9001V3.80001C14.5 3.08203 13.8533 2.5 13.0556 2.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 9.17647L8.41176 11.0882L13 6.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M10.5 1.5V4.10002"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 1.5V4.10002"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 6.5H9.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ComplianceIcon;

import React from 'react';

export const NetSuiteIcon = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4a.5.5 0 0 1 .5-.5H5a.5.5 0 0 1 .4.2l5.85 7.8H12V4a.5.5 0 0 1 1 0v8a.5.5 0 0 1-.5.5H11a.5.5 0 0 1-.4-.2L4.75 4.5H4V12a.5.5 0 0 1-1 0V4z"
        fill="currentColor"
      />
    </svg>
  );
};

import create from 'zustand';
import { useQuery } from '@tanstack/react-query';
import UsersApi from '@bluepoint/asset-management/apps/Users/api/UsersApi';
import { useEffect } from 'react';
import Globals from '~/common/globals';

type UserInfo = {
  id: number;
  avatar: string;
  first_name: string;
  last_name: string;
  initials: string;
  email: string;
  role: string;
  timezone: string;
  temperatureUnit: string;
};

type ClientInfo = {
  id: number;
  name: string;
  logo: string;
};

type UserInfoStoreValue = {
  currentUser: UserInfo | null;
  setCurrentUser: (user: UserInfo | null) => void;
  currentClient: ClientInfo | null;
  setCurrentClient: (client: ClientInfo | null) => void;
};

export const useUserInfoStore = create<UserInfoStoreValue>(set => ({
  currentUser: null,
  setCurrentUser: currentUser => set({ currentUser }),
  currentClient: null,
  setCurrentClient: currentClient => set({ currentClient }),
}));

export const useInitUserInfoStore = () => {
  const { setCurrentUser, setCurrentClient } = useUserInfoStore();
  const { data: currentClientInfo } = useQuery<ClientInfo>(
    ['clientLogo'],
    UsersApi.currentClient,
    {
      refetchInterval: false,
    },
  );

  const { data: currentUserInfo } = useQuery<UserInfo>(
    ['currentUser'],
    UsersApi.currentUser,
    {
      refetchInterval: false,
    },
  );

  useEffect(() => {
    setCurrentClient(currentClientInfo ?? null);
  }, [currentClientInfo]);

  useEffect(() => {
    setCurrentUser(currentUserInfo ?? null);
  }, [currentUserInfo]);
};

export function getCurrentUser() {
  if (typeof Globals.getAppSettings().user !== 'undefined') {
    return Globals.getAppSettings().user;
  }
  const { currentUser } = useUserInfoStore.getState();
  if (currentUser) {
    return currentUser;
  }
  return null;
}

export function getCurrentClientId() {
  if (typeof Globals.getAppSettings().user !== 'undefined') {
    return Globals.getAppSettings().user?.currentClientID;
  }
  const { currentClient } = useUserInfoStore.getState();
  if (currentClient) {
    return currentClient.id;
  }
  return null;
}

import React from 'react';

const StorageIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.77709 2.23987H0.487793V7.34097H8.77709V2.23987Z"
        stroke="currentColor"
        strokeWidth="0.735294"
        strokeLinejoin="round"
      />
      <path
        d="M2.59198 1.28345H1.23169V2.13363H2.59198V1.28345Z"
        stroke="currentColor"
        strokeWidth="0.735294"
        strokeLinejoin="round"
      />
      <path
        d="M7.94794 1.28345H6.58765V2.13363H7.94794V1.28345Z"
        stroke="currentColor"
        strokeWidth="0.735294"
        strokeLinejoin="round"
      />
      <path
        d="M2.16699 4.89661H3.6123"
        stroke="currentColor"
        strokeWidth="0.735294"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.82251 4.89661H7.26782"
        stroke="currentColor"
        strokeWidth="0.735294"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.58789 5.66192V4.13159"
        stroke="currentColor"
        strokeWidth="0.735294"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StorageIcon;

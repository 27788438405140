import React from 'react';
import { Button } from 'antd';

type Props = {
  showLinks?: boolean;
};

const Footer = ({ showLinks }: Props) => (
  <div className="text-center">
    {showLinks && (
      <>
        <small>
          <a href="/user/reset/">{gettext('Forgot password?')}</a>
        </small>
        <p className="text-muted">
          <small>{gettext('Looking for an asset management platform?')}</small>
        </p>
        <Button
          href="https://powerfactors.com/demo/"
          className="block full-width"
        >
          {gettext('Request a demo')}
        </Button>
      </>
    )}
    <p className="m-t">
      <small>
        {gettext('BluePoint renewable asset management software')} <br />
        <a href="https://powerfactors.com/">Power Factors LLC</a> &copy;{' '}
        {new Date().getFullYear()}
      </small>
    </p>
  </div>
);

export default Footer;

import React from 'react';

const SearchIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path
        d="M7.334 2.666a4.676 4.676 0 00-4.668 4.668A4.675 4.675 0 007.334 12c1.2 0 2.285-.47 3.113-1.219l2.854 2.852.707-.707-2.889-2.887c.549-.765.881-1.694.881-2.705a4.675 4.675 0 00-4.666-4.668zm0 1A3.66 3.66 0 0111 7.334 3.658 3.658 0 017.334 11a3.66 3.66 0 01-3.668-3.666 3.661 3.661 0 013.668-3.668z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SearchIcon;

import { Modal } from 'antd';

export const checkDuplicate = (submitButtonId, url, name_keys, formName) => {
  $('#' + submitButtonId).click(function () {
    let nameVal = '';
    name_keys.split(';').forEach(function (item) {
      nameVal += $('input[name="' + item + '"]')
        .val()
        .trim();
    });
    $.getJSON(url, { name: nameVal }).then(function (data) {
      if (data.is_exists) {
        Modal.confirm({
          title: gettext('The name is already in use'),
          content: gettext('Do you want to continue?'),
          okButtonProps: { danger: true },
          okText: gettext('Yes, I do!'),
          cancelText: gettext('No, cancel please!'),
          onOk: () => {
            submit_form();
          },
        });
      } else {
        submit_form();
      }
    });
  });
  function submit_form() {
    const form = $('form[name="' + formName + '"]');
    form.hide();
    $('.cancel').hide();
    // Show the loader
    $('.loader-wrapper').show();
    form.submit();
  }
};

import React from 'react';

const GlobeIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM6.863 2.617A9.68 9.68 0 005.123 7.5H2.521a5.504 5.504 0 014.342-4.883zm2.274 0A5.505 5.505 0 0113.479 7.5h-2.602a9.68 9.68 0 00-1.74-4.883zM8 2.77A8.679 8.679 0 019.875 7.5h-3.75A8.679 8.679 0 018 2.77zM2.521 8.5h2.602a9.68 9.68 0 001.74 4.883A5.504 5.504 0 012.521 8.5zm3.604 0h3.75A8.679 8.679 0 018 13.23 8.679 8.679 0 016.125 8.5zm4.752 0h2.602a5.505 5.505 0 01-4.342 4.883 9.68 9.68 0 001.74-4.883z"
        clipRule="evenodd"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default GlobeIcon;

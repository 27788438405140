import Globals from '~/common/globals';

export const ENV_URLS = {
  LOCAL: 'http://localhost:8000',
  STAGING: 'https://st4g1ng.bluepoint.io',
  PRODUCTION: 'https://www.bluepoint.io',
};

/**
 *
 * @param {string} paramName
 * @param {string} [defaultVal]
 * @returns {string}
 */
export const getUrlParameter = (paramName, defaultVal = undefined) => {
  const regex = new RegExp(`[\\?&]${paramName}=([^&#]*)`);
  const results = regex.exec(Globals.getLocation().search);
  return results === null
    ? defaultVal
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

/**
 *
 * @param {string} str
 * @returns {Record<string, string>}
 */
export const getParamsObject = str => {
  const decodedStr = decodeURIComponent(str);
  const paramsObject = {};
  if (decodedStr.indexOf('?') > -1) {
    const queryParams = decodedStr.substr(decodedStr.indexOf('?') + 1);
    const searchParamsItr = new URLSearchParams(queryParams);
    searchParamsItr.forEach((val, key) => {
      paramsObject[key] = val;
    });
  }
  return paramsObject;
};

export const getParamsStartsWith = str => {
  const params = getParamsObject(window.location.search);
  const out = {};
  Object.keys(params).forEach(param => {
    if (param.indexOf(str) !== -1) {
      out[param] = params[param];
    }
  });
  return out;
};

export const getStringifiedParams = obj => {
  try {
    return $.param(obj);
  } catch (err) {
    return '';
  }
};

export const getPrev = () => {
  const params = window.location.search;
  if (!params) return undefined;
  const paramsObject = getParamsObject(params);
  return paramsObject.next;
};

export const getNext = () => {
  const relative = window.location.pathname;
  const params = window.location.search;

  if (params) {
    const paramsObject = getParamsObject(params);
    delete paramsObject.next;
    const stringParams = getStringifiedParams(paramsObject);
    return `${relative}&${stringParams}`;
  }
  return relative;
};

export const getEnvUrl = () => {
  return ENV_URLS[Globals.getAppEnv().CURRENT_ENV || 'LOCAL'];
};

export const removeQueryParams = pqStr => {
  if (pqStr) return pqStr.split('?')[0];
  return '';
};

export const extendUrl = (key, value, urlStr) => {
  let appendedUrl = '';
  const urlParamsObject = getParamsObject(urlStr);
  urlParamsObject[key] = value;
  const urlParamsString = getStringifiedParams(urlParamsObject);
  appendedUrl = `${removeQueryParams(urlStr)}?${urlParamsString}`;
  return appendedUrl;
};

export const getUrlWithNext = url => {
  const next = getNext();
  return extendUrl('next', next, url);
};

export const getPrevURL = () => {
  const paramsObject = getParamsObject(window.location.search);
  let { next } = paramsObject;
  if (next) {
    const stringParams = getStringifiedParams(
      $.extend(
        {},
        {
          mt: paramsObject.mt,
          rt: paramsObject.rt,
          module: paramsObject.module,
        },
      ),
    );
    if (stringParams) next = `${next}?${stringParams}`;
  }
  return next;
};

export const getParam = (name, url) => {
  let currentUrl = url;
  if (!url) {
    currentUrl = window.location.href;
  }
  const paramName = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${paramName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(currentUrl);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getQueryParam = key => {
  const paramsObj = getParamsObject(window.location.search);
  return paramsObj[key];
};

export const modifyCurrentParams = (key, value) => {
  const params = getParamsObject(window.location.search);
  params[key] = value;
  return $.param(params);
};

export function openInNewTab(url) {
  window.open(url, '_blank', 'noopener noreferrer');
}

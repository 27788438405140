import React from 'react';

export const IncomingSmallIcon = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 1.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5V15h-.085a1.5 1.5 0 0 0-2.83 0h-.67a1.5 1.5 0 0 0-2.83 0h-.67a1.5 1.5 0 0 0-2.83 0H3v-2.75H2V16h2v-.5a.5.5 0 0 1 1 0v.5h2.5v-.5a.5.5 0 0 1 1 0v.5H11v-.5a.5.5 0 0 1 1 0v.5h2V1.5A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5V5h1V1.5zm2.5.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm1 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM2.146 6.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L3.293 9H.5a.5.5 0 0 1 0-1h2.793L2.146 6.854a.5.5 0 0 1 0-.708zM8.5 6.5a.5.5 0 0 0-1 0V7a1.5 1.5 0 1 0 0 3h1a.5.5 0 0 1 0 1H6.085A1.5 1.5 0 0 0 7.5 12v.5a.5.5 0 0 0 1 0V12a1.5 1.5 0 0 0 0-3h-1a.5.5 0 0 1 0-1h2.415A1.5 1.5 0 0 0 8.5 7v-.5z"
        fill="currentColor"
      />
    </svg>
  );
};

import { getParamsObject, getParamsStartsWith } from './uri';

export const appendHiddenFieldToForm = (formId, name, value) => {
  document
    .querySelector(formId)
    .insertAdjacentHTML(
      'beforeend',
      `<input type='hidden' name='${name}' value="${value}">`,
    );
};

export const appendUrlDataToForm = formId => {
  const paramsObj = getParamsObject(window.location.search);
  Object.keys(paramsObj).forEach(key => {
    appendHiddenFieldToForm(formId, key, paramsObj[key]);
  });
};

export const lockSelectField = (fieldElement, value) => {
  fieldElement.querySelectorAll(`option`).forEach(element => {
    // eslint-disable-next-line no-param-reassign
    element.selected = element.text === value;
  });
  // eslint-disable-next-line no-param-reassign
  fieldElement.disabled = true;
  // todo: get rid of jquery selectpicker
  $(fieldElement).selectpicker('refresh');
};

export const lockSelectFieldsIn = formId => {
  // Get all the GET arguments that start with the keyword "lock__"
  const LOCK = 'lock__';
  const lockArguments = getParamsStartsWith(LOCK);
  const formElement = document.querySelector(formId);

  // Loop through them and lock(disable) them
  Object.keys(lockArguments).forEach(lockField => {
    const value = lockArguments[lockField];
    const field = lockField.slice(LOCK.length);
    const fieldElement = formElement.querySelector(`#id_${field}`);
    if (fieldElement) lockSelectField(fieldElement, value);
  });

  // When the form is submitted, enable the disabled fields
  formElement.addEventListener('submit', () => {
    formElement.querySelectorAll('input, select').forEach(el => {
      // eslint-disable-next-line no-param-reassign
      el.disabled = false;
    });
  });
};

export const clearOnSelectAll = $elem => {
  // todo: get rid of jQuery selectpicker
  $elem.on('changed.bs.select', (e, clickedIndex, newValue) => {
    if (clickedIndex === 0 && newValue === true) {
      $(this).selectpicker('deselectAll');
      $(this).selectpicker('val', 'all');
    } else {
      $(this).find('option:first').attr('selected', false);
      $(this).selectpicker('refresh');
    }
  });
  $elem.selectpicker('refresh');
};

export const checkFormErrors = () => {
  const $tabs = $('.tab-pane');
  if ($('.error-in-form').length !== 0) {
    for (let i = 0, len = $tabs.length; i < len; i++) {
      // For every tab in the form, check all the fields with the class "error-in-form"
      const errorsInTab = $($tabs[i]).find('.error-in-form').length;
      if (errorsInTab !== 0) {
        // If there are errors in the page, display the number of errors in the tab inside a badge
        $(`a[href="#${$tabs[i].id}"]`).append(
          ` <span class="badge badge-danger p-l-xs m-l-xs"><i class="lnricns lnricns-warning"></i> ${errorsInTab}</span>`,
        );

        $('.submit-form').show();
        $('.cancel').show();
        $('form').show();
        $('.loader-wrapper').hide();
      } else {
        $(`a[href="#${$tabs[i].id}"]`).append(
          ` <span class="badge badge-default p-l-xs m-l-xs"><i class="lnricns lnricns-warning"></i> ${errorsInTab}</span>`,
        );
      }
    }
  }
};

import React from 'react';

const MenuIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 7H5V6H19V7ZM19 12H5V11H19V12ZM5 17H19V16H5V17Z"
        fill="#494948"
      />
    </svg>
  );
};

export default MenuIcon;

import React from 'react';

const SendIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.64929 5.84243C4.2366 5.06864 5.06152 4.22172 5.8459 4.61391L19.008 11.195C19.6714 11.5267 19.6714 12.4733 19.008 12.805L5.8459 19.386C5.06152 19.7782 4.2366 18.9313 4.64929 18.1575L7.93331 12L4.64929 5.84243ZM8.79998 12.5L5.67926 18.3513L17.3819 12.5L10.5 12.5H8.79998ZM17.3819 11.5L5.67926 5.64863L8.79998 11.5L10.5 11.5H17.3819Z"
        fill="#494948"
      />
    </svg>
  );
};

export default SendIcon;

import React from 'react';

const MilestoneIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.52409 3.34597C8.54336 3.28642 8.5735 3.23175 8.61217 3.18431C8.65719 3.12892 8.71197 3.08551 8.77221 3.05477C8.88926 2.99503 9.02694 2.98313 9.15373 3.02414C9.20582 3.04097 9.25418 3.06611 9.29724 3.09801L13.7926 6.3447L14.3539 6.75004L13.7926 7.15538L9.4999 10.2557V12.5C9.4999 12.7762 9.27604 13 8.9999 13C8.72376 13 8.4999 12.7762 8.4999 12.5V10.0065V9.99441L8.4999 3.50569V3.50004C8.4999 3.44628 8.50839 3.3945 8.52409 3.34597ZM9.4999 9.02216L12.6459 6.75004L9.4999 4.47791L9.4999 9.02216ZM6.15522 6.65004L4.25256 4.74738L4.74754 4.2524L7.24769 6.75255L7.49517 7.00004L7.24769 7.24752L4.74754 9.74767L4.25256 9.2527L6.15523 7.35004H3.9999C3.08863 7.35004 2.3499 8.08877 2.3499 9.00004V12.5C2.3499 12.6933 2.1932 12.85 1.9999 12.85C1.8066 12.85 1.6499 12.6933 1.6499 12.5V9.00004C1.6499 7.70217 2.70203 6.65004 3.9999 6.65004H6.15522Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MilestoneIcon;

module.exports = {
  primary: '#FF853D',
  BPBlue: '#4EA5E0',

  backgroundGrey: '#F1F3F6',

  grey100: '#F7F9FB',
  grey10: 'rgba(247, 249, 251, 0.5)',
  grey200: '#EFF1F2',
  grey300: '#E6E9EB',
  grey30: 'rgba(230, 233, 235, 0.3)',
  grey400: '#d9dde0',
  grey40: 'rgba(217, 221, 224, 0.6)',
  grey500: '#A1A3A6',
  grey600: '#7F8185',
  grey700: '#494948',
  grey800: '#212121',

  silver100: '#EDF1F5',
  silver200: '#D5DDE5',
  silver300: '#A9B5C0',
  silver600: '#6B747D',

  blue30: 'rgba(228, 242, 252, 0.3)',
  blue100: '#E4F2FC',
  blue200: '#CCEBF8',
  blue300: '#5098D5',
  blue400: '#4EA5E0',
  blue500: '#3380B3',
  blue5: 'rgba(51, 128, 179, 0.05)',
  blue600: '#266B98',

  yellow400: '#F5B741',

  red100: '#FFF3F2',
  red200: '#F2CCC9',
  red300: '#E5867E',
  red600: '#8F251D',
  redAccent: '#C1180B',

  // to be renamed to non-business-related names
  templatePageShadow: 'rgba(198,208,214,0.4)',
  danger: '#C1180B',
  dangerShadow: 'rgba(193, 24, 11, 0.25)',
  warning: '#ffb606',
  navy: '#34495e',

  green100: '#EBFAEF',
  green200: '#C5EDCF',
  green300: '#7DD693',
  green400: '#7DC74D',
  green600: '#26793B',

  orange100: '#fcebe1',
  orange200: '#f5d6c4',
  orange300: '#f09d6c',
  orange400: '#e67e22',
  orange600: '#8f5533',

  cobalt100: '#F2F5FE',
  cobalt200: '#D6DFFF',
  cobalt300: '#94ABFF',
  cobalt600: '#445EBB',

  pink100: '#F5E8FF',
  pink500: '#6E4290',

  turquoise100: '#F1FCFF',
  turquoise200: '#CBEAF5',
  turquoise300: '#7FC8E0',
  turquoise600: '#33788F',

  olive100: '#83742D',
  olive600: '#FBF9EC',

  golden100: '#FCF4DE',
  golden300: '#e8c258',
  golden600: '#966E07',

  violet300: '#b595cf',

  boxShadow: '0 1px 3px rgba(169, 181, 192, 0.15)',
};

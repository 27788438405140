import React from 'react';

const LocationIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24 3.75A16.75 16.75 0 007.25 20.5c0 6.554 4.127 12.411 8.148 16.656 4.022 4.246 8.047 6.926 8.047 6.926a1 1 0 001.11 0s4.025-2.68 8.047-6.926c4.021-4.245 8.148-10.102 8.148-16.656A16.751 16.751 0 0024 3.75zm0 2A14.748 14.748 0 0138.75 20.5c0 5.696-3.748 11.214-7.602 15.281C27.74 39.38 24.74 41.492 24 42.004c-.74-.512-3.74-2.625-7.148-6.223C12.998 31.714 9.25 26.196 9.25 20.5A14.748 14.748 0 0124 5.75zm0 8.5c-3.44 0-6.25 2.81-6.25 6.25s2.81 6.25 6.25 6.25 6.25-2.81 6.25-6.25-2.81-6.25-6.25-6.25zm0 2c2.36 0 4.25 1.89 4.25 4.25 0 2.36-1.89 4.25-4.25 4.25-2.36 0-4.25-1.89-4.25-4.25 0-2.36 1.89-4.25 4.25-4.25z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LocationIcon;

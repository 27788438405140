import React from 'react';
import PropTypes from 'prop-types';

const AngleCircleIcon = ({ direction, style, ...props }) => {
  let transform = null;
  if (direction === 'down') {
    transform = 'rotate(90deg)';
  } else if (direction === 'up') {
    transform = 'rotate(-90deg)';
  } else if (direction === 'left') {
    transform = 'rotate(180deg)';
  }

  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ transform, ...style }}
    >
      <path
        d="M10.9761 8.97632L13.9998 12L10.9761 15.0237"
        stroke="currentColor"
      />
      <rect x="3" y="3" width="18" height="18" rx="9" stroke="currentColor" />
    </svg>
  );
};

AngleCircleIcon.propTypes = {
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  style: PropTypes.object,
};

AngleCircleIcon.defaultProps = {
  direction: 'right',
  style: {},
};

export default AngleCircleIcon;

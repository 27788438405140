import React from 'react';

export const ZoomInAltIcon = props => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 11C17 14.3137 14.3137 17 11 17C7.68629 17 5 14.3137 5 11C5 7.68629 7.68629 5 11 5C14.3137 5 17 7.68629 17 11ZM15.6935 16.1935C14.4519 17.3162 12.8058 18 11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 12.7002 17.3938 14.2589 16.3858 15.4716L20.6569 19.7426L19.9497 20.4497L15.6935 16.1935ZM10.5 11.5H8V10.5H10.5V8H11.5V10.5H14V11.5H11.5V14H10.5V11.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

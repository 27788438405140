import React from 'react';
import PropTypes from 'prop-types';

const ArrowDiagonalIcon = ({ direction, style = {}, ...props }) => {
  let transform = null;
  if (direction === 'up-right') {
    transform = 'rotate(-90)';
  } else if (direction === 'down-left') {
    transform = 'rotate(180deg)';
  } else if (direction === 'down-right') {
    transform = 'rotate(90deg)';
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...props}
      style={{ transform, ...style }}
    >
      <path
        fill="currentColor"
        d="M 6.5 5 L 6.5 6 L 10 6 L 10 9.5 L 11 9.5 L 11 5 L 6.5 5 z "
        id="path20"
      />
      <path
        fill="currentColor"
        d="M 9.6464844 5.6464844 L 4.6464844 10.646484 L 5.3535156 11.353516 L 10.353516 6.3535156 L 9.6464844 5.6464844 z "
        id="path16"
      />
    </svg>
  );
};

ArrowDiagonalIcon.propTypes = {
  direction: PropTypes.oneOf([
    'up-right',
    'up-left',
    'down-left',
    'down-right',
  ]),
  style: PropTypes.object,
};

ArrowDiagonalIcon.defaultProps = {
  direction: 'up-right',
  style: {},
};

export default ArrowDiagonalIcon;

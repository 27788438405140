import React from 'react';
import ContentLoader from 'react-content-loader';

const PopoverLoader = props => (
  <ContentLoader
    speed={2}
    width={155}
    height={38}
    viewBox="0 0 155 38"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="3" y="8" rx="3" ry="3" width="88" height="6" />
    <rect x="3" y="22" rx="3" ry="3" width="52" height="6" />
  </ContentLoader>
);

export default PopoverLoader;

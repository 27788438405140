import React from 'react';

const WarningIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke="currentColor"
        d="M7.13397 3.5C7.51887 2.83333 8.48113 2.83333 8.86603 3.5L13.1962 11C13.5811 11.6667 13.0999 12.5 12.3301 12.5H3.66987C2.90007 12.5 2.41895 11.6667 2.80385 11L7.13397 3.5Z"
      />
      <line
        x1="8"
        y1="6"
        x2="8"
        y2="9"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path d="M8 10V11" strokeLinejoin="round" stroke="currentColor" />
    </svg>
  );
};

export default WarningIcon;

import create from 'zustand';
import { persist } from 'zustand/middleware';

type AuthStoreState = {
  accessToken: string | null;
};

export const useAuthStore = create(
  persist<AuthStoreState>(
    () => ({
      accessToken: null,
    }),
    {
      name: 'bp-auth-storage',
    },
  ),
);

export const auth = {
  setAccessToken: (token: string) => {
    useAuthStore.setState({ accessToken: token });
  },
  clearAccessToken: () => {
    useAuthStore.setState({ accessToken: null });
  },
  getAccessToken: () => useAuthStore.getState().accessToken,
};

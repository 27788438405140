import React from 'react';

const ProjectsIcon = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M2.5 2a1.5 1.5 0 1 0 0 3h3a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-3zM2 3.5a.5.5 0 0 1 .5-.5H5v1H2.5a.5.5 0 0 1-.5-.5zM7.5 2a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h6a1.5 1.5 0 0 0 0-3h-6zm6 2H8V3h5.5a.5.5 0 0 1 0 1zM1 11.5A1.5 1.5 0 0 1 2.5 10h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7A1.5 1.5 0 0 1 1 11.5zm1.5-.5a.5.5 0 0 0 0 1H9v-1H2.5zm9-1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2a1.5 1.5 0 0 0 0-3h-2zm2 2H12v-1h1.5a.5.5 0 0 1 0 1zM1 7.5A1.5 1.5 0 0 1 2.5 6h11a1.5 1.5 0 0 1 0 3h-11A1.5 1.5 0 0 1 1 7.5zM11 8h2.5a.5.5 0 0 0 0-1h-11a.5.5 0 0 0 0 1H11z"
      />
    </svg>
  );
};

export default ProjectsIcon;

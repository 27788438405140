import React from 'react';

const DurationIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 2.5C5.01472 2.5 3 4.51472 3 7C3 7.27614 2.77614 7.5 2.5 7.5C2.22386 7.5 2 7.27614 2 7C2 3.96243 4.46243 1.5 7.5 1.5C10.4695 1.5 12.8893 3.85326 12.9963 6.79659L13.6464 6.14645C13.8417 5.95118 14.1583 5.95118 14.3536 6.14645C14.5488 6.34171 14.5488 6.65829 14.3536 6.85355L12.8536 8.35355L12.5 8.70711L12.1464 8.35355L10.6464 6.85355C10.4512 6.65829 10.4512 6.34171 10.6464 6.14645C10.8417 5.95118 11.1583 5.95118 11.3536 6.14645L11.9951 6.78799C11.8844 4.40114 9.91421 2.5 7.5 2.5ZM1.5 10C1.22386 10 1 10.2239 1 10.5V12.5C1 12.7761 1.22386 13 1.5 13H3.5C3.77614 13 4 12.7761 4 12.5V10.5C4 10.2239 3.77614 10 3.5 10H1.5ZM2 12V11H3V12H2ZM6 10.5C6 10.2239 6.22386 10 6.5 10H8.5C8.77614 10 9 10.2239 9 10.5V12.5C9 12.7761 8.77614 13 8.5 13H6.5C6.22386 13 6 12.7761 6 12.5V10.5ZM7 11V12H8V11H7ZM11 10.5C11 10.2239 11.2239 10 11.5 10H13.5C13.7761 10 14 10.2239 14 10.5V12.5C14 12.7761 13.7761 13 13.5 13H11.5C11.2239 13 11 12.7761 11 12.5V10.5ZM12 11V12H13V11H12Z"
        fill="#7F8185"
      />
    </svg>
  );
};

export default DurationIcon;

import React from 'react';

const MapMarkerWind = ({ markerFill = '#5098D5', ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M13 9C17.9706 4.02944 26.0294 4.02944 31 9V9C35.9706 13.9706 35.9706 22.0294 31 27L22 36L13 27C8.02945 22.0294 8.02944 13.9706 13 9V9Z"
          fill={markerFill}
        />
        <path
          d="M30.2929 26.2929L22 34.5858L13.7071 26.2929C9.12708 21.7128 9.12708 14.2871 13.7071 9.7071C18.2871 5.12707 25.7129 5.12707 30.2929 9.7071C34.8729 14.2871 34.8729 21.7128 30.2929 26.2929Z"
          stroke="white"
          strokeWidth="2"
        />
      </g>
      <path
        d="M22.075 17.6251C21.025 17.6251 20.125 16.8001 20.125 15.6751C20.125 14.5501 21.025 13.7251 22.075 13.7251C23.05 13.7251 23.8 14.4001 24.025 15.3001"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M18.775 15.9C18.625 15.6 18.25 15.375 17.875 15.375C17.275 15.375 16.75 15.9 16.75 16.5C16.75 17.1 17.275 17.625 17.875 17.625H26.875"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M19.9 20.925C19.75 21.225 19.375 21.375 19 21.375C18.4 21.375 17.875 20.85 17.875 20.25C17.875 19.65 18.4 19.125 19 19.125H25.225"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M22.8999 20.625H27.4749"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="44"
          height="44"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.419608 0 0 0 0 0.454902 0 0 0 0 0.490196 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default MapMarkerWind;

import React from 'react';

const SendEmailIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 14V17C19 18.1046 18.1046 19 17 19H5C3.89543 19 3 18.1046 3 17V9.00001C3 7.89544 3.89543 7.00001 5 7.00001H11"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M3 8.99998L11.0355 15.806L14.5497 12.8296"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M22.1133 6.66992C22.1133 7.22852 22.0254 7.74023 21.8496 8.20508C21.6738 8.66602 21.4258 9.02539 21.1055 9.2832C20.7891 9.54102 20.4219 9.66992 20.0039 9.66992C19.6953 9.66992 19.4277 9.58789 19.2012 9.42383C18.9746 9.25977 18.8223 9.03711 18.7441 8.75586H18.6738C18.4824 9.06055 18.2461 9.28906 17.9648 9.44141C17.6836 9.59375 17.3672 9.66992 17.0156 9.66992C16.3789 9.66992 15.877 9.46484 15.5098 9.05469C15.1465 8.64453 14.9648 8.08984 14.9648 7.39062C14.9648 6.58594 15.207 5.93359 15.6914 5.43359C16.1758 4.92969 16.8262 4.67773 17.6426 4.67773C17.9395 4.67773 18.2676 4.70508 18.627 4.75977C18.9902 4.81055 19.3125 4.88281 19.5938 4.97656L19.4648 7.70117V7.8418C19.4648 8.4668 19.668 8.7793 20.0742 8.7793C20.3828 8.7793 20.627 8.58008 20.8066 8.18164C20.9902 7.7832 21.082 7.27539 21.082 6.6582C21.082 5.99023 20.9453 5.4043 20.6719 4.90039C20.3984 4.39258 20.0098 4.00195 19.5059 3.72852C19.002 3.45508 18.4238 3.31836 17.7715 3.31836C16.9395 3.31836 16.2148 3.49023 15.5977 3.83398C14.9844 4.17773 14.5156 4.66992 14.1914 5.31055C13.8672 5.94727 13.7051 6.6875 13.7051 7.53125C13.7051 8.66406 14.0078 9.53516 14.6133 10.1445C15.2188 10.7539 16.0879 11.0586 17.2207 11.0586C18.084 11.0586 18.9844 10.8828 19.9219 10.5312V11.4922C19.1016 11.8281 18.209 11.9961 17.2441 11.9961C15.7988 11.9961 14.6719 11.6074 13.8633 10.8301C13.0547 10.0488 12.6504 8.96094 12.6504 7.56641C12.6504 6.54688 12.8691 5.64062 13.3066 4.84766C13.7441 4.05078 14.3496 3.44141 15.123 3.01953C15.9004 2.59766 16.7793 2.38672 17.7598 2.38672C18.6074 2.38672 19.3613 2.5625 20.0215 2.91406C20.6855 3.26562 21.1992 3.76758 21.5625 4.41992C21.9297 5.06836 22.1133 5.81836 22.1133 6.66992ZM16.0898 7.41406C16.0898 8.32422 16.4473 8.7793 17.1621 8.7793C17.916 8.7793 18.3281 8.20703 18.3984 7.0625L18.4688 5.66211C18.2227 5.5957 17.959 5.5625 17.6777 5.5625C17.1777 5.5625 16.7871 5.72852 16.5059 6.06055C16.2285 6.39258 16.0898 6.84375 16.0898 7.41406Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SendEmailIcon;

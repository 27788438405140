import React from 'react';

const UnlinkIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path
        d="M11.014 1.5a3.518 3.518 0 0 0-2.475.986l-.006.008-1.387 1.387a.5.5 0 1 0 .708.707l1.384-1.385a2.52 2.52 0 0 1 3.525.033 2.52 2.52 0 0 1 .034 3.526l-1.385 1.384a.5.5 0 0 0 .707.708l1.387-1.387.008-.006a3.518 3.518 0 0 0-2.5-5.961zM5.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 1 0v-1a.5.5 0 0 0-.5-.5zm-2.293.707a.498.498 0 0 0-.353.854l.707.707a.5.5 0 1 0 .707-.707l-.707-.707a.498.498 0 0 0-.354-.147zM2.5 5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm1.72 2a.498.498 0 0 0-.353.146L2.494 8.521l-.008.006A3.518 3.518 0 0 0 7.461 13.5l.006-.006 1.387-1.387a.5.5 0 1 0-.708-.707l-1.384 1.383a2.514 2.514 0 0 1-4.262-1.79 2.517 2.517 0 0 1 .703-1.768l1.371-1.371A.5.5 0 0 0 4.221 7zm8.28 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm-.414 1.586a.499.499 0 0 0-.354.853l.707.707a.5.5 0 0 0 .707-.707l-.707-.707a.499.499 0 0 0-.353-.146zM10.5 12a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 1 0v-1a.5.5 0 0 0-.5-.5z"
        clipRule="evenodd"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default UnlinkIcon;

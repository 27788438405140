import React from 'react';

const MilestoneDiamondIcon = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m8 .793.354.354 6.364 6.363.353.354-.354.354-6.363 6.364-.354.353-.354-.353-6.363-6.364-.354-.354.354-.354 6.363-6.363L8 .793zm0 1.414L2.343 7.864 8 13.521l5.657-5.657L8 2.207z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MilestoneDiamondIcon;

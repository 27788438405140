import React from 'react';

const ExternalSmall = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 12 12"
      {...props}
    >
      <path
        d="M6.5 1a.5.5 0 00-.5.5.5.5 0 00.5.5h2.793L5.404 5.889a.5.5 0 000 .707.5.5 0 00.707 0L10 2.707V5.5a.5.5 0 00.5.5.5.5 0 00.5-.5V1H6.5zm-3 2C2.677 3 2 3.677 2 4.5v4c0 .823.677 1.5 1.5 1.5h4C8.323 10 9 9.323 9 8.5V7.25a.5.5 0 00-.5-.5.5.5 0 00-.5.5V8.5c0 .282-.218.5-.5.5h-4a.493.493 0 01-.5-.5v-4c0-.282.218-.5.5-.5h1.25a.5.5 0 00.5-.5.5.5 0 00-.5-.5H3.5z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ExternalSmall;

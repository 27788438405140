import React from 'react';
import type { IconComponentProps } from 'ui/icons/IconsTypes';

export const SeverityMajorIcon = (props: IconComponentProps) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Major">
        <line
          id="Line 88"
          x1="0.5"
          y1="-0.5"
          x2="7.5"
          y2="-0.5"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 4.5 9)"
          stroke="#F09D6C"
          strokeLinecap="round"
        />
        <path
          id="Rectangle 70"
          d="M9.00006 4.99994L5 0.999884L0.999942 4.99994"
          stroke="#F09D6C"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

import React from 'react';

const DragIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="5" y="3" width="2" height="2" />
      <rect x="5" y="7" width="2" height="2" />
      <rect x="5" y="11" width="2" height="2" />
      <rect x="9" y="3" width="2" height="2" />
      <rect x="9" y="7" width="2" height="2" />
      <rect x="9" y="11" width="2" height="2" />
    </svg>
  );
};

export default DragIcon;

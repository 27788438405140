import React from 'react';

const CollapseIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        d="m 11.976562,4.5 a 0.5,0.5 0 0 0 -0.330078,0.1464844 l -1.9999996,2 a 0.5,0.5 0 0 0 0,0.7070312 0.5,0.5 0 0 0 0.7070316,0 l 2,-2 a 0.5,0.5 0 0 0 0,-0.7070312 A 0.5,0.5 0 0 0 11.976562,4.5 Z"
      />
      <path
        fill="currentColor"
        d="M 6.9765625,9.5 A 0.5,0.5 0 0 0 6.6464844,9.6464844 l -2,1.9999996 a 0.5,0.5 0 0 0 0,0.707032 0.5,0.5 0 0 0 0.7070312,0 l 2,-2 a 0.5,0.5 0 0 0 0,-0.7070316 A 0.5,0.5 0 0 0 6.9765625,9.5 Z"
        id="path28"
      />
      <path
        fill="currentColor"
        d="M 9.5,4 A 0.5,0.5 0 0 0 9,4.5 V 8 h 3.5 A 0.5,0.5 0 0 0 13,7.5 0.5,0.5 0 0 0 12.5,7 H 10 V 4.5 A 0.5,0.5 0 0 0 9.5,4 Z"
        id="path24"
      />
      <path
        fill="currentColor"
        d="M 4.5,9 A 0.5,0.5 0 0 0 4,9.5 0.5,0.5 0 0 0 4.5,10 H 7 v 2.5 A 0.5,0.5 0 0 0 7.5,13 0.5,0.5 0 0 0 8,12.5 V 9 Z"
        id="path20"
      />
    </svg>
  );
};

export default CollapseIcon;

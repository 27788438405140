import React from 'react';

const ExportIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12 4.293l-.328.328a.5.5 0 00-.041.043L8.146 8.146a.5.5 0 000 .708.5.5 0 00.708 0L11.5 6.207V15a.5.5 0 00.5.5.5.5 0 00.5-.5V6.207l2.646 2.647a.5.5 0 00.708 0 .5.5 0 000-.708l-3.475-3.474a.5.5 0 00-.043-.041L12 4.293zM5.5 13a.5.5 0 00-.5.5v4c0 .823.677 1.5 1.5 1.5h11c.823 0 1.5-.677 1.5-1.5v-4a.5.5 0 00-.5-.5.5.5 0 00-.5.5v4c0 .282-.218.5-.5.5h-11a.493.493 0 01-.5-.5v-4a.5.5 0 00-.5-.5z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ExportIcon;

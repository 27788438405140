import React from 'react';

const PlusOperatorIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        d="M 4.5 7.5 A 0.5 0.5 0 0 0 4 8 A 0.5 0.5 0 0 0 4.5 8.5 L 11.5 8.5 A 0.5 0.5 0 0 0 12 8 A 0.5 0.5 0 0 0 11.5 7.5 L 4.5 7.5 z "
        id="path22"
      />
      <path
        fill="currentColor"
        d="M 8 4 A 0.5 0.5 0 0 0 7.5 4.5 L 7.5 11.5 A 0.5 0.5 0 0 0 8 12 A 0.5 0.5 0 0 0 8.5 11.5 L 8.5 4.5 A 0.5 0.5 0 0 0 8 4 z "
        id="path18"
      />
    </svg>
  );
};

export default PlusOperatorIcon;

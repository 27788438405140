import React from 'react';

const FlagIcon = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path
        d="M6 2.5c-.8055556 0-1.371514.1345432-1.7480469.2851562-.3765329.1506133-.6054687.3613282-.6054687.3613282A.50005.50005 0 003.5 3.5v5.9882812A.5.5 0 003.5 9.5V13a.5.5 0 00.5.5.5.5 0 00.5-.5V9.7773438c.0383214-.0222976.0099188-.0172488.1230469-.0625C4.871514 9.6154568 5.3055556 9.5 6 9.5c.6388889 0 1.1894531.2148438 1.8144531.4648438C8.4394531 10.214844 9.1388889 10.5 10 10.5c.805556 0 1.371514-.134543 1.748047-.285156.376533-.150613.605469-.3613284.605469-.3613284A.50005.50005 0 0012.5 9.5v-6a.50005.50005 0 00-.853516-.3535156s-.021064.0392851-.269531.1386718C11.128486 3.3845432 10.694444 3.5 10 3.5c-.6388889 0-1.1894531-.2148438-1.8144531-.4648438C7.5605469 2.7851562 6.8611111 2.5 6 2.5zm0 1c.6388889 0 1.1894531.2148438 1.8144531.4648438C8.4394531 4.2148438 9.1388889 4.5 10 4.5c.691207 0 1.129873-.1207434 1.5-.2441406v4.9667968c-.038346.0224429-.009919.0172489-.123047.0625C11.128486 9.3845432 10.694444 9.5 10 9.5c-.6388889 0-1.1894531-.2148438-1.8144531-.4648438C7.5605469 8.7851562 6.8611111 8.5 6 8.5c-.6912072 0-1.1298733.1207434-1.5.2441406V3.7773438c.0383464-.022443.0099188-.0172488.1230469-.0625C4.871514 3.6154568 5.3055556 3.5 6 3.5z"
        overflow="visible"
        fill="currentColor"
      />
    </svg>
  );
};

export default FlagIcon;

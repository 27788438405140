import React from 'react';

export const FitIcon = props => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6.5C15 6.22386 14.7761 6 14.5 6C14.2239 6 14 6.22386 14 6.5L14 9.5L14 10L14.5 10L17.5 10C17.7761 10 18 9.77614 18 9.5C18 9.22386 17.7761 9 17.5 9L15.5007 9C15.6284 8.99983 15.7561 8.95102 15.8536 8.85355L18.8536 5.85355C19.0488 5.65829 19.0488 5.34171 18.8536 5.14645C18.6583 4.95118 18.3417 4.95118 18.1464 5.14645L15.1464 8.14645C15.0489 8.24399 15.0001 8.37181 15 8.49965L15 6.5ZM15 8.50035L15 9L15.4993 9C15.3716 8.99983 15.2439 8.95102 15.1464 8.85355C15.0489 8.75601 15.0001 8.62819 15 8.50035Z"
        fill="currentColor"
      />
      <path
        d="M8.5 8.5L5.5 5.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 9.5L9.5 9.5L9.5 6.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M8.5 15.5L5.5 18.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 17.5L9.5 14.5L6.5 14.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M15.5 15.5L18.5 18.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 14.5L14.5 14.5L14.5 17.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};

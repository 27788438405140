import axios from 'axios';
import { http } from './http';

const GET_PRESIGNED_URL = '/api/gallery/images/upload-url';

export const uploadImageToS3 = async file => {
  try {
    const response = await http.get(GET_PRESIGNED_URL, {
      params: { filename: file.name, mime_type: file.type },
    });

    const jsonResp = response.data;

    const { status } = jsonResp;
    if (status !== 'ok') {
      const { message } = jsonResp;
      throw new Error(message);
    }

    const { data } = jsonResp;
    const { url, signed_url: signedUrl } = data;

    const awsResponse = await axios({
      method: 'PUT',
      url: signedUrl,
      data: file,
      headers: {
        'x-amz-acl': 'public-read',
        'content-type': file.type,
      },
    });
    if (awsResponse.status === 200) {
      return url;
    }
    throw new Error('Error uploading image to aws');
  } catch (err) {
    throw err;
  }
};

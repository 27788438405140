import React from 'react';

const CheckIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.54878 10.951L10.4629 15.8651L20.4903 6.09359"
        stroke="currentColor"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default CheckIcon;

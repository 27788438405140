import { identityDecoder, keyDecoder } from './legacy-http';
import { request, urlFactory } from './utils';

export const url = urlFactory('api/user');

const get = path =>
  request(
    {
      url: url(`/settings`),
      method: 'POST',
      data: { path },
    },
    identityDecoder,
  );

const set = (path, settings) =>
  request(
    {
      url: url(`/settings`),
      method: 'PUT',
      data: { path, settings },
    },
    keyDecoder('settings'),
  );

export default { get, set };

export const CapacityUnitDisplay = {
  mw: 'MW',
  mwh: 'MWh',
  kw: 'kW',
  kwh: 'kWh',
};

export function getCapacityUnit(unit: string): string {
  return CapacityUnitDisplay[unit as keyof typeof CapacityUnitDisplay] ?? unit;
}

import React from 'react';

const OtherResourceIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 5 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="0.629022"
        cy="5.00651"
        r="0.431202"
        transform="rotate(30 0.629022 5.00651)"
        fill="currentColor"
      />
      <circle
        cx="2.12316"
        cy="5.86882"
        r="0.431202"
        transform="rotate(30 2.12316 5.86882)"
        fill="currentColor"
      />
      <circle
        cx="3.61633"
        cy="6.73137"
        r="0.431202"
        transform="rotate(30 3.61633 6.73137)"
        fill="white"
      />
      <path
        d="M1.44482 10.4065L2.95403 7.79247"
        stroke="currentColor"
        strokeWidth="0.735294"
        strokeLinecap="round"
      />
      <path
        d="M1.23145 3.94592L2.74065 1.3319"
        stroke="currentColor"
        strokeWidth="0.735294"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default OtherResourceIcon;

import React from 'react';
import type { IconComponentProps } from 'ui/icons/IconsTypes';

export const SeverityMinorIcon = (props: IconComponentProps) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Minor">
        <line
          id="Line 88"
          x1="5"
          y1="0.5"
          x2="5"
          y2="7.5"
          stroke="#7DD693"
          strokeLinecap="round"
        />
        <path
          id="Rectangle 70"
          d="M9.00222 4.00222L5 8.00445L0.997775 4.00222"
          stroke="#7DD693"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

import React from 'react';

const EyeIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12.086 5.5c-5.631 0-8.695 6.277-8.695 6.277a.5.5 0 000 .446S6.455 18.5 12.086 18.5c5.63 0 8.697-6.277 8.697-6.277a.5.5 0 000-.446S17.717 5.5 12.086 5.5zm0 1c4.68 0 7.424 5.088 7.643 5.5-.219.412-2.964 5.5-7.643 5.5-4.68 0-7.422-5.088-7.64-5.5.218-.412 2.96-5.5 7.64-5.5z"
        overflow="visible"
      />
      <path
        fill="currentColor"
        d="M12.086 9.25A2.758 2.758 0 009.336 12a2.758 2.758 0 002.75 2.75 2.758 2.758 0 002.75-2.75 2.758 2.758 0 00-2.75-2.75zm0 1c.972 0 1.75.778 1.75 1.75s-.778 1.75-1.75 1.75-1.75-.778-1.75-1.75.778-1.75 1.75-1.75z"
        overflow="visible"
      />
    </svg>
  );
};

export default EyeIcon;

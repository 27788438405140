import { http } from './http';

const ERROR_MESSAGE = 'error fetching permissions';

export const PERMISSIONS_ENDPOINT = '/api/permissions';

async function getPermissions() {
  try {
    const response = await http.get(PERMISSIONS_ENDPOINT);
    if (response && response.data) {
      return {
        ok: true,
        results: response.data.permissions,
      };
    }
    return {
      ok: false,
      error: ERROR_MESSAGE,
    };
  } catch (error) {
    console.log(ERROR_MESSAGE, error);
    return {
      ok: false,
      error: error.message,
    };
  }
}

export const PermissionsApi = {
  get: getPermissions,
};

import axios from 'axios';

export const errors = {
  valueError: 'ValueError: you must pass a request decoder',
  responseDecoderError:
    'Could not decode response data after successful response',
  errorDecoderError: 'Could not decode error',
  knownError: 'Known error',
  unknownError: 'Unknown error',
  configError: 'Config error',
  networkError: 'Network error',
};

export const makeFailResult = (reason, data) => ({
  ok: false,
  reason,
  data,
});

export const makeSuccessResult = data => ({
  ok: true,
  data,
});

export const request = (config, responseDecoder, errorDecoder) => {
  if (!responseDecoder) {
    throw new Error(errors.valueError);
  }
  return axios(config)
    .then(response => {
      try {
        const decodedData = responseDecoder(response.data);
        return makeSuccessResult(decodedData);
      } catch (error) {
        return makeFailResult(errors.responseDecoderError);
      }
    })
    .catch(error => {
      if (error.response) {
        try {
          if (errorDecoder) {
            const decodedError = errorDecoder(error.response.data);
            return makeFailResult(errors.knownError, decodedError);
          }
          return makeFailResult(errors.unknownError);
        } catch (anotherError) {
          return makeFailResult(errors.errorDecoderError);
        }
      } else if (error.request) {
        return makeFailResult(errors.networkError);
      } else {
        return makeFailResult(errors.configError);
      }
    });
};
